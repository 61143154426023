//zh.js
module.exports = {
  base: {
    title: 'STARTHESTAR',
    desc1: '계약의 왕',
    desc2: '계약 거래의 글로벌 리더',
    d1: '믿음',
    d2: '정직',
    d3: '힘',
    more: '더보기',
    top: '맨 위로',
    download: '코드를 스캔하여 APP 다운로드',
    zszh: '실제 계정',
    mnzh: '데모 계정',
    content: '계약거래 분야 글로벌 마켓 리더와 거래하세요',
    lxkf: '고객 서비스에 문의',
    chxx: '철회',
    zx: '온라인'
  },
  lang: {
    title: '한국어'
  },
  message: {
    invalid_email: '잘못된 이메일',
    invalid_password: '잘못된 비밀번호',
    invalid_new_password: '잘못된 새 비밀번호',
    invalid_res_password: '잘못된 확인 암호',
    invalid_email_code: '잘못된 이메일 확인 코드',
    password_inconsistent: '일관되지 않은 암호',
    invalid_invitation_code: '잘못된 초대 코드',
    should_agree: '등록 약관을 읽고 동의하십시오.',
    not_null: '이 필드는 비워 둘 수 없습니다.',
    send_email: '{:s} 초 후에 다시 시도하십시오',
    invalid_number: '잘못된 값',

    invalid_rate: '여러 개를 선택하세요',
    invalid_rule: '규칙을 선택하세요',
    invalid_system_rule: '시스템에 규칙이 없습니다.',

    not_enough: '충분하지 않음',
    price_high: '가격은{price}보다 높아야합니다.',
    price_low: '가격은{price}미만이어야합니다.',

    number_high: '최대 인출 수는 {num}입니다.',
    number_low: '최소 출금 금액은 {num}입니다.',
    copy_address: '주소 복사 성공 {text}',

    invalid_upload_front: '신분증 앞면 사진을 업로드 해주세요.',
    invalid_upload_back: '신분증 뒷면 사진을 업로드 해주세요.',
    invalid_upload_hand: '휴대용 ID 사진을 업로드하세요.',
    invalid_upload_name: '인증서 이름을 입력하십시오',
    invalid_upload_card: 'ID 번호를 입력하세요',
    upload_pic_type: '사진 업로드는 JPG, PNG 형식 만 가능합니다.',
    upload_pic_size: '업로드 된 아바타 이미지 크기는 10MB를 초과 할 수 없습니다.'
  },
  index: {
    title2: '글로벌 시장 자유 거래 24 시간 논스톱',
    desc2: '전체 플랫폼에서 24 시간 거래량 ',
    desc2_1: '로 매우 유동적 인 시장에서 거래 전략을 구현하십시오.',
    list: {
      name: '시장',
      h24_price: '24H 최신 가격',
      h24_change: '24 시간 변경',
      h24_high: '24 시간 최고가',
      h24_low: '24 시간 최저가',
      h24_vol: '24 시간 볼륨'
    },
    footer: {
      desc: `미국에 본사를두고있는 Thesat Blockchain Technology Ltd는 선도적 인 글로벌 블록 체인 기술 회사입니다.이 회사는 미국 MSB 국제 금융 라이센스를 획득했습니다. 글로벌 규모의 규정 준수로 운영 할 수있는 규정 준수 블록 체인 기술 서비스 회사입니다. 블록 체인 비즈니스 서비스 . 동시에 STARTHESTAR Blockchain Technology Ltd의 기술 팀 재능은 최고의 국제 과학 연구 기관 및 블록 체인 기술 개발 팀에서 온 것으로 사용자 자금과 계정 보안을 완벽하게 보호합니다. 기술 팀 외에도 회사는 전문 블록 체인 팀인 디지털을 보유하고 있습니다. 자산 AI 정량 투자 팀, 블록 체인 프로젝트 가치 평가 팀, 디지털 자산 벤처 투자 팀 등은 글로벌 블록 체인 사용자에게 서비스를 제공하기위한 글로벌 레이아웃을 가지고 있습니다.
            회사의 목표와 비전은 글로벌 디지털 자산 애호가에게 안전하고 안정적이며 사려 깊고 신뢰할 수있는 거래 서비스를 제공하고 전문적이고 데이터 기반이며 국제적으로 유명한 글로벌 블록 체인 기술 회사를 구축하는 것입니다.`,

      item1: {
        tt: '제품 디스플레이',
        pt1: 'OTC 거래 규칙',
        pt2: '초보자 안내서',
        pt3: '요율 표준',
        pt4: '계약 설명'
      },
      item2: {
        tt: '지원',
        pt1: 'KYC 및 자금 세탁 방지 계약',
        pt2: '시행 요청 정책',
        pt3: '디지털 자산 소개'
      },
      item3: {
        tt: '약관',
        pt1: '개인 정보 보호',
        pt2: '사용자 계약',
        pt3: '위험 알림'
      },
      item4: {
        tt: '문의하기',

        pt1: '비즈니스 협력',
        pt2: '기관 협력',
        pt3: 'OTC 응용 프로그램',
        pt4: '고객 서비스 이메일',
        pt5: '불만 사항 및 제안',
        pt6: '미디어 협력'
      }
    },
    bk3: {
      title: '혁신적인 R & D 역량 자랑',
      desc: '금융 거래자를 위해 설계된 상상할 수없는 계약 거래 경험',

      pt1: '오더 중단 이익 및 손실 중단',
      pd1: '보류중인 주문은 늦게까지 머물지 않습니다. 주문을 보지 않을까 걱정합니다.',

      pt2: '열린 주문 취소',
      pd2: '시장이 갑자기 변합니다',

      pt3: '창고 별 전체 창고 지원',
      pd3: '원하는대로하고 원하는대로하십시오',

      pt4: '전체 포지션 단계적 청산',
      pd4: '외국 청산은 먼저 절반이됩니다. 투자자가 가능한 한 손실을 줄 이도록 도와주세요.'
    },
    bk5: {
      title: '계약 거래 플랫폼의 8 가지 장점',
      desc: '우리는 항상 경청하고, 지속적으로 최적화하고 개선하며, 글로벌 거래 사용자를위한 최고 수준의 거래 경험을 만듭니다.',

      pt1: '시장 심도',
      pd1: '플랫폼에있는 처음 세 개의 스톨의 시장 깊이는 수백만 달러에 도달하여 업계의 평균 시장 깊이 인 10,000 및 100,000을 훨씬 초과합니다. 스프레드가 작고 거래가 빠릅니다.',

      pt2: '원활한 거래',
      pd2: '원활한 거래 경험을 보장하기 위해 효율적인 매칭 거래 엔진을 사용하여 초당 수백만 개의 주문을 처리 할 수 ​​있으며 2 천만 이상의 동시 온라인 사용자와 원활하고 안정적인 운영을 지원할 수 있습니다.',

      pt3: '공정한 가격',
      pd3: '"Marked Price"는 세계 3 대 거래소의 실시간 거래 가격을 회귀 계산 지수 가격으로 선택하여 단시간에 최근 거래 가격의 비정상 조작으로 인한 사용자 손실을 효과적으로 방지합니다.',

      pt4: '자체 개발 기술',
      pd4: '전통적인 금융에 뿌리를두고있는 중매 엔진은 금융 업계의 수석 전문가 팀이 개발했으며 거래 플랫폼의 안전하고 안정적이며 효율적인 운영을 보장하기 위해 모든 세부 사항을 세 심하게 제작했습니다.',

      pt5: '최고 위험 관리',
      pd5: '2 단계 판단, 다층 암호화, 높은 시스템 보안. 핫 지갑과 콜드 지갑의 조합, 군사 등급 암호화 조치, 자산 거래 보안이 보장되며 100 % 사용자의 디지털 자산 보안을 보장합니다.',

      pt6: '자금 보안',
      pd6: '3 계층 보안 시스템, 포괄적 인 보안 감사. 업계 최고의 계층 적 결정 론적 콜드 월렛 시스템을 자체 구축하여 모든 사용자가 콜드 월렛 주소를 재충전 주소 할당, 자금 수집 및 인출은 오프라인 서명을 통해 완료됩니다.',

      pt7: '하드 코어 파워',
      pd7: '수만 개의 BTC 보유고, 금융 혁신 유전자 및 세계 최고 경영진. STARTHESTAR 는 시스템상의 이유로 계정 및 거래 데이터 오류가 발생하지 않으면 오류 발생시 플랫폼이 전액 지불 할 것이라고 약속합니다!',

      pt8: '품질 서비스',
      pd8: '연중 무휴 24 시간 전문 고객 서비스, 빠른 응답. 서비스의 생각에서 시작하여 글로벌 고객에게 일류 거래 경험을 제공합니다. 궁극적 인 경험은 고객 서비스 개념에서 비롯됩니다.'
    },
    bk6: {
      title: '안전 및 규정 준수 글로벌 운영',
      desc: '감독을 수용하고 항상 글로벌 규정 준수 작업을 준수'
    },
    bk7: {
      title: '공식 전략 파트너'
    },
    bk8: {
      title: '10 만 명 이상의 글로벌 거래 사용자에게 서비스 제공',
      desc: '현재 사용자는 미국, 일본, 대한민국, 세이셸, 베트남 및 기타 국가에 있습니다.'
    },
    bk9: {
      title: '언제 어디서나 원활한 거래',

      pt1: '실시간 매치 메이킹 거래, 입금 및 출금이 몇 초 안에 도착합니다.',
      pt2: '다중 거래 모드, 최대 125 배 레버리지',

      dt1: 'iOS 버전 다운로드',
      dt2: 'Android 버전 다운로드'
    },
    about: {
      title: '회사 소개',

      pt1: '플랫폼 개념',
      pd1:
        '글로벌 자산이 자유롭게 순환하고 새로운 계약 경험을 창출하게하십시오. X-xenogenetic은 혁신적인 유전자를 나타내며 산업 혁신을위한 꾸준한 힘을 제공합니다. M- 모터는 목표를 나타내며 계속해서 목표를 향해 나아갑니다. .EX-exchange는 거래 플랫폼을 대신하여 서비스, 경청 및 사고라는 운영 철학으로 공정하고 투명하며 효율적인 거래 생태계를 만들기 위해 최선을 다하고 있습니다. ',

      pt2: '핵심 가치',
      pd2: '우리는 항상 경청하고,주의를 기울이고, 앞으로 생각하며, 공정하고 투명하며 효율적인 거래 생태계를 만들기 위해 최선을 다하고 있습니다. 글로벌 거래자에게 더 나은 거래 경험을 제공하고 거래자의 실제 요구를 충족하며 개발을 촉진합니다. 디지털 자산 거래 시장. ',

      pt3: '팀 배경',
      pd3: '창립 팀은 최고의 블록 체인 투자자, 전통적인 재무 임원, 최고의 디지털 자산 전문가 및 전통적인 금속 가공 기술 팀으로 구성되어 있습니다. R & D 팀의 핵심 구성원은 Morgan Stanley, CICC, Hang Seng, Tencent, Noah Wealth 출신입니다.'
    }
  },
  start: {
    p_title: '거래, 여기서 시작',
    p_desc: '즉시 등록하려면 다음 영역에 이메일 주소를 입력하십시오',
    p_email: '이메일',
    p_btn: '만들다'
  },
  download: {
    p_title: '앱 클라이언트 다운로드',
    p_desc: '언제 어디서나, 멀티플랫폼 단말 거래',
    p_btn: '다운로드',
    p_scan_android: 'Android app',
    p_scan_ios: 'IOS app'
  },
  downpage: {
    t1: {
      p_title: 'Lightning 트랜잭션 성능',
      p_desc: '선택할 수 있는 다양한 거래 유형 제공, 주문 매칭이 매우 빠르며, 원클릭 플래시 교환, 거래가 더 편리합니다.'
    },
    t2: {
      p_title: '자산의 손쉬운 이전',
      p_desc: '계정에 있는 자산의 손쉬운 이체, 디지털 자산의 실시간 개요, 통화 비율의 파이 차트 증가'
    },
    t3: {
      p_title: '사용자 지정 거래 패널',
      p_desc: '다양한 트레이딩 습관에 따라 트레이딩 레이아웃을 자유롭게 정의하고 K-라인 및 깊이 맵의 진입을 증가'
    },
    t4: {
      p_title: '계정 통합 관리',
      p_desc: '여러 보안 확인 설정, 하나의 계정이 C2C 계정 및 통화 계정 관리'
    }
  },
  login: {
    login: '로그인',
    title: '사용자 로그인',
    desc1: '로그인하세요',
    desc2: '공식 웹 사이트',
    email: '사서함',
    email2: '이메일',
    email3: '전화번호',
    email_desc: '이메일을 입력 해주세요',
    phone_desc: 'Please enter phone number',
    password: '암호',
    password_desc: '비밀번호를 입력하세요',
    forgot_password: '비밀번호를 잊으 셨나요',
    no_account: '아직 계정이 없습니다'
  },
  signup: {
    signup: '등기',
    title: '사용자 등록',
    desc1: '로그인하세요',
    desc2: '공식 웹 사이트',
    invitation_code: '초대 코드',
    invitation_desc: '초대 코드를 입력하세요',
    accept: '동의하기',
    service: '사용자 동의',
    and: '와',
    policy: '개인 정보 정책',
    have_account: '이미 계정이 있습니다'
  },
  retrieve: {
    ptitle: '비밀번호 검색',
    pemail_code: '이메일 확인 코드',
    pset: '암호 설정',
    set_title: '비밀번호 설정',
    set_desc: '비밀번호를 재설정하고 있습니다. 다음 설정을 완료하십시오',
    new_password: '새 비밀번호',
    password_desc: '새 암호를 입력하십시오',
    res_passowrd: '암호 확인',
    res_passowrd_desc: '확인 암호를 입력하십시오'
  },
  valid_email: {
    title: '등록 확인',
    desc: '계정을 등록하고 있습니다. 다음 확인을 완료하세요.',
    email_code: '이메일 확인 코드',
    email_desc: '인증 코드를 입력하세요',
    get_code: '인증 코드 받기'
  },
  ihead: {
    begin_trade: '거래 시작',
    helper: '도움이되는 새로운 손',
    text: '문서 센터',
    new_currency: '새 통화 구독'
  },
  uhead: {
    title1: '상품',
    title2: '국제 외환',
    title3: '국제 색인',
    currency: '디지털 통화',
    lever: '레버리지 거래',
    coins: '통화 거래',
    contract: '계약 거래',
    contract_info: '계약 정보',
    helper: '도움이되는 새로운 손',
    text: '문서 센터',
    assets: '자산',
    u_wallet: '내 지갑',
    u_contract: '계약 계정',
    u_lever: '마진 계정',
    u_account: '계정 정보',
    u_logout: '로그 아웃'
  },
  news: {
    label: '뉴스',
    update: '업데이트 날짜'
  },
  guide: {
    label: '초보자 안내서',
    list: [
      {
        ptitle: 'STARTHESTAR 등록 방법',
        pkey: 'k1'
      },
      {
        ptitle: '신원 인증',
        pkey: 'k2'
      },
      {
        ptitle: '로그인 비밀번호 및 거래 비밀번호 설정',
        pkey: 'k3'
      },
      {
        ptitle: '코인 입금',
        pkey: 'k4'
      },
      {
        ptitle: '동전 거래 프로세스',
        pkey: 'k5'
      },
      {
        ptitle: '출금',
        pkey: 'k6'
      },
      {
        ptitle: ' 모바일 앱 다운로드',
        pkey: 'k7'
      }
    ],
    content: {
      k1: require('./currency/ko/guide/k1.js').default,
      k2: require('./currency/ko/guide/k2.js').default,
      k3: require('./currency/ko/guide/k3.js').default,
      k4: require('./currency/ko/guide/k4.js').default,
      k5: require('./currency/ko/guide/k5.js').default,
      k6: require('./currency/ko/guide/k6.js').default,
      k7: require('./currency/ko/guide/k7.js').default
    }
  },
  kline: {
    h24_high: '24H제일 높은',
    h24_low: '24H최저',
    h24_vol: '24H음량',
    commission: '현재 커미션',
    record: '거래 기록',
    commission_list: '대리인 목록',
    deal_list: '최근 거래',
    price: '가격',
    number: '수량',
    total: '합계',
    time: '시각',
    currency: '통화',
    type: '유형',
    direction: '방향',
    multiple: '배수',
    fee: '수수료',
    amount: '일시불',
    status: '상태',
    operate: '운영',
    buy: '구입',
    sell: '팔다',
    pending: '주문 보류',
    trade_success: '성공적인 거래',
    retract: '빼다',
    profit: '이익을 얻으십시오',
    loss: '손실 중지',

    all: '모두',
    all_currency: '모든 통화',
    new_price: '최근 가격',
    change: '상승 및 하강',
    protocol: '규약',
    _status1: '검토 중',
    _status2: '출금 성공',
    _status3: '거부됨'
  },
  coins: {
    title: '통화 거래',
    market: '시장 가격',
    limit: '가격 제한',
    profit: '이익을 얻으십시오',
    loss: '손실 중지',
    price: '가격',
    current: '흐름',
    price_desc: '가격을 입력하세요',
    number: '수량',
    number_desc: '수량을 입력하세요',
    available: '유효한',
    half: '반 창고',
    all: '전체 창고',
    fee: '수수료',
    amount: '거래 금액',
    buy: '구매',
    sell: '팔다'
  },
  lever: {
    title: '레버리지 거래',
    multiple: '배수',
    multiple_desc: '여러 개를 선택하세요',
    double: '타임스',
    buy: '구매(오르기)',
    sell: '팔다(가을)'
  },
  contract: {
    title: '계약 거래',
    time: '시각',
    time_desc: '시간을 선택하세요',
    minute: '분',
    second: '둘째',
    buy: '구매(오르기)',
    sell: '팔다(가을)',
    rate: '로열티 비율'
  },
  wallet: {
    title: '내 자산',
    valuation: '총 자산 평가',
    my_wallet: '내 지갑',
    my_trade: '거래 계좌',
    my_transfer: '출금 기록',
    my_deposit: '코인 입금 기록',
    cbdz: '입금 주소',
    cbsl: '수량',
    deposit: '코인 입금',
    withdrawal: '철수',
    search: '검색',
    currency: '통화',
    protocol: '규약',

    liquidation: '마감 포지션',
    liquidation_title: '빠른 청산',
    trade_pwd: '거래 비밀번호',
    enter_trade_pwd: '거래 비밀번호 입력',
    submit_liquidation: '즉시 포지션 청산',

    amount: '일시불',
    available: '유효한',
    freeze: '얼다',
    market: '평가',
    ljsy: '누적 수입',
    zrsy: '어제 수입',
    operate: '운영',
    countdown: '카운트 다운',

    lever_title: '마진 계정',
    contract_title: '계약 계정',
    direction: '방향',
    bond: '여유',
    buy_rate: '배수',
    profit: '유동적 손익',
    profit1: '이익과 손실',
    buy: '구입',
    sell: '팔다',
    many: '구입',
    empty: '팔다',

    contract_time: '계약 시간',
    premium: '프리미엄',
    minute: '분',

    address: '주소',
    address_desc: '주소를 입력하세요',
    number: '수량',
    number_desc: '출금 금액을 입력하세요',
    copy: '부',
    confirm: '결정',

    all: '모두',
    fee: '수수료',
    actual: '실제로 도착했습니다',

    premark: '주의',
    text_1: '이 주소는 {name} 자산 만 허용합니다. 다른 통화를 재충전하지 마십시오. 그렇지 않으면 자산이 검색되지 않습니다.',
    text_2: '{name} 채굴 자 수수료 : 현재 시장 가치 {rate} % / 거래, 최소 기준 : {min}{name} / 거래',
    text_3: '양도하기 전에 수령 주소 정보가 정확한지 확인하십시오. 자산이 양도 된 후에는 인출 할 수 없습니다.',
    text_4: '{name} 채굴 수수료: {val} {name}/펜'
  },
  record: {
    title: '자금 기록',
    type: '유형',
    all_currency: '모든 통화',
    all_type: '모든 유형',
    select: '문의',
    clear: '필터 지우기',
    receive: '저장',
    transfer: '철회',
    buy: '구매',
    sell: '팔다',
    normal: '일반 커미션',
    pending: '이익을 얻고 손절매'
  },
  user: {
    account: '계정 확인',
    account_desc: '예금, 거래 및 기타 기능은 먼저 계좌 확인을 통과해야합니다.',

    password: '로그인 비밀번호',
    password_desc: '계정 보안을 보호하는 데 사용',

    modify: '수정',
    set: '설정',

    find_paypwd: '비밀번호 검색',
    find_pay_pwd: '결제 비밀번호 검색',
    email: '이메일',
    enter_email: '이메일을 입력하십시오',
    email_code: '인증 코드',
    enter_email_code: '이메일 인증 코드를 입력하십시오',
    send: '보내기',

    pay_password: '결제 비밀번호',
    pay_password_desc: '거래시 자산 안전을 보호하기 위해 사용',

    not_certified: '인증되지 않음',
    certification: '인증',
    verified: '확인 됨',
    rejected: '거부 됨',

    update_pwd: '로그인 비밀번호 수정',
    update_pay_pwd: '결제 비밀번호 수정',
    set_pay_pwd: '결제 비밀번호 설정',

    old_pwd: '원래 암호',
    enter_old_pwd: '원래 암호를 입력하십시오',

    new_pwd: '새 비밀번호',
    enter_new_pwd: '새 암호를 입력하십시오',

    res_pwd: '암호 확인',
    enter_res_pwd: '확인 암호를 입력하십시오',

    submit: '제출'
  },
  certified: {
    title: '계정 인증',
    front: '신분증 앞면',
    back: '신분증 뒷면',
    hand: '신분증 소지',
    name: '이름',
    name_desc: '이름을 입력하세요',
    card: '식별 번호',
    card_desc: 'ID 번호를 입력하세요',
    submit: '제출'
  },
  trade: {
    title: '거래 비밀번호',
    enter_password: '거래 비밀번호를 입력하세요',
    cancel: '취소',
    submit: '제출'
  },
  revoke: {
    title: '프롬프트 메시지',
    message: '주문을 취소 하시겠습니까?',
    cancel: '취소',
    submit: '확인'
  },
  coins_assets: {
    title: '통화 자산',
    total_equity: '총 계좌 자산',
    equity: '계정 자산',
    number: '보유 수',
    market_value: '현재 평가'
  },
  lever_assets: {
    title: '레버리지 자산',
    total_equity: '총 계좌 자산',
    equity: '계정 자산',
    profit: '미실현 손익',
    risk: '위험'
  },
  contract_assets: {
    title: '계약 자산',
    total_equity: '총 계좌 자산',
    equity: '계정 자산',
    profit: '미실현 손익'
  },

  //文档菜单
  contract_menu: {
    contract: '계약 세부 사항',
    lever: '레버리지 세부 정보',
    coins: '코인 인덱스',
    history: '펀드 이율 내역',
    coins_rules: [
      {
        value: '1D',
        name: '그 날'
      },
      {
        value: '1W',
        name: '그 주'
      },
      {
        value: '1M',
        name: '이번 달'
      },
      {
        value: '1J',
        name: '현재 시즌'
      }
    ],
    lever_info: '정보 활용',
    contract_info: '계약 정보'
  },

  //合约详情
  contract_info: {
    bzj: '마진',

    pt1: '결제 통화',
    pt2: '계약 액면가',
    pt3: '최소 변경 가격',
    pt4: '계약 기간',
    pt5: '펀드 이율 시간',
    pt6: '표시 가격',
    pt7: '포지션 볼륨',
    pt8: '24 시간 거래량',
    pt9: '권한',
    pt10: '만기 분만 수입',
    pt11: '오픈 포지션 스프레드',
    pt12: '배송 및 정산 메커니즘',
    pt13: '거래 서비스 수수료',
    pt14: '위치 잠금',

    pv5: '매일 08:00 16:00 24:00',
    pv10_1: '롱 포지션 : (평균 종가-평균 시가) x 수량',
    pv10_2: '매도 포지션 : (평균 시가-평균 종가) x 수량',
    pv11: '없음',
    pv12: '만기시 자동 배송',
    pv13: '없음',
    pv14: '지원되지 않음'
  },

  //杠杆详情
  lever_info: {
    title: '레버리지 세부 정보',
    bei: '시간',

    pt1: '결제 통화',
    pt2: '계약 액면가',
    pt3: '최소 변경 가격',
    pt4: '계약 기간',
    pt5: '펀드 이율 시간',
    pt6: '표시 가격',
    pt7: '포지션 볼륨',
    pt8: '24 시간 거래량',
    pt9: '위험 준비금',
    pt10: '청산 이익',
    pt11: '오픈 포지션 스프레드',
    pt12: '자동 청산 메커니즘',
    pt13: '거래 서비스 수수료',
    pt14: '위치 잠금',

    pv4: '지속 가능',
    pv5: '매일 08:00 16:00 24:00',
    pv10_1: '롱 포지션 : (평균 종가-평균 시가) * 수량',
    pv10_2: '매도 포지션 : (평균 시가-평균 종가) * 수량',
    pv12: '예비 위험 비율이 80 %에 도달',
    pv13: '계약 금액',
    pv14: '지원되지 않음'
  },

  //币币信息
  coins_desc: require('./currency/ko/coins.js').default,

  //资金费率历史
  fun_history: {
    t1: '날짜',
    t2: '예상 자금 조달 비율',
    t3: '실제 자금 조달 비율'
  },

  // 文档
  docs: {
    list: [
      {
        name: 'OTC 거래 규칙',
        key: 'otc'
      },
      {
        name: 'KYC 및 자금 세탁 방지 계약',
        key: 'kyc'
      },
      {
        name: '법 집행 요청 정책',
        key: 'law'
      },
      {
        name: '개인 정보 보호',
        key: 'privacy'
      },
      {
        name: '사용자 동의',
        key: 'protocol'
      },
      {
        name: '위험 알림',
        key: 'risk'
      }
    ],
    otc: require('./currency/ko/otc').default,
    kyc: require('./currency/ko/kyc').default,
    law: require('./currency/ko/law').default,
    privacy: require('./currency/ko/privacy').default,
    protocol: require('./currency/ko/protocol').default,
    risk: require('./currency/ko/risk').default
  },

  // 数字资产介绍
  asset: {
    title: '디지털 자산 소개',
    list: '디지털 자산 목록'
  },
  num_asset: require('./currency/ko/asset').default,

  //新币认购
  new_currency: {
    _title: '새 통화 구독',
    _all: '모두',
    _progress: '진행 중',
    _end: '위에',
    _success: '교환 성공',
    _progress_str: '진행',
    _total_str: '총 방출',
    _date_time: '시작 및 종료 날짜',
    _submit: '지금 사용',

    _base_title: '기본 정보',
    _status: '상태',
    _currency: '허용되는 통화',
    _symbol: '통화 기호',
    _amount: '전체 해제된 금지',
    _price: '초기 가격',
    _limit: '개인 구매 한도',
    _limit_amount: '개인 구매 한도',
    _limit_one: '개인 단일 제한 하한',
    _no_limit: '제한 없음',
    _position: '위치',
    _begin_time: '시작 시간',
    _end_time: '종료 시간',
    _redeeded: '상환된 금액',
    _surplus: '잉여',
    _wallet: '지갑',
    _have: '현재 보유 중',
    _input: '입력 수량',
    _choose: '선택하세요',

    _new_wallet: '새 통화 계정',
    _new_price: '최신 가격',
    _lock: '잠금 기간',

    _login: '로그인해주세요',
    _no_data: '데이터 없음',

    _avg_price: '평균 가격'
  }
}
