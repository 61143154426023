module.exports = {
  base: {
    title: 'STARTHESTAR',
    desc1: 'rey del contrato',
    desc2: 'Líder mundial en negociación por contrato',
    d1: 'Confianza',
    d2: 'Honestidad',
    d3: 'Fortaleza',
    more: 'Más',
    top: 'volver a la cima',
    download: 'Descargar aplicación',
    zszh: 'Cuenta real',
    mnzh: 'Cuenta de demostración',
    content: 'Opere con un líder del mercado mundial en el comercio por contrato',
    lxkf: 'Contactar con servicio al cliente',
    chxx: 'Retirar',
    zx: 'en línea'
  },
  demo: {
    title: 'éxito de registro!',
    title2: 'cuenta analógica',
    submit: 'Confirmar'
  },
  lang: {
    title: 'Español'
  },
  message: {
    invalid_email: 'Email inválido',
    invalid_password: 'Contraseña invalida',
    invalid_new_password: 'Nueva contraseña no válida',
    invalid_res_password: 'Contraseña de confirmación no válida',
    invalid_email_code: 'Código de verificación de correo electrónico no válido',
    password_inconsistent: 'Contraseñas inconsistentes',
    invalid_invitation_code: 'Código de invitación no válido',
    should_agree: 'Por favor, lea y acepte los términos de registro',
    not_null: 'Este campo no puede estar vacío',
    send_email: 'Vuelva a intentarlo después de {:s}s',
    invalid_number: 'valor no válido',
    invalid_balance: 'Saldo inválido',
    invalid_rate: 'Seleccione varios',
    invalid_rule: 'Por favor elige una regla',
    invalid_system_rule: 'No hay reglas en el sistema',
    not_enough: 'No hay suficiente disponible',
    price_high: 'El precio debe ser superior a {price}',
    price_low: 'El precio debe ser inferior a {price}',
    number_high: 'El número máximo de retiros es {num}',
    number_low: 'El monto mínimo de retiro es {num}',
    copy_address: 'Copiar dirección con éxito {texto}',
    invalid_upload_front: 'Por favor suba la foto de frente del DNI',
    invalid_upload_back: 'Sube una foto del reverso de tu DNI',
    invalid_upload_hand: 'Cargue una foto de identificación de mano',
    invalid_upload_name: 'Por favor ingrese el nombre del certificado',
    invalid_upload_card: 'Por favor ingrese el número de identificación',
    upload_pic_type: 'Cargar imágenes solo puede estar en formato JPG, PNG',
    upload_pic_size: 'El tamaño de la imagen cargada no puede exceder los 10 MB'
  },
  index: {
    title2: 'Comercio libre en el mercado global las 24 horas sin parar',
    desc2: 'Implemente su estrategia comercial en un mercado extremadamente líquido, con un volumen comercial de 24 horas de',
    desc2_1: 'en toda la plataforma',
    list: {
      name: 'mercado',
      h24_price: '24H último precio',
      h24_change: 'Cambio 24H',
      h24_high: '24H precio más alto',
      h24_low: 'Precio más bajo 24H',
      h24_vol: 'Volumen 24H'
    },
    footer: {
      desc:
        'starthestar Blockchain Technology Ltd, con sede en los Estados Unidos, es una empresa de tecnología de cadena de bloques líder en el mundo. La empresa ha obtenido la licencia financiera internacional US MSB. Es una empresa de servicios de tecnología de cadena de bloques compatible que puede operar de conformidad con los servicios comerciales de cadena de bloques a escala mundial. Al mismo tiempo, los talentos del equipo técnico de starthestar Blockchain Technology Ltd provienen de las principales instituciones internacionales de investigación científica y equipos de desarrollo de tecnología blockchain para proteger completamente los fondos de los usuarios y la seguridad de la cuenta. Además del equipo técnico, la empresa también cuenta con un equipo profesional de blockchain: el equipo de inversión cuantitativa de IA de activos digitales, el equipo de evaluación del valor del proyecto de blockchain, el equipo de inversión de riesgo de activos digitales, etc., tienen un diseño global para servir a los usuarios globales de blockchain. El objetivo y la visión de la compañía es proporcionar servicios de transacciones seguros, estables, considerados y confiables para los entusiastas de los activos digitales globales, y construir una compañía de tecnología blockchain de renombre mundial, profesional, digital e internacional.',
      item1: {
        tt: 'Pantalla del producto',
        pt1: 'Reglas de negociación OTC',
        pt2: 'Guía para principiantes',
        pt3: 'Tarifa Estándar',
        pt4: 'Descripción del contrato'
      },
      item2: {
        tt: 'Apoyo',
        pt1: 'Acuerdo KYC y Anti-Lavado de Dinero',
        pt2: 'Política de Solicitud de Ejecución',
        pt3: 'Introducción a los activos digitales'
      },
      item3: {
        tt: 'Términos',
        pt1: 'Protección de la privacidad',
        pt2: 'Acuerdo del Usuario',
        pt3: 'Notificación de riesgos'
      },
      item4: {
        tt: 'Contáctenos',
        pt1: 'Cooperación empresarial',
        pt2: 'Cooperación institucional',
        pt3: 'solicitud de venta libre',
        pt4: 'Correo electrónico de atención al cliente',
        pt5: 'Quejas y Sugerencias',
        pt6: 'cooperación con los medios'
      }
    },
    bk3: {
      title: 'Orgulloso de la fuerza innovadora de I+D',
      desc: 'Experiencia de negociación de contratos inimaginable, diseñada para operadores financieros',
      pt1: 'Orden abierta stop loss y stop loss',
      pd1: 'Pedidos pendientes no te quedes despierto hasta tarde, preocúpate de no estar pendiente del pedido',
      pt2: 'Orden abierta inversa',
      pd2: 'El mercado cambia repentinamente, sigue el ritmo en un segundo',
      pt3: 'Admite almacén completo por almacén',
      pd3: 'Haz lo que quieras, haz lo que quieras',
      pt4: 'Liquidación escalonada de posición completa',
      pd4: 'La liquidación extranjera primero es igual a la mitad y trata de ayudar a los inversores a reducir las pérdidas'
    },
    bk5: {
      title: 'Ocho ventajas de la plataforma de negociación por contrato',
      desc: 'Escuchamos todo el tiempo, optimizamos y mejoramos continuamente, y creamos una experiencia comercial de primera clase para los usuarios comerciales globales.',
      pt1: 'Profundidad del mercado',
      pd1: 'La profundidad de mercado de los tres primeros puestos en la plataforma ha alcanzado el orden de millones de dólares, superando con creces la profundidad de mercado promedio de la industria de 10.000 y 100.000. El margen es pequeño y la transacción es rápida.',
      pt2: 'Transacción sin problemas',
      pd2: 'Utilizando un motor de transacciones coincidente eficiente para garantizar una experiencia de transacción fluida, puede procesar millones de pedidos por segundo y puede soportar una operación estable y sin problemas con más de 20 millones de usuarios en línea simultáneos.',
      pt3: 'Precio justo',
      pd3:
        "'Marked Price' selecciona el precio de transacción en tiempo real de las tres principales bolsas del mundo como precio índice para el cálculo de regresión, lo que evita de manera efectiva las pérdidas de usuarios causadas por la manipulación anormal del último precio de transacción en un corto período de tiempo.",
      pt4: 'Tecnología de desarrollo propio',
      pd4: 'El motor de emparejamiento arraigado en las finanzas tradicionales, desarrollado por un equipo de expertos senior en la industria financiera, y elaborado meticulosamente cada detalle para garantizar el funcionamiento seguro, estable y eficiente de la plataforma de negociación.',
      pt5: 'Control de riesgos superior',
      pd5: 'Juicio de segundo nivel, cifrado multicapa, alta seguridad del sistema. La combinación de billeteras calientes y frías, medidas de cifrado de grado militar, seguridad de transacciones de activos está garantizada y garantiza al 100% la seguridad de los activos digitales de los usuarios.',
      pt6: 'Seguridad de los fondos',
      pd6:
        'Sistema de seguridad de tres capas, auditoría de seguridad integral. El sistema de monedero frío determinista jerárquico de construcción propia líder en la industria, todos los usuarios asignan direcciones de recarga a direcciones de monedero frío, la recolección y el retiro de fondos se completan a través de firmas fuera de línea.',
      pt7: 'Poder incondicional',
      pd7:
        'Decenas de miles de reservas de BTC, genes de innovación financiera y el equipo directivo superior del mundo. starthestar promete que si no se producen errores en los datos de la cuenta y de la transacción debido a razones del sistema, ¡la plataforma pagará la totalidad en caso de errores!',
      pt8: 'Servicio de calidad',
      pd8: 'Servicio al cliente profesional las 24 horas, las 24 horas, respuesta rápida. A partir de la idea de servicio, brinde una experiencia comercial de primera clase para clientes globales. La máxima experiencia proviene del concepto de servir a los clientes.'
    },
    bk6: {
      title: 'Operación global de seguridad y cumplimiento',
      desc: 'Adopte la supervisión y adhiérase siempre a las operaciones de cumplimiento global'
    },
    bk7: {
      title: 'Socio Estratégico Oficial'
    },
    bk8: {
      title: 'Sirvió a más de 100.000 usuarios comerciales globales',
      desc: 'Actualmente, los usuarios se encuentran en todo Estados Unidos, Japón, Corea del Sur, Seychelles, Vietnam y otros países.'
    },
    bk9: {
      title: 'Transacción fluida en cualquier momento y en cualquier lugar',
      pt1: 'Las transacciones de emparejamiento en tiempo real, los depósitos y los retiros llegan en segundos',
      pt2: 'Múltiples modos de negociación, hasta 125 veces de apalancamiento',
      dt1: 'Descarga de la versión de iOS',
      dt2: 'Descarga de la versión de Android'
    },
    about: {
      title: 'Sobre nosotros',
      pt1: 'Concepto de plataforma',
      pd1:
        'Deje que los activos globales circulen libremente y cree una nueva experiencia de contrato. X-xenogenetic, representa genes innovadores y proporciona un flujo constante de poder para la innovación de la industria. M-motor representa el objetivo y continúa avanzando con el objetivo de liderar la experiencia de negociación por contrato. EX-exchange, en nombre de la plataforma de negociación, se compromete a crear un ecosistema de negociación justo, transparente y eficiente con la filosofía operativa de servicio, escucha y pensamiento.',
      pt2: 'Valores fundamentales',
      pd2:
        'Siempre estamos escuchando, prestando atención y pensando en el futuro, y estamos comprometidos a crear un ecosistema comercial justo, transparente y eficiente. Proporcione a los comerciantes globales una mejor experiencia comercial, satisfaga las necesidades reales de los comerciantes y promueva el desarrollo del mercado de comercio de activos digitales.',
      pt3: 'Antecedentes del equipo',
      pd3:
        'El equipo fundador está compuesto por los principales inversores de blockchain, ejecutivos financieros tradicionales, los mejores expertos en activos digitales y equipos tradicionales de tecnología metalúrgica. Los miembros principales del equipo de I+D son de Morgan Stanley, CICC, Hang Seng, Tencent, Noah Wealth.'
    }
  },
  start: {
    p_title: 'Transacción, comience aquí',
    p_desc: 'Ingrese su dirección de correo electrónico en la siguiente área para registrarse de inmediato',
    p_email: 'correo electrónico',
    p_btn: 'Crear'
  },
  download: {
    p_title: 'Descargar aplicación cliente',
    p_desc: 'En cualquier momento, en cualquier lugar, transacción de terminal multiplataforma',
    p_btn: 'Descargar',
    p_scan_android: 'aplicación Android',
    p_scan_ios: 'aplicación para iOS'
  },
  downpage: {
    t1: {
      p_title: 'Rendimiento de transacciones relámpago',
      p_desc: 'Proporcione una variedad de tipos de transacciones para elegir, la coincidencia de pedidos es extremadamente rápida, el intercambio rápido con un clic y el comercio es más conveniente'
    },
    t2: {
      p_title: 'Fácil transferencia de activos',
      p_desc: 'Fácil transferencia de activos en la cuenta, descripción general en tiempo real de los activos digitales, aumento del gráfico circular de la relación de divisas'
    },
    t3: {
      p_title: 'Panel de comercio personalizado',
      p_desc: 'Defina libremente el diseño comercial de acuerdo con los diferentes hábitos comerciales y aumente la entrada de la línea K y el mapa de profundidad'
    },
    t4: {
      p_title: 'Gestión de integración de cuentas',
      p_desc: 'Múltiples configuraciones de verificación de seguridad, una cuenta administra cuentas C2C y cuentas de divisas'
    }
  },
  login: {
    login: 'acceso',
    title: 'Inicio de sesión de usuario',
    desc1: 'Ingresa, por favor',
    desc2: 'Página web oficial',
    email: 'Correo electrónico',
    email2: 'Correo electrónico',
    email3: 'Número de teléfono',
    email_desc: 'Por favor ingrese su correo electrónico',
    phone_desc: 'Por favor ingrese el número de teléfono',
    password: 'contraseña',
    password_desc: 'Por favor introduzca la contraseña',
    forgot_password: 'Has olvidado tu contraseña',
    no_account: 'No tienes cuenta aún'
  },
  signup: {
    signup: 'Inscribirse',
    title: 'registro de usuario',
    desc1: 'Bienvenido a iniciar sesión',
    desc2: 'Página web oficial',
    invitation_code: 'código de invitación',
    invitation_desc: 'Por favor, introduzca el código de invitación',
    accept: 'Aceptar',
    service: 'Acuerdo del Usuario',
    and: 'y',
    policy: 'Política de protección de privacidad',
    have_account: 'Ya tienes una cuenta'
  },
  retrieve: {
    ptitle: 'Recuperar Contraseña',
    pemail_code: 'Código de verificación de correo electrónico',
    pset: 'Configurar la clave',
    set_title: 'Configurar la clave',
    set_desc: 'Estás restableciendo tu contraseña, por favor completa la siguiente configuración',
    new_password: 'Nueva contraseña',
    password_desc: 'Por favor ingrese una nueva contraseña',
    res_passowrd: 'confirmar Contraseña',
    res_passowrd_desc: 'Por favor ingrese la contraseña de confirmación'
  },
  valid_email: {
    title: 'Verificación de registro',
    desc: 'Estás registrando una cuenta, por favor completa la siguiente verificación',
    email_code: 'Código de verificación de correo electrónico',
    email_desc: 'porfavor ingrese el codigo de verificacion',
    get_code: 'Obtener código de verificación'
  },
  ihead: {
    begin_trade: 'Comienza a negociar',
    helper: 'Ayuda para principiantes',
    text: 'Centro de documentación',
    new_currency: 'Nueva suscripción de moneda'
  },
  uhead: {
    title1: 'materias primas',
    title2: 'Forex internacional',
    title3: 'Índice Internacional',
    currency: 'Divisa',
    lever: 'Transacción apalancada',
    coins: 'Transacción de divisas',
    contract: 'Transacción de contrato',
    metal: 'Metal',
    energy: 'Energía',
    contract_info: 'Información del contrato',
    helper: 'Ayuda para principiantes',
    text: 'Centro de documentación',
    assets: 'Activos',
    u_wallet: 'Mi billetera',
    u_contract: 'Cuenta de contrato',
    u_lever: 'Cuenta de apalancamiento',
    u_account: 'Información de la cuenta',
    u_logout: 'Cerrar sesión'
  },
  news: {
    label: 'Noticias',
    update: 'Actualizado en'
  },
  guide: {
    label: 'Guía para principiantes',
    list: [
      {
        ptitle: 'Cómo registrarse en starthestar',
        pkey: 'k1'
      },
      {
        ptitle: 'Autenticación de identidad',
        pkey: 'k2'
      },
      {
        ptitle: 'Configuración de contraseña de inicio de sesión y contraseña de transacción',
        pkey: 'k3'
      },
      {
        ptitle: 'depositar monedas',
        pkey: 'k4'
      },
      {
        ptitle: 'Proceso de transacción de monedas',
        pkey: 'k5'
      },
      {
        ptitle: 'Retiro',
        pkey: 'k6'
      },
      {
        ptitle: 'Descarga de la aplicación móvil ',
        pkey: 'k7'
      }
    ],
    content: {
      k1: require('./currency/en/guide/k1.js').default,
      k2: require('./currency/en/guide/k2.js').default,
      k3: require('./currency/en/guide/k3.js').default,
      k4: require('./currency/en/guide/k4.js').default,
      k5: require('./currency/en/guide/k5.js').default,
      k6: require('./currency/en/guide/k6.js').default,
      k7: require('./currency/en/guide/k7.js').default
    }
  },
  kline: {
    h24_high: '24H alto',
    h24_low: '24H bajo',
    h24_vol: 'Volumen 24H',
    commission: 'Comisión actual',
    record: 'Registro de la transacción',
    commission_list: 'Lista de comisiones',
    deal_list: 'Última oferta',
    price: 'Precio',
    xprice: 'margen de beneficios',
    bzj: 'Margen',
    number: 'Número',
    total: 'Total',
    time: 'Tiempo',
    currency: 'Posiciones',
    type: 'Tipo',
    direction: 'Dirección',
    multiple: 'Aprovechar',
    fee: 'Comisión',
    amount: 'Total',
    status: 'Estado',
    operate: 'Operación',
    buy: 'Comprar',
    sell: 'Vender',
    pending: 'Orden pendiente',
    trade_success: 'Comercio exitoso',
    retract: 'Cancelar',
    profit: 'Saca provecho',
    loss: 'Detener la pérdida de',
    all: 'Todo',
    all_currency: 'Todas las monedas',
    new_price: 'Último precio',
    change: 'Levantarse y caer',
    protocol: 'protocolo',
    _status1: 'Revisar',
    _status2: 'Exitoso',
    _status3: 'Rechazado'
  },
  coins: {
    title: 'Transacción de divisas',
    market: 'precio de mercado',
    limit: 'Precio límite',
    profit: 'Saca provecho',
    loss: 'Detener la pérdida de',
    price: 'Precio',
    current: 'Actual',
    price_desc: 'Por favor ingrese el precio',
    number: 'Número',
    number_desc: 'Por favor ingrese el número',
    available: 'Disponible',
    half: 'medio almacén',
    all: 'Almacén completo',
    fee: 'Comisión',
    amount: 'cantidad de transacción',
    buy: 'Comprar',
    sell: 'Vender',
    current_price: 'Actual',
    open_price: 'Abierto hoy',
    high: 'Alto',
    low: 'Bajo',
    yesterday: 'cerrar ayer',
    today_zf: 'Cambio de precio',
    now_cc: 'Posición',
    y_cc: 'Posición Y',
    jsj: 'Precio acordado',
    zrjsj: 'Y precio de liquidación'
  },
  lever: {
    title: 'Transacción apalancada',
    multiple: 'Aprovechar',
    multiple_desc: 'Seleccione un múltiplo',
    double: 'doble',
    buy: 'Comprar)',
    sell: 'Vender (caída)'
  },
  contract: {
    title: 'Transacción de contrato',
    time: 'Tiempo',
    time_desc: 'Por favor selecciona una hora',
    minute: 'minuto',
    second: 'Segundo',
    buy: 'Comprar)',
    sell: 'Vender (caída)',
    rate: 'Proporción de regalías'
  },
  wallet: {
    title: 'Mis activos',
    valuation: 'Valoración total de activos',
    my_wallet: 'Mi billetera',
    my_trade: 'Cuenta de operaciones',
    my_transfer: 'registro de retiros',
    my_deposit: 'Registro de depósito de monedas',
    cbdz: 'dirección de depósito',
    cbsl: 'Cantidad',
    deposit: 'depositar monedas',
    withdrawal: 'Retiro',
    search: 'Buscar',
    currency: 'Posiciones',
    protocol: 'Protocolo',
    liquidation: 'Cerrar Posición',
    liquidation_title: 'Liquidación Rápida',
    trade_pwd: 'Contraseña comercial',
    enter_trade_pwd: 'Ingrese la contraseña comercial',
    submit_liquidation: 'Cerrar la posición inmediatamente',
    amount: 'Total',
    available: 'Disponible',
    freeze: 'Congelar',
    market: 'valuación',
    ljsy: 'Ingresos acumulados',
    zrsy: 'Ganancias de ayer',
    operate: 'Operación',
    countdown: 'cuenta regresiva',
    lever_title: 'Cuenta de apalancamiento',
    contract_title: 'Cuenta de contrato',
    direction: 'Dirección',
    bond: 'Margen',
    buy_rate: 'múltiple',
    profit: 'Ganancia y perdida',
    profit1: 'Ganancia y perdida',
    buy: 'Comprar',
    sell: 'Vender',
    many: 'Comprar',
    empty: 'Vender',
    contract_time: 'Tiempo de contrato',
    premium: 'Privilegio',
    minute: 'minuto',
    address: 'DIRECCIÓN',
    address_desc: 'Por favor ingrese la dirección',
    number: 'Número',
    number_desc: 'Por favor ingrese el número de retiros',
    copy: 'Copiar',
    confirm: 'DE ACUERDO',
    all: 'Todo',
    fee: 'Comisión',
    actual: 'llegada real',
    premark: 'Atención',
    text_1: 'Esta dirección solo acepta activos de {name}, no recargue otras monedas, de lo contrario, los activos no se recuperarán',
    text_2: 'La tarifa del minero de {name} es: valor de mercado actual {tasa}%/transacción, estándar mínimo: {min}{name}/transacción',
    text_3: 'Confirme que la información de la dirección de recepción sea correcta antes de transferir los fondos. Una vez que los activos se transfieren, no se pueden retirar.',
    text_4: '{name} La tarifa minera es: {val} {name}/pen'
  },
  record: {
    title: 'Registro de fondos',
    type: 'Tipo',
    all_currency: 'Todas las monedas',
    all_type: 'Todos los tipos',
    select: 'Consulta',
    clear: 'Filtro claro',
    receive: 'Depósito',
    transfer: 'Retiro',
    buy: 'Comprar',
    sell: 'Vender',
    normal: 'Común',
    pending: 'Límite de parada',
    wallet: 'Detalles del puesto',
    zzzqy: 'Balance',
    zqy: 'Margen libre',
    fdyk: 'P/L flotante',
    fxl: 'Tasa de riesgo'
  },
  user: {
    account: 'Autenticación de cuenta',
    account_desc: 'El depósito de crédito, la transacción y otras funciones primero deben pasar la verificación de la cuenta',
    password: 'Contraseña de inicio de sesión',
    password_desc: 'Se utiliza para proteger la seguridad de la cuenta.',
    modify: 'Modificar',
    set: 'Colocar',
    find_paypwd: 'Recuperar Contraseña',
    find_pay_pwd: 'Recuperar contraseña de pago',
    email: 'Correo electrónico',
    enter_email: 'Por favor introduzca su correo electrónico',
    email_code: 'Código de verificación',
    enter_email_code: 'Por favor, introduzca el código de verificación de correo electrónico',
    send: 'Enviar',
    pay_password: 'Contraseña de pago',
    pay_password_desc: 'Se utiliza para proteger la seguridad de los activos cuando se negocia',
    not_certified: 'No certificado',
    certification: 'Certificación en proceso',
    verified: 'Verificado',
    rejected: 'rechazado',
    update_pwd: 'Modificar contraseña de inicio de sesión',
    update_pay_pwd: 'Modificar contraseña de pago',
    set_pay_pwd: 'Establecer contraseña de pago',
    old_pwd: 'Contraseña original',
    enter_old_pwd: 'Por favor ingrese la contraseña original',
    new_pwd: 'Nueva contraseña',
    enter_new_pwd: 'Por favor ingrese una nueva contraseña',
    res_pwd: 'confirmar Contraseña',
    enter_res_pwd: 'Por favor ingrese la contraseña de confirmación',
    submit: 'Entregar'
  },
  certified: {
    title: 'Autenticación de cuenta',
    front: 'frente de la tarjeta de identificación',
    back: 'El reverso de la tarjeta de identificación',
    hand: 'Tarjeta de identificación de mano',
    name: 'Nombre',
    name_desc: 'Por favor ingresa un nombre',
    card: 'número de identificación',
    card_desc: 'Por favor, introduzca el número de identificación',
    submit: 'Entregar'
  },
  trade: {
    title: 'Clave de transacción',
    enter_password: 'Por favor ingrese la contraseña de la transacción',
    cancel: 'Cancelar',
    submit: 'entregar'
  },
  revoke: {
    title: 'Mensaje rápido',
    message: '¿Está seguro de que desea retirar el pedido?',
    cancel: 'Cancelar',
    submit: 'confirmar'
  },
  coins_assets: {
    title: 'Activos en divisas',
    total_equity: 'Equidad total',
    equity: 'Patrimonio de la cuenta',
    number: 'Número de explotaciones',
    market_value: 'Valoración actual'
  },
  lever_assets: {
    title: 'Activos apalancados',
    total_equity: 'Equidad total',
    equity: 'Patrimonio de la cuenta',
    profit: 'Ganancias y pérdidas no realizadas',
    risk: 'Riesgo'
  },
  contract_assets: {
    title: 'Contrato de activos',
    total_equity: 'Equidad total',
    equity: 'Patrimonio de la cuenta',
    profit: 'Ganancias y pérdidas no realizadas'
  },
  contract_menu: {
    contract: 'Detalles del contrato',
    lever: 'Detalles de apalancamiento',
    coins: 'Índice de monedas',
    history: 'Historial de tasas de fondos',
    coins_rules: [
      {
        value: '1D',
        name: 'Al día'
      },
      {
        value: '1W',
        name: 'Semana actual'
      },
      {
        value: '1M',
        name: 'Mes actual'
      },
      {
        value: '1J',
        name: 'Actual temporada'
      }
    ],
    lever_info: 'Aproveche la información',
    contract_info: 'Información del contrato'
  },
  contract_info: {
    bzj: 'Depósito de seguridad',
    pt1: 'Moneda de liquidación',
    pt2: 'Valor nominal del contrato',
    pt3: 'Precio mínimo de cambio',
    pt4: 'Duración del contrato',
    pt5: 'Tiempo de tasa de fondo',
    pt6: 'Precio marcado',
    pt7: 'Volumen de posición',
    pt8: 'Volumen de operaciones de 24 horas',
    pt9: 'Privilegio',
    pt10: 'Vencimiento Entrega Renta',
    pt11: 'Diferencial de posición abierta',
    pt12: 'Mecanismo de Entrega y Liquidación',
    pt13: 'Cargo por servicio de transacción',
    pt14: 'Posición de bloqueo',
    pv5: 'Todos los días 08:00 16:00 24:00',
    pv10_1: 'Posición larga: (precio medio de cierre-precio medio de apertura) x cantidad',
    pv10_2: 'Posición corta: (precio medio de apertura-precio medio de cierre) x cantidad',
    pv11: 'No',
    pv12: 'Entrega automática al vencimiento',
    pv13: 'No',
    pv14: 'no apoyo'
  },
  metal_info: {
    pt1: 'Variedades comerciales',
    pt2: 'Moneda de liquidación',
    pt3: 'Unidad de cotización',
    pt4: 'Tamaño del contrato',
    pt5: 'Unidad mínima de cambio',
    pt51: 'Unidad mínima de negociación',
    pt52: 'Unidad máxima de negociación',
    pt6: 'tiempo de contrato',
    pt7: 'tiempo de negociación',
    pt8: 'Comisión',
    pt9: 'intercambio cotizado',
    pt10: 'margen objetivo',
    pv4: 'sostenible',
    pv5: 'Negociación las 24 horas de lunes a viernes',
    pv51: 'comercio de 24 horas',
    pv6: 'Valor del contrato0.08%',
    pv7: 'Bolsa de Valores de Londres (LME)',
    pv71: 'Bolsa de Valores de Nueva York (NYMEX)',
    pv10: 'El margen objetivo de {name} es {num} pips'
  },
  lever_info: {
    title: 'Detalles de apalancamiento',
    bei: 'veces',
    pt1: 'Moneda de liquidación',
    pt2: 'Valor nominal del contrato',
    pt3: 'Precio mínimo de cambio',
    pt4: 'Duración del contrato',
    pt5: 'Tiempo de tasa de fondo',
    pt6: 'Precio marcado',
    pt7: 'Volumen de posición',
    pt8: 'Volumen de operaciones de 24 horas',
    pt9: 'Reserva de riesgo',
    pt10: 'Beneficio de liquidación',
    pt11: 'Diferencial de posición abierta',
    pt12: 'Mecanismo de liquidación automática',
    pt13: 'Cargo por servicio de transacción',
    pt14: 'Posición de bloqueo',
    pv4: 'Sostenible',
    pv5: 'Todos los días 08:00 16:00 24:00',
    pv10_1: 'Posición larga: (precio medio de cierre-precio medio de apertura) x cantidad',
    pv10_2: 'Posición corta: (precio medio de apertura-precio medio de cierre) x cantidad',
    pv12: 'La ratio de riesgo de reserva alcanza el 80%',
    pv13: 'Valor del contrato',
    pv14: 'No soportado'
  },
  //币币信息
  coins_desc: require('./currency/en/coins.js').default,

  fun_history: {
    t1: 'Fecha',
    t2: 'Tasa de financiación estimada',
    t3: 'Tasa de financiación real'
  },
  docs: {
    list: [
      {
        name: 'Reglas de negociación OTC',
        key: 'cuerpos de cadetes militares'
      },
      {
        name: 'KYC y acuerdos contra el lavado de dinero',
        key: 'kyc'
      },
      {
        name: 'Política de solicitud de aplicación de la ley',
        key: 'ley'
      },
      {
        name: 'protección de la privacidad',
        key: 'privacidad'
      },
      {
        name: 'Acuerdo del Usuario',
        key: 'protocolo'
      },
      {
        name: 'Notificación de riesgo',
        key: 'riesgo'
      }
    ],
    otc: require('./currency/en/otc').default,
    kyc: require('./currency/en/kyc.js').default,
    law: require('./currency/en/law.js').default,
    privacy: require('./currency/en/privacy.js').default,
    protocol: require('./currency/en/protocol').default,
    risk: require('./currency/en/risk').default
  },
  asset: {
    title: 'Introducción de activos digitales',
    list: 'Lista de activos digitales'
  },
  num_asset: require('./currency/en/asset').default,

  new_currency: {
    _title: 'Nueva suscripción de moneda',
    _all: 'Todo',
    _progress: 'En curso',
    _end: 'Encima',
    _success: 'Intercambio exitoso',
    _progress_str: 'Progreso',
    _total_str: 'Liberado total',
    _date_time: 'Fecha de inicio y finalización',
    _submit: 'canjear ahora',
    _base_title: 'Información básica',
    _status: 'Estado',
    _currency: 'Moneda aceptada',
    _symbol: 'Símbolo de moneda',
    _amount: 'Prohibición total levantada',
    _price: 'Precio inicial',
    _limit: 'límite de compra personal',
    _limit_amount: 'límite de compra personal',
    _limit_one: 'Límite inferior de límite único personal',
    _no_limit: 'Sin límite',
    _position: 'Posición',
    _begin_time: 'Hora de inicio',
    _end_time: 'Hora de finalización',
    _redeemed: 'Cantidad canjeada',
    _surplus: 'superávit',
    _wallet: 'Billetera',
    _have: 'actualmente ocupado',
    _input: 'cantidad de entrada',
    _choose: 'Por favor elige',
    _new_wallet: 'Nueva cuenta de moneda',
    _new_price: 'Último precio',
    _lock: 'Período de bloqueo',
    _login: 'Por favor Iniciar sesión',
    _no_data: 'Sin datos',

    _avg_price: 'precio medio'
  }
}
