module.exports = {
  base: {
    title: 'STARTHESTAR',
    desc1: 'Kontraktets kung',
    desc2: 'Global ledare inom kontraktshandel',
    d1: 'Förtroende',
    d2: 'Ärlighet',
    d3: 'Styrka',
    more: 'Mer',
    top: 'tillbaka till toppen',
    download: 'Ladda ner APP',
    zszh: 'Verkligt konto',
    mnzh: 'Demokonto',
    content: 'Handel med en global marknadsledare inom kontraktshandel',
    lxkf: 'Kontakta kundtjänst',
    chxx: 'Ta tillbaka',
    zx: 'online'
  },
  demo: {
    title: 'framgångsrik registrering!',
    title2: 'Analogt konto',
    submit: 'Bekräfta'
  },
  lang: {
    title: 'svenska'
  },
  message: {
    invalid_email: 'Ogiltig e-postadress',
    invalid_password: 'Felaktigt lösenord',
    invalid_new_password: 'Ogiltigt nytt lösenord',
    invalid_res_password: 'Ogiltigt bekräfta lösenord',
    invalid_email_code: 'Ogiltig e-postverifieringskod',
    password_inconsistent: 'Inkonsekventa lösenord',
    invalid_invitation_code: 'Ogiltig inbjudningskod',
    should_agree: 'Vänligen läs och godkänn registreringsvillkoren',
    not_null: 'Detta fält får inte vara tomt',
    send_email: 'Försök igen efter {:s}s',
    invalid_number: 'ogiltigt värde',
    invalid_balance: 'Ogiltigt saldo',
    invalid_rate: 'Välj flera',
    invalid_rule: 'Välj en regel',
    invalid_system_rule: 'Inga regler i systemet',
    not_enough: 'Inte tillräckligt tillgängligt',
    price_high: 'Priset måste vara högre än {price}',
    price_low: 'Priset måste vara lägre än {price}',
    number_high: 'Det maximala antalet uttag är {num}',
    number_low: 'Minsta uttagsbelopp är {num}',
    copy_address: 'Kopiera adressen framgångsrikt {text}',
    invalid_upload_front: 'Ladda upp fotot på framsidan av legitimationen',
    invalid_upload_back: 'Ladda upp ett foto på baksidan av ditt ID',
    invalid_upload_hand: 'Ladda upp ett handhållet ID-foto',
    invalid_upload_name: 'Vänligen ange namnet på certifikatet',
    invalid_upload_card: 'Vänligen ange ID-numret',
    upload_pic_type: 'Ladda upp bilder kan endast vara i JPG-, PNG-format',
    upload_pic_size: 'Storleken på uppladdningsbilden får inte överstiga 10 MB'
  },
  index: {
    title2: 'Global marknad fri handel 24 timmar non-stop',
    desc2: 'Implementera din handelsstrategi på en extremt likvid marknad, med en 24-timmars handelsvolym på',
    desc2_1: 'på hela plattformen',
    list: {
      name: 'marknadsföra',
      h24_price: '24H senaste pris',
      h24_change: '24h byte',
      h24_high: '24H högsta pris',
      h24_low: '24H lägsta pris',
      h24_vol: '24H volym'
    },
    footer: {
      desc:
        'starthestar Blockchain Technology Ltd, med huvudkontor i USA, är ett världsledande blockchain-teknikföretag. Företaget har erhållit den amerikanska MSB internationella finanslicensen. Det är ett kompatibelt blockchain-tekniktjänsteföretag som kan verka i överensstämmelse på global skala Blockchain-företagstjänster. Samtidigt kommer starthestar Blockchain Technology Ltd-talanger från tekniska team från topp internationella vetenskapliga forskningsinstitutioner och blockchain-teknikutvecklingsteam för att fullt ut skydda användarmedel och kontosäkerhet. Förutom det tekniska teamet har företaget också ett professionellt blockchain-team: digitala tillgångar AI kvantitativa investeringsteam, blockchain-projektvärdevärderingsteam, digitala tillgångsinvesteringsteam, etc., har en global layout för att tjäna globala blockchain-användare. Företagets mål och vision är att tillhandahålla säkra, stabila, hänsynsfulla och pålitliga transaktionstjänster för globala digitala tillgångsentusiaster, och att bygga ett professionellt, digitalt och internationellt världskänt blockchain-teknikföretag.',
      item1: {
        tt: 'Produktdisplay',
        pt1: 'OTC-handelsregler',
        pt2: 'Nybörjarguide',
        pt3: 'Pris Standard',
        pt4: 'Kontraktsbeskrivning'
      },
      item2: {
        tt: 'Stöd',
        pt1: 'KYC och avtal mot penningtvätt',
        pt2: 'Policy för begäran om verkställighet',
        pt3: 'Introduktion till digitala tillgångar'
      },
      item3: {
        tt: 'Villkor',
        pt1: 'Privat skydd',
        pt2: 'Användaravtal',
        pt3: 'Riskmeddelande'
      },
      item4: {
        tt: 'kontakta oss',
        pt1: 'Företagssamarbete',
        pt2: 'Institutionellt samarbete',
        pt3: 'OTC-applikation',
        pt4: 'E-post till kundtjänst',
        pt5: 'Klagomål och förslag',
        pt6: 'mediesamarbete'
      }
    },
    bk3: {
      title: 'Stolt över innovativ FoU-styrka',
      desc: 'Ofattbar kontraktshandelsupplevelse, designad för finansiella handlare',
      pt1: 'Öppen order stoppa vinst och stoppa förlust',
      pd1: 'Väntande beställningar stannar inte uppe sent, oroa dig för att inte titta på beställningen',
      pt2: 'Omvänd öppen ordning',
      pd2: 'Marknaden förändras plötsligt, häng med på en sekund',
      pt3: 'Stöd fullt lager för lager',
      pd3: 'Gör vad du vill, gör vad du vill',
      pt4: 'Full position stegvis likvidation',
      pd4: 'Utländsk likvidation är först lika med hälften och försöker hjälpa investerare att minska förlusterna'
    },
    bk5: {
      title: 'Åtta fördelar med kontraktshandelsplattform',
      desc: 'Vi lyssnar hela tiden, optimerar och förbättrar kontinuerligt och skapar en förstklassig handelsupplevelse för globala handelsanvändare',
      pt1: 'Marknadsdjup',
      pd1: 'Marknadsdjupet för de tre första stånden på plattformen har nått storleksordningen miljontals dollar, vilket vida överstiger branschens genomsnittliga marknadsdjup på 10 000 och 100 000. Spridningen är liten och transaktionen är snabb.',
      pt2: 'Smidig transaktion',
      pd2: 'Genom att använda en effektiv matchande transaktionsmotor för att säkerställa en smidig transaktionsupplevelse, kan den behandla miljontals order per sekund och kan stödja smidig och stabil drift med mer än 20 miljoner samtidiga onlineanvändare.',
      pt3: 'Rättvist pris',
      pd3: "'Markerat pris' väljer transaktionspriset i realtid för världens tre största börser som indexpris för regressionsberäkning, vilket effektivt förhindrar användarförluster orsakade av onormal manipulation av det senaste transaktionspriset under en kort tidsperiod.",
      pt4: 'Egenutvecklad teknik',
      pd4: 'Matchmaking-motorn med rötter i traditionell finans, utvecklad av ett team av seniora experter inom finansbranschen, och noggrant utformad varje detalj för att säkerställa en säker, stabil och effektiv drift av handelsplattformen.',
      pt5: 'Topp riskkontroll',
      pd5: 'Bedömning på andra nivån, flerskiktskryptering, hög systemsäkerhet. Kombinationen av varma och kalla plånböcker, krypteringsåtgärder av militär kvalitet, säkerhet för tillgångstransaktioner garanteras och garanterar till 100 % säkerheten för användarnas digitala tillgångar.',
      pt6: 'Fonder säkerhet',
      pd6: 'Trelagers säkerhetssystem, omfattande säkerhetsrevision. Självbyggt branschens ledande hierarkiska deterministiska kalla plånbokssystem, alla användare tilldelar laddningsadresser är kalla plånboksadresser, insamling och uttag av pengar genomförs genom offlinesignaturer.',
      pt7: 'Hardcore Power',
      pd7: 'Tiotusentals BTC-reserver, gener för finansiella innovationer och världens högsta ledningsgrupp. starthestar lovar att om konto- och transaktionsdatafel inte orsakas på grund av systemskäl kommer plattformen att betala fullt ut vid fel!',
      pt8: 'Kvalitetsservice',
      pd8: 'Professionell kundservice dygnet runt 7*24 timmar, snabbt svar. Utgå från tanken på service, tillhandahåll förstklassig handelsupplevelse för globala kunder. Den ultimata upplevelsen kommer från konceptet att betjäna kunder.'
    },
    bk6: {
      title: 'Säkerhet och efterlevnad global verksamhet',
      desc: 'Omfamna övervakning och alltid följa globala efterlevnadsoperationer'
    },
    bk7: {
      title: 'Officiell strategisk partner'
    },
    bk8: {
      title: 'Betjänade mer än 100 000 globala handelsanvändare',
      desc: 'För närvarande finns användare över hela USA, Japan, Sydkorea, Seychellerna, Vietnam och andra länder'
    },
    bk9: {
      title: 'Smidig transaktion när som helst, var som helst',
      pt1: 'Realtidsmatchningstransaktioner, insättningar och uttag kommer på några sekunder',
      pt2: 'Flera handelslägen, upp till 125 gånger hävstångseffekt',
      dt1: 'Ladda ner iOS-version',
      dt2: 'Ladda ner Android-versionen'
    },
    about: {
      title: 'Om oss',
      pt1: 'Plattformskoncept',
      pd1:
        'Låt globala tillgångar cirkulera fritt och skapa en ny kontraktsupplevelse. X-xenogenetic, representerar innovativa gener och ger en stadig ström av kraft för industriinnovation. M-motor, representerar målet, och fortsätter att gå framåt med målet att leda kontraktshandelsupplevelsen. EX-exchange, på uppdrag av handelsplattformen, har åtagit sig att skapa ett rättvist, transparent och effektivt handelsekosystem med verksamhetsfilosofin av service, lyssnande och tänkande.',
      pt2: 'Kärnvärderingar',
      pd2:
        'Vi lyssnar alltid, uppmärksammar och tänker framåt och är engagerade i att skapa ett rättvist, öppet och effektivt handelsekosystem. Ge globala handlare en bättre handelsupplevelse, möta de faktiska behoven hos handlare och främja utvecklingen av marknaden för handel med digitala tillgångar.',
      pt3: 'Team Bakgrund',
      pd3: 'Grundarteamet består av toppblockkedjeinvesterare, traditionella finanschefer, toppexperter på digitala tillgångar och traditionella metallbearbetningsteknikteam. Kärnmedlemmarna i FoU-teamet är från Morgan Stanley, CICC, Hang Seng, Tencent, Noah Wealth.'
    }
  },
  start: {
    p_title: 'Transaktion, börja här',
    p_desc: 'Ange din e-postadress i följande område för att registrera dig omedelbart',
    p_email: 'e-post',
    p_btn: 'Skapa'
  },
  download: {
    p_title: 'Ladda ner APP-klient',
    p_desc: 'När som helst, var som helst, terminaltransaktion med flera plattformar',
    p_btn: 'Ladda ner',
    p_scan_android: 'Android app',
    p_scan_ios: 'IOS app'
  },
  downpage: {
    t1: {
      p_title: 'Blixttransaktionsprestanda',
      p_desc: 'Tillhandahåll en mängd olika transaktionstyper att välja mellan, ordermatchning är extremt snabb, ett klick flash-utbyte och handel är bekvämare'
    },
    t2: {
      p_title: 'Enkel överföring av tillgångar',
      p_desc: 'Enkel överföring av tillgångar på kontot, realtidsöversikt över digitala tillgångar, öka cirkeldiagrammet för valutaförhållande'
    },
    t3: {
      p_title: 'Anpassad handelspanel',
      p_desc: 'Definiera fritt handelslayouten enligt olika handelsvanor och öka ingången av K-line och djupkarta'
    },
    t4: {
      p_title: 'Account Integration Management',
      p_desc: 'Flera säkerhetsverifieringsinställningar, ett konto hanterar C2C-konton och valutakonton'
    }
  },
  login: {
    login: 'logga in',
    title: 'Användarnamn',
    desc1: 'Logga in tack',
    desc2: 'Officiell hemsida',
    email: 'E-post',
    email2: 'E-post',
    email3: 'Telefonnummer',
    email_desc: 'Vänligen ange din e-post',
    phone_desc: 'Vänligen ange telefonnummer',
    password: 'Lösenord',
    password_desc: 'Vänligen ange lösenordet',
    forgot_password: 'glömt ditt lösenord',
    no_account: 'Inget konto ännu'
  },
  signup: {
    signup: 'Bli Medlem',
    title: 'Användarregistrering',
    desc1: 'Välkommen att logga in',
    desc2: 'Officiell hemsida',
    invitation_code: 'Inbjudningskod',
    invitation_desc: 'Vänligen ange inbjudningskoden',
    accept: 'Acceptera',
    service: 'Användaravtal',
    and: 'och',
    policy: 'Integritetsskyddspolicy',
    have_account: 'Har du redan ett konto'
  },
  retrieve: {
    ptitle: 'Hämta lösenord',
    pemail_code: 'E-postverifieringskod',
    pset: 'Välj lösenord',
    set_title: 'Välj lösenord',
    set_desc: 'Du återställer ditt lösenord, vänligen gör följande inställningar',
    new_password: 'nytt lösenord',
    password_desc: 'Vänligen ange ett nytt lösenord',
    res_passowrd: 'Bekräfta lösenord',
    res_passowrd_desc: 'Vänligen ange lösenordet för bekräftelse'
  },
  valid_email: {
    title: 'Verifiering av registrering',
    desc: 'Du registrerar ett konto, vänligen slutför följande verifiering',
    email_code: 'E-postverifieringskod',
    email_desc: 'Vänligen ange verifieringskoden',
    get_code: 'Få verifieringskod'
  },
  ihead: {
    begin_trade: 'Börja handla',
    helper: 'Nybörjarhjälp',
    text: 'Dokumentcenter',
    new_currency: 'Ny valutaprenumeration'
  },
  uhead: {
    title1: 'Handelsvaror',
    title2: 'Internationell Forex',
    title3: 'Internationellt index',
    currency: 'Valuta',
    lever: 'Hävstångstransaktion',
    coins: 'Valutatransaktion',
    contract: 'Kontraktstransaktion',
    metal: 'Metall',
    energy: 'Energi',
    contract_info: 'Avtalsinformation',
    helper: 'Nybörjarhjälp',
    text: 'Dokumentcenter',
    assets: 'Tillgångar',
    u_wallet: 'Min plånbok',
    u_contract: 'Avtalskonto',
    u_lever: 'Hävstångskonto',
    u_account: 'Kontoinformation',
    u_logout: 'Logga ut'
  },
  news: {
    label: 'Nyheter',
    update: 'Uppdaterad på'
  },
  guide: {
    label: 'Nybörjarguide',
    list: [
      {
        ptitle: 'Hur man registrerar sig för starthestar',
        pkey: 'k1'
      },
      {
        ptitle: 'Identitetsautentisering',
        pkey: 'k2'
      },
      {
        ptitle: 'Inställningar för inloggningslösenord och transaktionslösenord',
        pkey: 'k3'
      },
      {
        ptitle: 'Sätt in mynt',
        pkey: 'k4'
      },
      {
        ptitle: 'Mynttransaktionsprocess',
        pkey: 'k5'
      },
      {
        ptitle: 'Uttag',
        pkey: 'k6'
      },
      {
        ptitle: 'Ladda ner  mobil APP',
        pkey: 'k7'
      }
    ],
    content: {
      k1: require('./currency/en/guide/k1.js').default,
      k2: require('./currency/en/guide/k2.js').default,
      k3: require('./currency/en/guide/k3.js').default,
      k4: require('./currency/en/guide/k4.js').default,
      k5: require('./currency/en/guide/k5.js').default,
      k6: require('./currency/en/guide/k6.js').default,
      k7: require('./currency/en/guide/k7.js').default
    }
  },
  kline: {
    h24_high: '24h hög',
    h24_low: '24h låg',
    h24_vol: '24H volym',
    commission: 'Nuvarande kommission',
    record: 'Transaktionspost',
    commission_list: 'Kommissionsförteckning',
    deal_list: 'Senaste affären',
    price: 'Pris',
    xprice: 'Vinstspridning',
    bzj: 'Marginal',
    number: 'siffra',
    total: 'Total',
    time: 'Tid',
    currency: 'Positioner',
    type: 'Typ',
    direction: 'Riktning',
    multiple: 'Inflytande',
    fee: 'Provision',
    amount: 'Total',
    status: 'Status',
    operate: 'Drift',
    buy: 'köpa',
    sell: 'Sälja',
    pending: 'Väntande beställning',
    trade_success: 'Trading framgångsrik',
    retract: 'Annullera',
    profit: 'Göra vinst',
    loss: 'Stoppa förlusten',
    all: 'Allt',
    all_currency: 'Alla valutor',
    new_price: 'Senaste pris',
    change: 'Stiga och falla',
    protocol: 'protokoll',
    _status1: 'Recension',
    _status2: 'Framgångsrik',
    _status3: 'avvisade'
  },
  coins: {
    title: 'Valutatransaktion',
    market: 'marknadspris',
    limit: 'Begränsat pris',
    profit: 'Göra vinst',
    loss: 'Stoppa förlusten',
    price: 'Pris',
    current: 'Nuvarande',
    price_desc: 'Vänligen ange priset',
    number: 'siffra',
    number_desc: 'Vänligen ange numret',
    available: 'Tillgängliga',
    half: 'halva lager',
    all: 'Fullt lager',
    fee: 'Provision',
    amount: 'Transaktionsbelopp',
    buy: 'köpa',
    sell: 'Sälja',
    current_price: 'Nuvarande',
    open_price: 'Öppet idag',
    high: 'Hög',
    low: 'Låg',
    yesterday: 'Stäng igår',
    today_zf: 'Prisförändring',
    now_cc: 'Placera',
    y_cc: 'Y-position',
    jsj: 'Avräkningspris',
    zrjsj: 'Y Avräkningspris'
  },
  lever: {
    title: 'Hävstångstransaktion',
    multiple: 'Inflytande',
    multiple_desc: 'Välj en multipel',
    double: 'dubbel',
    buy: 'Köpa upp)',
    sell: 'Sälja (höst)'
  },
  contract: {
    title: 'Kontraktstransaktion',
    time: 'Tid',
    time_desc: 'Välj en tid',
    minute: 'minut',
    second: 'Andra',
    buy: 'Köpa upp)',
    sell: 'Sälja (höst)',
    rate: 'Royaltyförhållande'
  },
  wallet: {
    title: 'Mina tillgångar',
    valuation: 'Total tillgångsvärdering',
    my_wallet: 'Min plånbok',
    my_trade: 'Handelskonto',
    my_transfer: 'Uttagsrekord',
    my_deposit: 'Myntdeponeringspost',
    cbdz: 'insättningsadress',
    cbsl: 'Quantity',
    deposit: 'Sätt in mynt',
    withdrawal: 'Uttag',
    search: 'Sök',
    currency: 'Positioner',
    protocol: 'Protokoll',
    liquidation: 'Stäng position',
    liquidation_title: 'Snabb likvidation',
    trade_pwd: 'Handelslösenord',
    enter_trade_pwd: 'Ange handelslösenord',
    submit_liquidation: 'Stäng positionen omedelbart',
    amount: 'Total',
    available: 'Tillgängliga',
    freeze: 'Frysa',
    market: 'värdering',
    ljsy: 'Kumulerad inkomst',
    zrsy: 'Gårdagens intäkter',
    operate: 'Drift',
    countdown: 'Nedräkning',
    lever_title: 'Hävstångskonto',
    contract_title: 'Avtalskonto',
    direction: 'Riktning',
    bond: 'Marginal',
    buy_rate: 'flera olika',
    profit: 'Vinst och förlust',
    profit1: 'Vinst och förlust',
    buy: 'köpa',
    sell: 'Sälja',
    many: 'köpa',
    empty: 'Sälja',
    contract_time: 'Kontraktstid',
    premium: 'Privilegium',
    minute: 'minut',
    address: 'Adress',
    address_desc: 'Vänligen ange adressen',
    number: 'siffra',
    number_desc: 'Vänligen ange antalet uttag',
    copy: 'Kopiera',
    confirm: 'OK',
    all: 'Allt',
    fee: 'Provision',
    actual: 'Faktisk ankomst',
    premark: 'Uppmärksamhet',
    text_1: 'Den här adressen accepterar bara tillgångar från {name}, ladda inte om andra valutor, annars kommer tillgångarna inte att hämtas',
    text_2: '{name} gruvarbetares avgift är: aktuellt marknadsvärde {rate}%/transaktion, minimistandard: {min}{name}/transaktion',
    text_3: 'Vänligen bekräfta att mottagarens adressinformation är korrekt innan du överför pengarna. När tillgångarna väl har överförts kan de inte tas ut.',
    text_4: '{name} Gruvavgift är: {val} {name}/ penna'
  },
  record: {
    title: 'Fondrekord',
    type: 'Typ',
    all_currency: 'Alla valutor',
    all_type: 'Alla typer',
    select: 'Fråga',
    clear: 'Rensa filtret',
    receive: 'Deposition',
    transfer: 'Uttag',
    buy: 'köpa',
    sell: 'Sälja',
    normal: 'Vanlig',
    pending: 'Stop-Limit',
    wallet: 'Positionsdetaljer',
    zzzqy: 'Balans',
    zqy: 'Fri marginal',
    fdyk: 'Flytande P/L',
    fxl: 'Risknivå'
  },
  user: {
    account: 'Kontoautentisering',
    account_desc: 'Kreditinsättning, transaktion och andra funktioner måste först passera kontoverifieringen',
    password: 'Inloggningslösenord',
    password_desc: 'Används för att skydda kontosäkerheten',
    modify: 'Ändra',
    set: 'Uppsättning',
    find_paypwd: 'Hämta lösenord',
    find_pay_pwd: 'Hämta betalningslösenord',
    email: 'E-post',
    enter_email: 'Vänligen ange din e-postadress',
    email_code: 'Verifierings kod',
    enter_email_code: 'Vänligen ange e-postverifieringskoden',
    send: 'Skicka',
    pay_password: 'Lösenord för betalning',
    pay_password_desc: 'Används för att skydda tillgångars säkerhet vid handel',
    not_certified: 'Ej certifierad',
    certification: 'Certifiering pågår',
    verified: 'Verifierad',
    rejected: 'avvisade',
    update_pwd: 'Ändra inloggningslösenord',
    update_pay_pwd: 'Ändra betalningslösenord',
    set_pay_pwd: 'Ange betalningslösenord',
    old_pwd: 'Ursprungligt lösenord',
    enter_old_pwd: 'Vänligen ange det ursprungliga lösenordet',
    new_pwd: 'nytt lösenord',
    enter_new_pwd: 'Vänligen ange ett nytt lösenord',
    res_pwd: 'Bekräfta lösenord',
    enter_res_pwd: 'Vänligen ange lösenordet för bekräftelse',
    submit: 'Skicka in'
  },
  certified: {
    title: 'Kontoautentisering',
    front: 'ID-kort fram',
    back: 'Baksidan av ID-kortet',
    hand: 'Handhållet ID-kort',
    name: 'namn',
    name_desc: 'Vänligen ange ett namn',
    card: 'id nummer',
    card_desc: 'Ange ID-nummer',
    submit: 'Skicka in'
  },
  trade: {
    title: 'transaktionslösenord',
    enter_password: 'Vänligen ange transaktionslösenord',
    cancel: 'Avbryt',
    submit: 'Skicka in'
  },
  revoke: {
    title: 'Snabbmeddelande',
    message: 'Är du säker på att du vill dra tillbaka beställningen?',
    cancel: 'Avbryt',
    submit: 'bekräfta'
  },
  coins_assets: {
    title: 'Valutatillgångar',
    total_equity: 'Totalt kapital',
    equity: 'Kontot eget kapital',
    number: 'Antal innehav',
    market_value: 'Nuvarande värdering'
  },
  lever_assets: {
    title: 'Belånade tillgångar',
    total_equity: 'Totalt kapital',
    equity: 'Kontot eget kapital',
    profit: 'Orealiserad vinst och förlust',
    risk: 'Risk'
  },
  contract_assets: {
    title: 'Kontraktstillgångar',
    total_equity: 'Totalt kapital',
    equity: 'Kontot eget kapital',
    profit: 'Orealiserad vinst och förlust'
  },
  contract_menu: {
    contract: 'Kontraktsdetaljer',
    lever: 'Hävstångsdetaljer',
    coins: 'Myntindex',
    history: 'Fondräntehistorik',
    coins_rules: [
      {
        value: '1D',
        name: 'På dagen'
      },
      {
        value: '1W',
        name: 'Denna vecka'
      },
      {
        value: '1M',
        name: 'Denna månad'
      },
      {
        value: '1J',
        name: 'Aktuell säsong'
      }
    ],
    lever_info: 'Utnyttja information',
    contract_info: 'Avtalsinformation'
  },
  contract_info: {
    bzj: 'Säkerhets inbetalning',
    pt1: 'Avräkningsvaluta',
    pt2: 'Kontrakt nominellt värde',
    pt3: 'Minsta ändringspris',
    pt4: 'Avtalsvillkor',
    pt5: 'Fondräntastid',
    pt6: 'Märkt pris',
    pt7: 'Positionsvolym',
    pt8: '24-timmars handelsvolym',
    pt9: 'Privilegium',
    pt10: 'Förfalloleveransinkomst',
    pt11: 'Öppen positionsspridning',
    pt12: 'Leverans- och avvecklingsmekanism',
    pt13: 'Transaktionsserviceavgift',
    pt14: 'Låsläge',
    pv5: 'Varje dag 08:00 16:00 24:00',
    pv10_1: 'Lång position: (genomsnittligt slutpris-genomsnittligt öppningspris) x kvantitet',
    pv10_2: 'Kort position: (genomsnittligt öppningspris-genomsnittligt slutkurs) x kvantitet',
    pv11: 'Nej',
    pv12: 'Automatisk leverans vid förfall',
    pv13: 'Nej',
    pv14: 'stödjer inte'
  },
  metal_info: {
    pt1: 'Handel med sorter',
    pt2: 'Avvecklingsvaluta',
    pt3: 'Offertenhet',
    pt4: 'Kontraktsstorlek',
    pt5: 'Minsta förändringsenhet',
    pt51: 'Minsta handelsenhet',
    pt52: 'Maximal handelsenhet',
    pt6: 'Kontraktstid',
    pt7: 'Handelstid',
    pt8: 'Provision',
    pt9: 'Noterad börs',
    pt10: 'målspridning',
    pv4: 'hållbar',
    pv5: '24-timmars handel från måndag till fredag',
    pv51: '24 timmars handel',
    pv6: 'Kontraktsvärde 0,08 %',
    pv7: 'London Stock Exchange (LME)',
    pv71: 'New York Stock Exchange (NYMEX)',
    pv10: 'Målspridningen för {name} är {num} punkter'
  },
  lever_info: {
    title: 'Hävstångsdetaljer',
    bei: 'gånger',
    pt1: 'Avräkningsvaluta',
    pt2: 'Kontrakt nominellt värde',
    pt3: 'Minsta ändringspris',
    pt4: 'Avtalsvillkor',
    pt5: 'Fondräntastid',
    pt6: 'Märkt pris',
    pt7: 'Positionsvolym',
    pt8: '24-timmars handelsvolym',
    pt9: 'Riskreserv',
    pt10: 'Likvidationsvinst',
    pt11: 'Öppen positionsspridning',
    pt12: 'Automatisk likvidationsmekanism',
    pt13: 'Transaktionsserviceavgift',
    pt14: 'Låsläge',
    pv4: 'Hållbar',
    pv5: 'Varje dag 08:00 16:00 24:00',
    pv10_1: 'Lång position: (genomsnittligt slutpris-genomsnittligt öppningspris) x kvantitet',
    pv10_2: 'Kort position: (genomsnittligt öppningspris-genomsnittligt slutkurs) x kvantitet',
    pv12: 'Reservriskkvoten når 80 %',
    pv13: 'Kontrakts värde',
    pv14: 'Stöds inte'
  },
  //币币信息
  coins_desc: require('./currency/en/coins.js').default,

  fun_history: {
    t1: 'Datum',
    t2: 'Beräknad finansieringsgrad',
    t3: 'Faktisk finansieringsgrad'
  },
  docs: {
    list: [
      {
        name: 'OTC-handelsregler',
        key: 'otc'
      },
      {
        name: 'KYC och avtal mot penningtvätt',
        key: 'kyc'
      },
      {
        name: 'Policy för brottsbekämpande begäranden',
        key: 'lag'
      },
      {
        name: 'Privat skydd',
        key: 'Integritet'
      },
      {
        name: 'Användaravtal',
        key: 'protokoll'
      },
      {
        name: 'Riskavisering',
        key: 'risk'
      }
    ],
    otc: require('./currency/en/otc').default,
    kyc: require('./currency/en/kyc.js').default,
    law: require('./currency/en/law.js').default,
    privacy: require('./currency/en/privacy.js').default,
    protocol: require('./currency/en/protocol').default,
    risk: require('./currency/en/risk').default
  },
  asset: {
    title: 'Introduktion av digitala tillgångar',
    list: 'Lista över digitala tillgångar'
  },
  num_asset: require('./currency/en/asset').default,

  new_currency: {
    _title: 'Ny valutaprenumeration',
    _all: 'Allt',
    _progress: 'Pågående',
    _end: 'Över',
    _success: 'Utbytet lyckades',
    _progress_str: 'Framsteg',
    _total_str: 'Totalt släppt',
    _date_time: 'Start- och slutdatum',
    _submit: 'Lös in nu',
    _base_title: 'Grundläggande information',
    _status: 'Status',
    _currency: 'Godkänd valuta',
    _symbol: 'Valutasymbol',
    _amount: 'Totalt upphävt förbud',
    _price: 'Ursprungligt pris',
    _limit: 'Personlig köpgräns',
    _limit_amount: 'Personlig köpgräns',
    _limit_one: 'Personlig singelgräns nedre gräns',
    _no_limit: 'Ingen gräns',
    _position: 'Placera',
    _begin_time: 'Starttid',
    _end_time: 'Sluttid',
    _redeemed: 'Inlöst belopp',
    _surplus: 'överskott',
    _wallet: 'Plånbok',
    _have: 'Hålls för närvarande',
    _input: 'inmatad kvantitet',
    _choose: 'Vänligen välj',
    _new_wallet: 'Nytt valutakonto',
    _new_price: 'Senaste pris',
    _lock: 'Låsperiod',
    _login: 'Vänligen logga in',
    _no_data: 'Inga data',

    _avg_price: 'genomsnittspris'
  }
}
