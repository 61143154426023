<template>
  <div class="msg_main">
    <!-- 左边 -->
    <div class="msg_left_box">
      <!-- 标题 -->
      <div class="msg_left_title">
        <!-- <el-tag :type="status.status">{{ status.title }}</el-tag> -->
      </div>
      <!-- 内容 -->
      <div class="msg_left_content" style="position: relative">
        <div>
          <!-- <el-input v-model="search_text" prefix-icon="el-icon-search" @input="searchText" placeholder="search"></el-input> -->
          <!-- <el-input v-model="search_text" @input="searchText" placeholder="search" :loading="false"></el-input> -->
          <!-- <template #suffix>
              <close-circle-outlined style="color: rgba(0, 0, 0, 0.45)" @click="onSearchClear" />
            </template> -->

          <div class="ss-tkbox" v-if="searchList.length > 0">
            <ul>
              <li v-for="(item, i) in searchList" :key="i" @click="onSearchChat(item)">
                <div class="ss-box1">
                  <div class="ss-head1">
                    <img :src="item.u_avatar" class="ss-avatar" />
                  </div>
                  <div class="ss-desc1">
                    <div class="ss-name">{{ item.u_name }}</div>
                    <div class="ss-desc" v-html="item.content_str"></div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <!-- 用户 -->
        <div :class="['msg_user', parseInt(currentChat.session_id) === item.session_id ? 'active' : '']"
          v-for="(item, i) in chatList" :key="i" @click="onChat(item)">
          <div>
            <div class="msg_avatar">
              <img :src="item.avatar" style="width: 100%" alt="avatar" />
            </div>
          </div>
          <div class="msg_info">
            <div class="msg_row">
              <div class="title">{{ item.nickname }}</div>
              <div class="date">{{ item.create_time_str }}</div>
            </div>
            <div class="msg_row" v-if="item.message">
              <div class="desc" v-if="item.message.type == 1">
                [Msg]
                {{ item.message.content }}
              </div>
              <div class="desc" v-if="item.message.type == 2">
                [Image]
                <img :src="item.message.content" alt="" style="width: auto; height: 100%" />
              </div>
              <div class="desc" v-if="item.message.type == 3">[Goods]{{ JSON.parse(item.message.content)['name'] }}
              </div>
            </div>
            <div :class="'settop ' + (item.sort > 0 ? 'active' : '')" @click.stop="onSetTop(i, item)">
              <!-- <TagOutlined :style="{ fontSize: '18px', marginBottom: '5px' }" tag="Top" /> -->
              <i class="el-icon-star-off"></i>
            </div>
            <div class="delete" @click.stop="onDelete(i, item)">
              <svg width="24" height="24">
                <defs>
                  <filter x="-1.5%" y="-1.9%" width="103.1%" height="103.6%" filterUnits="objectBoundingBox"
                    id="delete_svg__a">
                    <feOffset in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                    <feGaussianBlur stdDeviation="3" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" in="shadowBlurOuter1"
                      result="shadowMatrixOuter1"></feColorMatrix>
                    <feMerge>
                      <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
                      <feMergeNode in="SourceGraphic"></feMergeNode>
                    </feMerge>
                  </filter>
                </defs>
                <path
                  d="M11.142 3.334H.856v10c0 .92.768 1.666 1.715 1.666h6.857c.948 0 1.714-.747 1.714-1.667V3.334M8.142 0H3.856L3 .834H.856A.845.845 0 000 1.667V2.5h12v-.834a.846.846 0 00-.858-.833H9L8.142 0"
                  transform="translate(6 6)" filter="url(#delete_svg__a)" fill="#757575" fill-rule="evenodd"
                  data-spm-anchor-id="a2o42.home.1355784120.i1.654346b5lCj8Rf"></path>
              </svg>
            </div>
            <div class="badge" v-if="item.msg_total">{{ item.msg_total }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 右边 -->
    <div class="msg_right_box">
      <div class="m_right_title">
        <div style="width: 100%">{{ currentChat.nickname }}</div>
      </div>
      <!-- 消息框 -->
      <div class="msg_box">
        <div class="message-box">
          <div class="simple-scroller chat-list message-list custom-scroll-bar">
            <div class="scroll-content" ref="messageContent" @scroll="onMsgScroll">
              <!-- 加载中 -->
              <div class="top-holder invisible" v-if="loading">
                <i class="next-icon next-icon-loading next-icon-medium"></i>
              </div>
              <!-- 时间 -->
              <template v-for="(item, i) in msgList">
                <!-- <div v-if="item.time_gap == 0 || item.time_gap >= 120" class="message-row user-type-0 row-card-system" :key="i">
                  <div class="avatar-holder"></div>
                  <div class="message-contain">
                    <div class="lzd-message-item card-system">
                      <div class="lzd-system-text">
                        {{ item.create_time_str }}
                      </div>
                    </div>
                  </div>
                  <div class="avatar-holder"></div>
                </div> -->
                <div :class="['message-row', 'user-type-' + item.msg_type, 'row-card-text']" :key="i">
                  <div class="simple-avatar circle">
                    <img :src="item.msg_type == 1 ? currentChat.avatar : mineChat.avatar" />
                  </div>
                  <div class="message-contain">
                    <!-- 撤回 -->
                    <div v-if="item.msg_type == 2" class="message-withdraw-active">
                      <div class="message-withdraw">
                        <span>
                          <svg viewBox="0 0 1024 1024" version="1.1" width="10" height="10">
                            <path
                              d="M321.836 517.083c11.36 11.617 11.339 30.135 0.088 41.727l-0.553 0.555c-11.613 11.364-30.124 11.342-41.711 0.087l-0.555-0.553L71.523 346.61c-11.209-11.461-11.358-29.681-0.471-41.323l0.544-0.567L279.178 93.917c11.585-11.764 30.508-11.906 42.268-0.318 11.573 11.405 11.894 29.921 0.86 41.719l-0.543 0.565-157.477 159.918h517.718c148.563 0 268.996 120.48 268.996 269.1 0 147.133-118.037 266.687-264.547 269.063l-4.449 0.036H235.952c-16.507 0-29.889-13.387-29.889-29.9 0-16.277 13.002-29.517 29.183-29.892l0.706-0.008h446.052c115.549 0 209.22-93.706 209.22-209.3 0-114.436-91.807-207.422-205.76-209.27l-3.46-0.028-518.069-0.001 157.901 161.482z"
                              fill="#666666" p-id="1734"></path>
                          </svg>
                        </span>
                      </div>
                    </div>
                    <!-- 消息内容 -->
                    <div class="lzd-message-item card-text">
                      <div class="message-text-box">
                        <div class="lzd-message-text" v-if="item.type == 2">
                          <span class="emoji-face has-text">
                            <div @click="onShowImg(item.content)">
                              <div class="background-default card-img"
                                :style="'height: 200px; width: 200px;background-size: 100%; background-image: url(' + item.content + ');'">
                              </div>
                            </div>
                          </span>
                        </div>
                        <div class="lzd-message-text" v-else-if="item.type == 3">
                          <span class="emoji-face has-text">
                            <div @click="onDetail(JSON.parse(item.content).id)">
                              <div style="display: flex; align-items: center; justify-content: center">
                                <div style="display: flex">
                                  <div
                                    :style="'background-size: 100%;height: 60px; width: 60px; background-image: url(' + JSON.parse(item.content).img + ')'">
                                  </div>
                                </div>
                                <div style="display: grid; padding: 0 10px">
                                  <div class="g-name">
                                    {{ JSON.parse(item.content).name }}
                                  </div>
                                  <div class="g-price">$ {{ JSON.parse(item.content).price }}</div>
                                </div>
                              </div>
                            </div>
                          </span>
                        </div>
                        <div class="lzd-message-text" v-else>
                          <span class="emoji-face has-text">
                            <!-- <span>{{item.content}}</span> -->
                            <div v-html="replaceContent(item.content)"></div>
                          </span>
                        </div>
                        <!-- <div class="box">
                          <div class="lzd-message-text translation">11111111111111</div>
                          <div class="lzd-message-text tip">Alibaba Translation</div>
                        </div> -->
                      </div>
                    </div>
                    <!-- 未读状态 -->
                    <div class="message-read-type"
                      style="display: flex; align-items: center; justify-content: flex-end">
                      <span class="read-type unread-type">{{ item.unread == 1 ? 'Unread' : 'Read' }}</span>
                      <div class="read-chehui" v-if="item.msg_type == 2" @click="onRevoke(i, item.id)">{{
                        $t('base.chxx') }}</div>
                    </div>
                  </div>
                  <div class="avatar-holder"></div>
                </div>
              </template>
            </div>
            <!-- 发送商品 -->
            <div :class="['chat-goods', goods_show ? 'active' : '']" v-if="goods.id">
              <div style="position: relative">
                <i class="el-icon-circle-close" @click="onShowImgClose()"></i>
                <!-- <CloseCircleOutlined :style="{ fontSize: '20px', position: 'absolute', top: '5px', right: '5px' }" @click="onShowImgClose()" /> -->
                <div class="c-gbox">
                  <div class="c-gboxx">
                    <div class="c-gimg">
                      <img :src="goods && goods.goods.img" alt="" />
                    </div>
                    <div class="c-gname">
                      <div class="c-text">{{ goods && goods.goods.name }}</div>
                      <div class="c-price">$ {{ goods && goods.goods.price }}</div>
                    </div>
                  </div>
                  <div class="c-btnbox">
                    <div class="c-btn-send" @click="onSendGoods">{{ $t('withdrawal.fs') }}</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="scrollbar-track">
              <div class="scrollbar-thumb" style="height: 332px; top: 0px"></div>
            </div>
            <div class="scrollbar-track horizontal none">
              <div class="scrollbar-thumb horizontal" style="width: 20px"></div>
            </div>
          </div>
        </div>
      </div>
      <!-- 输入框 -->
      <div class="message-view-field">
        <!-- 发送图片隐藏 -->
        <label class="file-upload message-view-field__upload">
          <svg width="27" height="27" id="buyer_file_send_btn" @click="onFile">
            <g fill="none" fill-rule="evenodd">
              <circle stroke="#999" stroke-width="0.5" cx="13.5" cy="13.5" r="13.25"></circle>
              <path
                d="M14.143 14.142h5.194a.65.65 0 00.65-.648.65.65 0 00-.65-.65h-5.194V7.65a.649.649 0 10-1.298 0v5.196H7.65a.65.65 0 100 1.297h5.195v5.196a.648.648 0 101.298 0v-5.196z"
                fill="#5F646E"></path>
            </g>
          </svg>
          <!-- <input class="file-upload__control" type="file" accept=".jpg, .jpeg, .png, .gif, .webp, .mov, .mp4" style="width: 0px; overflow: hidden;"> -->
        </label>
        <div class="message-view-field__input-block">
          <!-- <textarea v-model="content" @keyup.enter.stop="onSubmit()" id="textarea" class="message-fileds__autosize" rows="1" style="max-height: 100px; overflow: hidden; overflow-wrap: break-word; height: 20px;"></textarea> -->
          <!-- 发送文字 -->
          <input id="textarea" v-model="content" @keyup.enter.stop="onSubmit()" autocomplete="off"
            class="message-fileds__autosize" rows="1"
            style="max-height: 100px; overflow: hidden; overflow-wrap: break-word; height: 30px" />
          <!-- 发送表情 -->
          <span :class="['balloon', isShowEmoji ? '' : 'balloon--hidden']">
            <span class="balloon__trigger" @click.stop="onShowEmoji()">
              <svg width="30" height="30" id="buyer_emoji_btn" class="clickable">
                <defs>
                  <filter x="-1.3%" y="-13.5%" width="103.6%" height="126.9%" filterUnits="objectBoundingBox"
                    id="smile_svg__a">
                    <feOffset dx="2" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                    <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.201964447 0" in="shadowBlurOuter1"
                      result="shadowMatrixOuter1"></feColorMatrix>
                    <feMerge>
                      <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
                      <feMergeNode in="SourceGraphic"></feMergeNode>
                    </feMerge>
                  </filter>
                </defs>
                <g filter="url(#smile_svg__a)" transform="translate(-303 -9)" fill="#9E9E9E" fill-rule="nonzero">
                  <path
                    d="M321.26 24h1.022c0 3.47-2.761 6.287-6.252 6.287-3.49 0-6.316-2.816-6.316-6.287h1.051c0 3.093 2.588 5.267 5.265 5.267 2.678 0 5.23-2.174 5.23-5.267zm-2.117-4.19a1.047 1.047 0 110 2.094 1.047 1.047 0 010-2.094zm-6.294 0h.017c.573 0 1.039.468 1.039 1.047 0 .578-.466 1.048-1.04 1.048h-.016c-.574 0-1.04-.47-1.04-1.048 0-.579.466-1.047 1.04-1.047zM316 13c-6.075 0-11 4.925-11 11s4.925 11 11 11 11-4.925 11-11-4.925-11-11-11zm0 1.048c5.497 0 9.952 4.455 9.952 9.952 0 5.496-4.455 9.952-9.952 9.952s-9.952-4.456-9.952-9.952c0-5.497 4.455-9.952 9.952-9.952z">
                  </path>
                </g>
              </svg>
            </span>
            <div ref="emojilist" class="balloon__wrapper">
              <div class="im-emotions">
                <div class="simple-scroller emoji-list custom-scroll-bar">
                  <div class="scroll-content" style="width: 304px; height: 260px">
                    <div class="emoji-item" v-for="(d, i) in emoji" :key="i" @click="onEmoji(i)">
                      <!-- {{ i+1 }} -->
                      <img class="emoji-item-pic" width="70" height="70"
                        :src="require('@/static/emoji/' + (i + 1) + '.png')" style="height: 70px" /><span
                        class="name">{{
                          emoji[i] }}</span>
                    </div>
                  </div>
                  <div class="scrollbar-track none">
                    <div class="scrollbar-thumb"></div>
                  </div>
                  <div class="scrollbar-track horizontal none">
                    <div class="scrollbar-thumb horizontal"></div>
                  </div>
                </div>
              </div>
            </div>
          </span>
        </div>
        <button type="submit" class="message-view-field__send" @click="onSubmit()">
          <svg class="icon-plane disable" width="21" height="18" id="buyer_msg_send_btn">
            <path d="M0 0v7l15 2-15 2.001V18l21-9z" fill="#979797" fill-rule="evenodd"></path>
          </svg>
        </button>
      </div>
    </div>
    <!--  -->
    <ChatFile ref="chat_file" @onSend="onSend"></ChatFile>
    <ChatShowImg ref="chat_show"></ChatShowImg>
  </div>
</template>

<script>
import ChatFile from './ChatFile.vue'
import ChatShowImg from './ChatShowImg.vue'
// import { CloseCircleOutlined, TagOutlined } from '@ant-design/icons-vue'

import chat from "@/api/chat"
export default {
  name: 'ChartVue',
  components: {
    ChatFile,
    ChatShowImg,
    // CloseCircleOutlined,
    // TagOutlined
  },
  data() {
    return {
      //加载
      loading: true,

      //内容
      content: '',
      // 显示表情
      isShowEmoji: false,

      // 表情代码
      emoji: [
        'Smile',
        'Shy',
        'Tongue',
        'Tittering',
        'Admire',
        'LOL',
        'Dancing',
        'Kiss',
        'Comfort',
        'Hug',
        'Cheer up',
        'Victory',
        'Good job',
        'Kissing',
        'Obsessed',
        'Grinning',
        'Search',
        'Announce',
        'Accounting',
        'Miser',
        'Great idea',
        'Grimace',
        'Angel',
        'Waving',
        'Drool',
        'Enjoy',
        'Erotomania',
        'Dumbstruck',
        'Think',
        'Puzzle',
        'Query',
        'No money',
        'Boring',
        'Suspicious',
        'Be Quiet',
        'Complacent',
        'Shakehead',
        'Have a cold',
        'Awkward',
        'Smirking',
        'What',
        'However',
        'Sweat',
        'Loneliness',
        'Sleepy',
        'Dizzy',
        'Distressed',
        'Grievance',
        'Sob',
        'Cry',
        'Wail',
        'Alright',
        'Sorry',
        'See you',
        'Frowning',
        'Tired',
        'Vomit',
        'Bad Luck',
        'Stunned',
        'Surprised',
        'Shutup',
        'Fight',
        'Despise',
        'Rage',
        'Super Angry',
        'Mammon',
        'Leifeng',
        'Wish prosperity',
        'Waiter',
        'Boss',
        'Evil',
        'To challenge',
        'CS',
        'Invisible',
        'Bmob',
        'Scream',
        'Girl',
        'Boy',
        'Fortunecat',
        'Deal',
        'Applause',
        'Shake Hands',
        'Lips',
        'Rose',
        'Fall',
        'Love',
        'Heartbroken',
        'Money',
        'Shopping',
        'Gift',
        'Mail',
        'Phone',
        'Celebration',
        'Clock',
        'Wait',
        'Toolate',
        'Airplane'
      ],

      // 聊天列表
      currentChat: {
        id: 0,
        session_id: 0
      },
      mineChat: {
        avatar: ''
      },
      chatList: [],
      msgList: [],
      goods_id: 0,
      goods: [],
      goods_show: false,

      page: 1,
      isOver: false,

      //循环加载
      ajaxMsg: 0,
      ajaxTime: 2000,

      status: {
        status: 'success',
        title: this.$t('base.zx')
      },

      //搜索文案
      search_text: '',
      searchList: []
    }
  },
  created() {
    let code = ''
    if (this.$route.query.code) {
      code = this.$route.query.code
    }

    if (this.$route.query.goods_id) {
      this.goods_id = this.$route.query.goods_id
    }

    let customer = ''
    if (this.$route.query.customer) {
      customer = this.$route.query.customer
    }

    this.getChatList(code, this.goods_id, customer)
  },
  mounted() {
    document.addEventListener('click', this.bodyCloseMenus)
    //this.onSocket()
  },
  beforeUnmount() {
    document.removeEventListener('click', this.bodyCloseMenus)
    this.ajaxTime = 0
    clearTimeout(this.ajaxMsg)
    console.log('清除定时')
    //取消订阅消息通知
    //this.$socket.send({ method: 'subscribe', data: { type: 'unchat' } })
  },
  methods: {
    onSearchChat(item) {
      let that = this
      this.chatList.forEach((vo) => {
        if (vo.session_id === item.session_id) {
          that.onSearchClear()
          that.onChat(vo)
        }
      })
    },
    onSearchClear() {
      this.search_text = ''
      this.searchList = []
    },
    searchText() {
      console.log(this.search_text.length)
      if (this.search_text.length >= 0) {
        let that = this
        chat.searchText({ text: this.search_text }).then((res) => {
          console.log(res)
          that.searchList = res.data
        })
      } else {
        this.searchList = []
      }
    },
    setCurrentMsg(content) {
      if (!this.currentChat.message) {
        this.currentChat.message = {
          id: 0,
          content: '',
          session_id: this.currentChat.session_id
        }
      }
      this.currentChat.message.content = content
    },
    /**
     * 点击空白处
     */
    bodyCloseMenus(e) {
      // console.log(e)
      //点击空白处
      if (!this.$refs.emojilist.contains(e.target)) {
        this.isShowEmoji = false
      }
    },
    onShowImg(url) {
      this.$refs.chat_show.show(url)
    },
    onDetail(id) {
      // console.log(id, 'id')
      this.$router.push({ name: 'detail', query: { id: id } })
    },
    /**
     * 打开文件
     */
    onFile() {
      this.$refs.chat_file.show()
    },
    onSend(img) {
      // console.log(img)
      this.onSubmit(img)
      this.$refs.chat_file.hide()
    },
    scrollBottom() {
      // 让滚动条始终在最底部
      this.$nextTick(() => {
        this.$refs.messageContent.scrollTop = this.$refs.messageContent.scrollHeight
      })
    },
    onRevoke(sort, id) {
      // console.log(id, id)
      if (!id) {
        // 提交数据
        this.$message.warning({ content: 'too fast. please wait', key: 'msg' })
        return
      }

      chat.revoke({ id: id }).then((res) => {
        // console.log(res)
        if (res.code == 1) {
          this.$message.error(res.msg)
          return
        }

        this.msgList.splice(sort, 1)

        this.$message.success(res.msg)
      })
    },
    /**
     * 切换对象
     */
    onChat(item) {
      //清除未读
      if (item.msg_total > 0) {
        item.msg_total = 0
      }
      this.chatList.forEach((vo) => {
        if (item.session_id === vo.session_id) {
          vo.msg_total = item.msg_total
        }
      })

      this.currentChat = item
      this.page = 1
      this.msgList = []
      this.isOver = false
      this.getMessageList()
    },
    onDelete(sort, item) {
      this.$message.loading({ content: 'delete...', key: 'msg' })
      let that = this
      chat.deleteMsg({ id: item.id }).then((res) => {
        // console.log(res)
        if (res.code == 1) {
          that.$message.error(res.msg)
          return
        }
        that.chatList.splice(sort, 1)
        //如果删除的是当前
        if (that.currentChat.id === item.id) {
          if (that.chatList.length > 0) {
            that.onChat(that.chatList[0])
          } else {
            that.msgList = []
          }
        }
        that.$message.success({ content: res.msg, key: 'msg' })
      })
    },

    compare(a, b) {
      if (a.sort > b.sort) return -1
      else if (a.sort < b.sort) return 1
      else return 0
    },
    onSetTop(sort, item) {
      this.$message.loading({ content: item.sort > 0 ? 'cancel top...' : 'set top...', key: 'msg' })
      let that = this
      chat.setTop({ id: item.id }).then((res) => {
        if (res.code == 1) {
          that.$message.error(res.msg)
          return
        }
        if (item.sort > 0) {
          that.chatList[sort].sort = 0
          that.chatList.sort(that.compare)
        } else {
          //置顶
          let arr = [].concat(that.chatList[sort])[0]
          arr.sort = 1 //随便给个值
          console.log(arr)
          that.chatList.splice(sort, 1)
          that.chatList.unshift(arr)
        }
        //如果删除的是当前
        // if (that.currentChat.id === item.id) {
        //   if (that.chatList.length > 0) {
        //     that.onChat(that.chatList[0])
        //   } else {
        //     that.msgList = []
        //   }
        // }
        that.$message.success({ content: res.msg, key: 'msg' })
      })
    },
    /**
     * 获取消息列表
     * @param {*} code
     */
    async getChatList(code, goods_id, customer) {
      //获取列表
      let that = this
      await chat.getChatList({ code: code, goods_id: goods_id, customer: customer }).then((res) => {
        that.currentChat = res.data.chat
        that.mineChat = res.data.mine
        that.chatList = res.data.list
        if (res.data.goods) {
          that.goods = res.data.goods
          setTimeout(function () {
            that.goods_show = true
          }, 2000)
        }
      })

      //循环获取消息
      if (that.chatList.length) {
        that.onChat(that.currentChat)
      }
    },
    onShowImgClose() {
      this.goods_show = false
    },
    onMsgScroll(e) {
      e
      // console.log(e.target.scrollTop)
      // if (e.target.scrollTop < 10 && e.target.scrollTop > 0) {
      //   this.getMessageList()
      // }
    },
    async getMessageList() {
      if (this.currentChat.session_id <= 0 || this.isOver) {
        return
      }

      let that = this
      this.loading = true
      await chat
        .getMessageList({
          session_id: this.currentChat.session_id,
          page: this.page
        })
        .then((res) => {
          that.loading = false

          if (res.data.data.length <= 0) {
            this.isOver = true
          } else {
            that.msgList = res.data.data
            //添加
            // res.data.data.forEach((item) => {
            //   that.msgList.unshift(item)
            // })

            if (that.msgList.length > 0) {
              that.setCurrentMsg(that.msgList[that.msgList.length - 1].content)
            }

            if (this.page == 1) {
              that.scrollBottom()
            }

            that.page++
          }

          // 开始循环
          if (!this.ajaxMsg) {
            that.runAjaxMsg()
          }
        })
    },
    /**
     * 异步循环获取消息
     */
    runAjaxMsg() {
      if (this.currentChat.session_id) {
        let that = this
        chat
          .ajaxMsg({ session_id: this.currentChat.session_id })
          .then((res) => {
            if (res.data.msg.length) {
              that.msgList = that.msgList.concat(res.data.msg)
              that.scrollBottom()
            }
            const chat = res.data.chat
            if (JSON.stringify(chat) !== '[]') {
              that.chatList.forEach((item) => {
                if (chat[item.id]) {
                  item.msg_total = chat[item.id].msg_total
                }
              })
            }
          })
          .catch((res) => {
            // console.log(res.response.data)
            if (res.response.data.code == 1) {
              this.currentChat = {
                id: 0,
                session_id: 0
              }
              return
            }
          })
      }
      if (this.ajaxTime > 0) {
        console.log('ajaxTime', this.ajaxTime)
        this.ajaxMsg = setTimeout(this.runAjaxMsg, this.ajaxTime)
      }
    },
    replaceContent(content) {
      //替换表情
      this.emoji.forEach((item, i) => {
        if (content.indexOf(`[${item}]`) >= 0) {
          content = content.replace(`[${item}]`, `<img src="` + require('@/static/emoji/' + (i + 1) + '.png') + `">`)
        }
      })

      return content
    },
    onShowEmoji() {
      this.isShowEmoji = !this.isShowEmoji
    },
    /**
     * 点击表情
     */
    async onEmoji(i) {
      //表情代码
      let code = '[' + this.emoji[i] + ']'

      const myField = document.querySelector('#textarea')
      if (myField.selectionStart || myField.selectionStart === 0) {
        var startPos = myField.selectionStart
        var endPos = myField.selectionEnd
        this.content = myField.value.substring(0, startPos) + code + myField.value.substring(endPos, myField.value.length)
        await this.$nextTick() // 这句是重点, 圈起来
        myField.focus()
        myField.setSelectionRange(endPos + code.length, endPos + code.length)
      } else {
        this.content += code
      }

      this.onShowEmoji()
    },
    //发送商品
    onSendGoods() {
      // console.log(this.goods)
      this.onSubmit(null, this.goods.goods)
      this.goods = {}
    },
    /**
     * 发送聊天
     */
    onSubmit(img = null, goods = null) {
      if (img == null && goods == null && this.content.length <= 0) {
        return
      }
      // console.log(this.content.length, this.content, img)

      let content = this.content
      let type = 1
      if (img) {
        content = img
        type = 2
      }
      // console.log(goods, 'goods')
      if (goods) {
        content = JSON.stringify({
          id: this.goods_id,
          img: goods.img,
          name: goods.name,
          price: goods.price
        })
        type = 3
      }

      // 提交数据
      // this.$ui.openLoading();
      // this.$message.loading({ content: 'send...', key: 'msg' })

      const that = this

      let msg = {
        content: content,
        create_time_str: '',
        msg_type: 2,
        type: type,
        session_id: this.currentChat.session_id,
        unread: 1,
        time_gap: 1
      }
      that.msgList = that.msgList.concat(msg)
      that.setCurrentMsg(content)
      that.scrollBottom()

      chat
        .sendMsg({
          session_id: this.currentChat.session_id,
          content: content,
          type: type
        })
        .then((res) => {
          // this.$ui.closeLoading();
          this.$message({
            message: res.msg,
            type: 'success'
          });

          that.msgList[that.msgList.length - 1].id = res.data.id
        })

      this.content = ''
    }
  }
}
</script>

<style>
.msg_main {
  width: 100%;
  display: flex;
  font-family: Roboto, sans-serif;
  height: 600px;
  overflow: hidden;
  box-sizing: border-box;
  background: #fff;
  border: none;
  border-radius: 2px 2px 0 0;
  box-shadow: 0 0 7px 0 #999;
  transform-origin: right bottom;
}

.msg_left_box {
  width: 272px;
  height: 100%;
  border-right: 1px solid #e6e7eb;
  display: flex;
  flex-direction: column;
}

.msg_left_title {
  height: 50px;
  padding: 0 21px;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  border-bottom: 1px solid #e6e7eb;
}

.msg_left_content {
  width: 271px;
  flex: 1 1;
  overflow: auto;
  padding-bottom: 64px;
}

.msg_user {
  display: flex;
  align-items: center;
  padding: 6px 25px 6px 12px;
  height: 64px;
  cursor: pointer;
  position: relative;
}

/* .msg_user.active {
  background-color: #f2f2f2;
} */

.msg_user:hover {
  background: #f2f3f7;
}

.msg_user.active {
  background: #e6f7ff;
}

.msg_avatar {
  width: 36px;
  height: 36px;
  background: #eee;
  border: 1px solid #ccc;
  border-radius: 50%;
  overflow: hidden;
}

.msg_info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1;
  margin-left: 12px;
  min-width: 0;
}

.msg_row {
  display: flex;
  min-width: 0;
}

.msg_info .title {
  flex: 1 1;
  font-size: 14px;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.msg_info .date {
  font-size: 12px;
  line-height: 20px;
  padding-left: 4px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.msg_info .desc {
  width: 100%;
  max-height: 30px;
  line-height: 16px;
  font-size: 12px;
  overflow: hidden;
  word-break: break-all;
}

.msg_info .settop {
  position: absolute;
  top: 10px;
  right: 5px;
  opacity: 0;
}

.msg_info .settop.active {
  position: absolute;
  top: 10px;
  right: 5px;
  opacity: 1;
}

.msg_info .settop.active path {
  fill: #00b5ce;
}

.msg_info .settop:hover path {
  fill: #00b5ce;
}

.msg_info .delete {
  position: absolute;
  bottom: 0;
  right: 2px;
  opacity: 0;
}

.msg_info .delete:hover path {
  fill: #00b5ce;
}

.msg_user:hover .delete {
  opacity: 1;
}

.msg_user:hover .settop {
  opacity: 1;
}

.msg_info .badge {
  position: absolute;
  height: 16px;
  border-radius: 8px;
  background: #f96150;
  text-align: center;
  font-size: 10px;
  color: #fff;
  letter-spacing: 0;
  line-height: 16px;
  padding: 0 5px;
  right: 6px;
}

.msg_right_box {
  display: flex;
  flex: 1 1;
  width: 100%;
  flex-direction: column;
  position: relative;
}

.m_right_title {
  height: 50px;
  padding: 0 16px;
  border-bottom: 1px solid #e6e7eb;
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
}

.msg_box {
  display: flex;
  width: 100%;
  flex: 1 1;
  overflow: auto;
}

.message-view-field {
  display: -ms-flexbox;
  display: flex;
  padding: 8px 10px;
  background: #f9f9f9;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  border-top: 1px solid #ebecf0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  bottom: 0;
}

.message-view-field__input-block {
  -ms-flex-positive: 1;
  flex-grow: 1;
  display: -ms-flexbox;
  display: flex;
  background: #fff;
  padding: 2px 6px 2px 22px;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #cdced2;
  border-radius: 20px;
  position: relative;
  margin-left: 11px;
}

.message-view-field__send {
  margin-left: 10px;
  color: #9e9e9e;
  cursor: pointer;
  border: none;
  background: transparent;
  display: flex;
}

.balloon__wrapper {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: absolute;
  border: 1px solid transparent;
  border-radius: 3px;
  word-wrap: break-word;
  background: #fff;
  -webkit-box-shadow: 0 0 6px 0 rgb(0 0 0 / 50%);
  box-shadow: 0 0 6px 0 rgb(0 0 0 / 50%);
  padding: 0;
  bottom: calc(100% + 10px);
  right: calc(50% - 14px);
  width: 293px;
}

.im-emotions {
  display: inline-block;
  position: relative;
  width: 100%;
  max-height: 300px;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}

.im-emotions .emoji-list {
  height: 260px;
  overflow: hidden;
  padding: 5px 10px 5px 2px;
}

.im-emotions .emoji-list .emoji-item {
  padding: 5px 3px;
  font-size: 12px;
  display: inline-block;
  cursor: pointer;
}

.im-emotions .emoji-list .emoji-item-pic {
  width: 30px !important;
  height: 30px !important;
  text-align: center;
  display: block;
}

.im-emotions .emoji-list .name {
  width: 100%;
  display: none;
  text-align: center;
  color: #757575;
}

.simple-scroller.custom-scroll-bar .scroll-content {
  padding-top: 10px;
  height: 88%;
  overflow: auto;
  padding-bottom: 80px;
}

.message-view-field__upload {
  cursor: pointer;
}

.file-upload {
  display: flex;
  position: relative;
}

.message-fileds__autosize {
  border: none;
  line-height: 20px;
  font-size: 14px;
  color: #212121;
  outline: none;
  padding: 0 3px 0 4px;
  margin-right: 4px;
  resize: none;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.balloon {
  display: flex;
  position: relative;
}

.balloon__trigger {
  display: flex;
}

.clickable {
  cursor: pointer;
}

.balloon--hidden .balloon__wrapper {
  display: none;
}

/* content */
.message-box {
  height: 100%;
  width: 100%;
}

.simple-scroller {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.chat-list .top-holder {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  height: 40px;
  line-height: 40px;
  overflow: hidden;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.invisible {
  /* visibility: hidden; */
}

.message-box .message-row {
  padding: 0 12px 6px 6px;
}

.message-row {
  padding: 0 0 20px;
  display: -ms-flexbox;
  display: flex;
}

.message-row .avatar-holder,
.message-row .simple-avatar {
  width: 40px;
  min-width: 40px;
  height: 40px;
  margin: 0 5px;
}

.lzd-message-item.card-system {
  background: none;
  margin: 0 auto;
  font-size: 13px;
  color: #9299a2;
  word-break: break-word;
  text-align: center;
  line-height: 20px;
}

.lzd-message-item {
  padding: 5px;
  height: auto;
  width: auto;
  max-width: 750px;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  background: #f9f9f9;
  background-color: #fff;
  border-radius: 8px;
}

.row-card-system .message-contain {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.message-contain {
  position: relative;
  font-size: 12px;
}

.message-row .message-read-type {
  width: 100%;
  text-align: right;
}

.simple-avatar>img {
  width: 100%;
  height: 100%;
  display: block;
}

img {
  vertical-align: middle;
}

.user-type-2 {
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.user-type-1 {
  -ms-flex-direction: row;
  flex-direction: row;
}

.message-contain {
  position: relative;
  font-size: 12px;
}

.message-row.user-type-1 .lzd-message-item:not(.card-system) {
  background: #e3f0ff;
}

.message-row.user-type-2 .lzd-message-item:not(.card-system) {
  background: #e3f0ff;
}

.message-card {
  width: 255px;
  padding: 0 10px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 12px;
  color: #3a434d;
  background: #fff;
}

.card-product {
  background: #fff;
  cursor: pointer;
  height: 125px;
}

.message-card .card-header {
  height: 30px;
  line-height: 30px;
  font-size: 12px;
  color: #3a434d;
  border-bottom: 1px solid #d5d5d5;
}

.message-card .card-content {
  margin-top: 9px;
  margin-bottom: 9px;
  width: 100%;
  display: -ms-flexbox;
  display: flex;
}

.card-product .lzd-pro-img {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  border: 0 solid #e6e7eb;
  width: 62px;
  height: 62px;
  margin-right: 8px;
}

.background-default {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -ms-flex-align: center;
  align-items: center;
}

.card-product .lzd-pro-desc {
  -ms-flex: 1 1;
  flex: 1 1;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  line-height: 20px;
  overflow: hidden;
}

.message-card .text-two-lines {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
}

.card-product .lzd-pro-title {
  max-height: 30px;
  font-size: 12px;
  line-height: 14px;
  color: #3a434d;
}

.text-three-lines,
.text-two-lines {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.text-two-lines {
  -webkit-line-clamp: 2;
}

.message-card .text-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-product .lzd-pro-price {
  margin-top: 5px;
  font-size: 16px;
  color: #ff3d00;
  font-weight: 500;
}

.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.card-product .lzd-pro-old-price-content {
  margin-top: 3px;
  font-size: 12px;
  line-height: 12px;
  color: #999;
  display: -ms-flexbox;
  display: flex;
}

/* 消息框滑条 */
.simple-scroller.hover>.scrollbar-track,
.simple-scroller.hover>.scrollbar-track.horizontal,
.simple-scroller:hover>.scrollbar-track,
.simple-scroller:hover>.scrollbar-track.horizontal {
  background: hsla(0, 0%, 94.5%, 0.4);
}

.simple-scroller .scrollbar-track {
  position: absolute;
  top: 5px;
  bottom: 5px;
  right: 0;
  width: 12px;
  -webkit-transform: scaleX(0.66);
  transform: scaleX(0.66);
  -webkit-transform-origin: right;
  transform-origin: right;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: transparent;
  border-radius: 5px;
  -webkit-transition: background-color 0.3s ease, -webkit-transform 0.3s ease;
  transition: background-color 0.3s ease, -webkit-transform 0.3s ease;
  transition: background-color 0.3s ease, transform 0.3s ease;
  transition: background-color 0.3s ease, transform 0.3s ease, -webkit-transform 0.3s ease;
  cursor: pointer;
}

.lzd-message-item.card-text {
  position: relative;
  display: inline-block;
  -ms-flex-item-align: end;
  align-self: flex-end;
}

.message-text-box {
  padding: 5px;
}

.lzd-message-text {
  word-break: break-word;
  word-wrap: break-word;
  max-width: 750px;
  font-size: 14px;
  color: #151515;
  letter-spacing: 0;
  white-space: pre-wrap;
}

.emoji-face {
  cursor: text;
}

.emoji-img {
  vertical-align: bottom;
}

.emoji-img img {
  vertical-align: bottom;
  height: 30px;
}

.message-row.user-type-2 .box {
  display: none;
}

.lzd-message-text.translation {
  position: relative;
  margin-top: 12px;
}

.lzd-message-text.tip {
  margin-top: 4px;
  margin-bottom: -6px;
  opacity: 0.5;
  font-size: 10px;
  color: #333;
  letter-spacing: 0;
  text-align: right;
  line-height: 20.15px;
}

.hide-for-select {
  position: absolute;
  opacity: 0;
  left: -9999px;
  top: -9999px;
}

.message-row.user-type-1 .read-type {
  display: none;
}

.message-row .read-type {
  -ms-flex-item-align: end;
  align-self: flex-end;
  margin: 0 6px 4px;
  color: #999;
  font-size: 14px;
}

.simple-avatar.circle {
  border-radius: 50%;
}

.simple-avatar {
  margin: 0;
  padding: 0;
  position: relative;
  display: inline-block;
  overflow: hidden;
  background: transparent;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-align: center;
  background: #eee;
  color: #fff;
  white-space: nowrap;
  vertical-align: middle;
  width: 32px;
  height: 32px;
  line-height: 32px;
}

.message-contain .message-withdraw {
  background: #fff;
  border-radius: 5px;
  padding: 7px 5px 3px;
  position: absolute;
  left: -27px;
  bottom: 16px;
}

.chat-goods {
  position: absolute;
  width: 100%;
  height: 140px;
  /* background: #000; */
  bottom: 63px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transform: translateY(10px);
  transition: opacity 0.15s ease, visibility 0.15s ease, transform 0.15s ease;
}

.chat-goods.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0px);
}

.c-gbox {
  width: 300px;
  background: #f5f5f5;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0px 0px 5px 0px #c3c2c2;
}

.c-gboxx {
  display: flex;
  align-items: center;
}

.c-gimg {
  width: 75px;
  height: 75px;
  border-radius: 10px;
  flex: 1 30%;
  padding: 5px;
  overflow: hidden;
}

.c-gimg img {
  width: 100%;
  height: 100%;
}

.c-gname {
  flex: 1 70%;
  padding: 10px;
  overflow: hidden;
}

.c-text {
  /* white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  font-size: 15px;
  font-weight: 700;
}

.c-price {
  color: #ff9800;
  font-size: 16px;
  font-weight: 700;
}

.c-btnbox {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
}

.c-btn-send {
  border: 1px solid #d3e7ff;
  padding: 2px 11px;
  border-radius: 5px;
  background: #e3f0ff;
  color: #21acf7;
  cursor: pointer;
}

.read-chehui {
  align-self: flex-end;
  margin: 0 6px 4px;
  color: #999;
  font-size: 14px;
  cursor: pointer;
}

.simg {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.simg>>>div {
  background-size: 100% !important;
}

.simg image {
  width: 90%;
  height: 90%;
}

.g-name {
  width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  font-size: 15px;
  font-weight: 700;
}

.g-price {
  color: #f44336;
  font-weight: 700;
}

.ss-tkbox {
  position: absolute;
  width: 100%;
  top: 32px;
  left: 0;
  z-index: 10;
  background: #fff;
  overflow-y: scroll;
  height: calc(100% - 32px);
}

.ss-tkbox ul {
  display: flex;
  flex-direction: column;
  gap: 0px;
}

.ss-tkbox ul li {
  padding: 0px 5px;
  cursor: pointer;
}

.ss-tkbox ul li:hover {
  background: #f5f5f5;
}

.ss-box1 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 0;
  border-bottom: 1px dashed #dedede;
}

.ss-head1 {
  width: 20%;
  padding: 2px;
}

.ss-avatar {
  width: 100%;
}

.ss-desc1 {
  width: 80%;
  padding: 5px 10px;
}

.ss-name {
  font-weight: 700;
}

.ss-desc {
  color: #aeaeae;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
