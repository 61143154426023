module.exports = {
  base: {
    title: 'STARTHESTAR',
    desc1: 'Konge af kontrakt',
    desc2: 'Globalt førende inden for kontrakthandel',
    d1: 'Tillid',
    d2: 'Ærlighed',
    d3: 'Styrke',
    more: 'Mere',
    top: 'tilbage til toppen',
    download: 'Download APP',
    zszh: 'Rigtig konto',
    mnzh: 'Demo konto',
    content: 'Handel med en global markedsleder inden for kontrakthandel',
    lxkf: 'Kontakt kundeservice',
    chxx: 'Tilbagekald',
    zx: 'online'
  },
  demo: {
    title: 'tilmelding succes!',
    title2: 'Analog konto',
    submit: 'Bekræfte'
  },
  lang: {
    title: 'dansk'
  },
  message: {
    invalid_email: 'Ugyldig email',
    invalid_password: 'Forkert kodeord',
    invalid_new_password: 'Ugyldig ny adgangskode',
    invalid_res_password: 'Ugyldig bekræftelsesadgangskode',
    invalid_email_code: 'Ugyldig e-mailbekræftelseskode',
    password_inconsistent: 'Inkonsekvente adgangskoder',
    invalid_invitation_code: 'Ugyldig invitationskode',
    should_agree: 'Læs venligst og accepter registreringsbetingelserne',
    not_null: 'Dette felt må ikke være tomt',
    send_email: 'Prøv venligst igen efter {:s}s',
    invalid_number: 'ugyldig værdi',
    invalid_balance: 'Ugyldig saldo',
    invalid_rate: 'Vælg venligst flere',
    invalid_rule: 'Vælg venligst en regel',
    invalid_system_rule: 'Ingen regler i systemet',
    not_enough: 'Ikke nok tilgængelig',
    price_high: 'Prisen skal være højere end {price}',
    price_low: 'Prisen skal være mindre end {price}',
    number_high: 'Det maksimale antal udbetalinger er {num}',
    number_low: 'Minimumsbeløbet for tilbagetrækning er {num}',
    copy_address: 'Kopier adressen korrekt {text}',
    invalid_upload_front: "Upload venligst det forreste billede af ID'et",
    invalid_upload_back: 'Upload venligst et billede af bagsiden af dit ID',
    invalid_upload_hand: 'Upload venligst et håndholdt ID-billede',
    invalid_upload_name: 'Indtast venligst navnet på certifikatet',
    invalid_upload_card: 'Indtast venligst ID-nummeret',
    upload_pic_type: 'Upload billeder kan kun være i JPG, PNG-format',
    upload_pic_size: 'Upload billedstørrelse må ikke overstige 10 MB'
  },
  index: {
    title2: 'Globalt marked fri handel 24 timer non-stop',
    desc2: 'Implementer din handelsstrategi på et ekstremt likvidt marked med en 24-timers handelsvolumen på',
    desc2_1: 'på hele platformen',
    list: {
      name: 'marked',
      h24_price: '24H seneste pris',
      h24_change: '24H skift',
      h24_high: '24H højeste pris',
      h24_low: '24H laveste pris',
      h24_vol: '24H volumen'
    },
    footer: {
      desc:
        'starthestar Blockchain Technology Ltd, med hovedkontor i USA, er en verdensledende blockchain-teknologivirksomhed. Virksomheden har opnået den amerikanske MSB internationale finansielle licens. Det er en kompatibel blockchain-teknologiservicevirksomhed, der kan fungere i overensstemmelse på global skala Blockchain-virksomhedstjenester. Samtidig kommer starthestar Blockchain Technology Ltd tekniske teamtalenter fra top internationale videnskabelige forskningsinstitutioner og blockchain teknologiudviklingsteams for fuldt ud at beskytte brugermidler og kontosikkerhed. Ud over det tekniske team har virksomheden også et professionelt blockchain-team: digitale aktiver AI kvantitative investeringsteam, blockchain-projektværdievalueringsteam, digital asset venture-investeringsteam osv., har et globalt layout til at betjene globale blockchain-brugere. Virksomhedens mål og vision er at levere sikre, stabile, hensynsfulde og troværdige transaktionstjenester til globale digitale aktiventusiaster og at opbygge en professionel, digital og international verdenskendt blockchain-teknologivirksomhed.',
      item1: {
        tt: 'Produkt Display',
        pt1: 'OTC handelsregler',
        pt2: 'Begynderguide',
        pt3: 'Prisstandard',
        pt4: 'Kontraktbeskrivelse'
      },
      item2: {
        tt: 'Support',
        pt1: 'KYC og anti-hvidvaskaftale',
        pt2: 'Håndhævelsesanmodningspolitik',
        pt3: 'Introduktion til digitale aktiver'
      },
      item3: {
        tt: 'Betingelser',
        pt1: 'Beskyttelse af privatlivets fred',
        pt2: 'Brugeraftale',
        pt3: 'Risikomeddelelse'
      },
      item4: {
        tt: 'kontakt os',
        pt1: 'Erhvervssamarbejde',
        pt2: 'Institutionelt samarbejde',
        pt3: 'OTC ansøgning',
        pt4: 'Kundeservice e-mail',
        pt5: 'Klager og forslag',
        pt6: 'mediesamarbejde'
      }
    },
    bk3: {
      title: 'Stolt af innovativ R&D styrke',
      desc: 'Ufattelig kontrakthandelsoplevelse, designet til finansielle forhandlere',
      pt1: 'Åben ordre stop profit og stop loss',
      pd1: 'Afventende ordrer bliver ikke sent oppe, bekymre dig om ikke at se ordren',
      pt2: 'Omvendt åben rækkefølge',
      pd2: 'Markedet ændrer sig pludselig, følg med på et sekund',
      pt3: 'Support fuldt lager for lager',
      pd3: 'Gør hvad du vil, gør hvad du vil',
      pt4: 'Fuld stilling trinvist likvidation',
      pd4: 'Udenlandsk likvidation er først lig med halvdelen og forsøger at hjælpe investorer med at reducere tab'
    },
    bk5: {
      title: 'Otte fordele ved kontrakthandelsplatform',
      desc: 'Vi lytter hele tiden, optimerer og forbedrer løbende og skaber en førsteklasses handelsoplevelse for globale handelsbrugere',
      pt1: 'Markedsdybde',
      pd1: 'Markedsdybden af de første tre boder på platformen har nået størrelsesordenen millioner af dollars, hvilket langt overstiger branchens gennemsnitlige markedsdybde på 10.000 og 100.000. Spredningen er lille, og transaktionen er hurtig.',
      pt2: 'Glat handel',
      pd2: 'Ved at bruge en effektiv matchende transaktionsmotor til at sikre en smidig transaktionsoplevelse, kan den behandle millioner af ordrer i sekundet og kan understøtte en jævn og stabil drift med mere end 20 millioner samtidige onlinebrugere.',
      pt3: 'Rimelig pris',
      pd3: "'Marked Price' vælger transaktionsprisen i realtid for verdens tre store børser som indeksprisen for regressionsberegning, hvilket effektivt forhindrer brugertab forårsaget af unormal manipulation af den seneste transaktionspris i løbet af kort tid.",
      pt4: 'Selvudviklet teknologi',
      pd4: 'Matchmaking-motoren, der er rodfæstet i traditionel finansiering, udviklet af et team af senioreksperter i den finansielle industri, og omhyggeligt udformet hver eneste detalje for at sikre sikker, stabil og effektiv drift af handelsplatformen.',
      pt5: 'Top Risikokontrol',
      pd5: 'Andet niveau bedømmelse, flerlags kryptering, høj systemsikkerhed. Kombinationen af varme og kolde tegnebøger, krypteringsforanstaltninger af militær kvalitet, sikkerhed for aktivtransaktioner er garanteret, og 100 % garanterer sikkerheden for brugernes digitale aktiver.',
      pt6: 'Fonds sikkerhed',
      pd6: 'Tre-lags sikkerhedssystem, omfattende sikkerhedsrevision. Selvbygget branchens førende hierarkiske deterministiske cold wallet-system, alle brugere tildeler genopladningsadresser er cold wallet-adresser, indsamling og tilbagetrækning af midler gennemføres gennem offline signaturer.',
      pt7: 'Hardcore Power',
      pd7: 'Titusindvis af BTC-reserver, finansielle innovationsgener og verdens øverste ledelsesteam. starthestar lover, at hvis konto- og transaktionsdatafejl ikke er forårsaget af systemårsager, vil platformen betale fuldt ud i tilfælde af fejl!',
      pt8: 'Kvalitetsservice',
      pd8: '7 * 24 timer døgnet rundt professionel kundeservice, hurtigt svar. Start med at tænke på service, giv førsteklasses handelsoplevelse til globale kunder. Den ultimative oplevelse kommer fra konceptet med at betjene kunder.'
    },
    bk6: {
      title: 'Sikkerhed og overholdelse global drift',
      desc: 'Omfavn overvågning og overhold altid globale compliance-operationer'
    },
    bk7: {
      title: 'Officiel strategisk partner'
    },
    bk8: {
      title: 'Betjente mere end 100.000 globale handelsbrugere',
      desc: 'I øjeblikket er brugere over hele USA, Japan, Sydkorea, Seychellerne, Vietnam og andre lande'
    },
    bk9: {
      title: 'Glat transaktion når som helst og hvor som helst',
      pt1: 'Matchmaking-transaktioner, indbetalinger og udbetalinger i realtid ankommer på få sekunder',
      pt2: 'Flere handelstilstande, op til 125 gange gearing',
      dt1: 'iOS-version download',
      dt2: 'Download Android version'
    },
    about: {
      title: 'Om os',
      pt1: 'Platform koncept',
      pd1:
        'Lad globale aktiver cirkulere frit og skab en ny kontraktoplevelse. X-xenogenetic, repræsenterer innovative gener og giver en konstant strøm af kraft til industriinnovation. M-motor, repræsenterer målet, og fortsætter med at bevæge sig fremad med målet om at lede kontrakthandelsoplevelsen .EX-exchange, på vegne af handelsplatformen, er forpligtet til at skabe et retfærdigt, gennemsigtigt og effektivt handelsøkosystem med driftsfilosofien service, lytning og tænkning.',
      pt2: 'Kerneværdier',
      pd2:
        'Vi lytter altid, er opmærksomme og tænker fremad og er forpligtet til at skabe et retfærdigt, gennemsigtigt og effektivt handelsøkosystem. Giv globale handlende en bedre handelsoplevelse, imødekomme handlendes faktiske behov og fremme udviklingen af det digitale marked for handel med aktiver.',
      pt3: 'Team baggrund',
      pd3: 'Grundlæggerteamet er sammensat af top blockchain-investorer, traditionelle finansielle ledere, top digitale aktiveksperter og traditionelle metalbearbejdningsteknologiteam. Kernemedlemmerne i R&D-teamet er fra Morgan Stanley, CICC, Hang Seng, Tencent, Noah Wealth.'
    }
  },
  start: {
    p_title: 'Transaktion, start her',
    p_desc: 'Indtast venligst din e-mailadresse i følgende område for at tilmelde dig med det samme',
    p_email: 'e-mail',
    p_btn: 'skab'
  },
  download: {
    p_title: 'Download APP-klient',
    p_desc: 'Når som helst, hvor som helst, multi-platform terminal transaktion',
    p_btn: 'Hent',
    p_scan_android: 'Android app',
    p_scan_ios: 'IOS app'
  },
  downpage: {
    t1: {
      p_title: 'Lyntransaktionsydelse',
      p_desc: 'Giv en række transaktionstyper at vælge imellem, ordrematchning er ekstremt hurtig, et-kliks flash-udveksling, og handel er mere bekvemt'
    },
    t2: {
      p_title: 'Nem overførsel af aktiver',
      p_desc: 'Nem overførsel af aktiver på kontoen, realtidsoverblik over digitale aktiver, øg cirkeldiagrammet for valutaforhold'
    },
    t3: {
      p_title: 'Tilpasset handelspanel',
      p_desc: 'Definer frit handelslayoutet i henhold til forskellige handelsvaner, og øg indtastningen af K-line og dybdekort'
    },
    t4: {
      p_title: 'Account Integration Management',
      p_desc: 'Flere sikkerhedsbekræftelsesindstillinger, én konto administrerer C2C-konti og valutakonti'
    }
  },
  login: {
    login: 'Log på',
    title: 'Bruger login',
    desc1: 'Log venligst ind',
    desc2: 'Officiel hjemmeside',
    email: 'e-mail',
    email2: 'e-mail',
    email3: 'Ttelefon nummer',
    email_desc: 'Indtast venligst din e-mail',
    phone_desc: 'Indtast venligst telefonnummer',
    password: 'adgangskode',
    password_desc: 'Indtast venligst adgangskoden',
    forgot_password: 'Glemt kodeord',
    no_account: 'Ingen konto endnu'
  },
  signup: {
    signup: 'Tilmelde',
    title: 'Brugerregistrering',
    desc1: 'Velkommen til at logge ind',
    desc2: 'Officiel hjemmeside',
    invitation_code: 'invitations kode',
    invitation_desc: 'Indtast venligst invitationskoden',
    accept: 'Acceptere',
    service: 'Brugeraftale',
    and: 'og',
    policy: 'Privatlivsbeskyttelsespolitik',
    have_account: 'Har du allerede en bruger'
  },
  retrieve: {
    ptitle: 'Hent adgangskode',
    pemail_code: 'E-mail-bekræftelseskode',
    pset: 'Indstil adgangskode',
    set_title: 'Indstil adgangskode',
    set_desc: 'Du nulstiller din adgangskode. Udfyld venligst følgende indstillinger',
    new_password: 'nyt kodeord',
    password_desc: 'Indtast venligst en ny adgangskode',
    res_passowrd: 'Bekræft kodeord',
    res_passowrd_desc: 'Indtast venligst bekræftelsesadgangskoden'
  },
  valid_email: {
    title: 'Verifikation af registrering',
    desc: 'Du er ved at registrere en konto. Udfyld venligst følgende bekræftelse',
    email_code: 'E-mail-bekræftelseskode',
    email_desc: 'Indtast venligst bekræftelseskoden',
    get_code: 'Få bekræftelseskode'
  },
  ihead: {
    begin_trade: 'Begynd at handle',
    helper: 'Nybegynderhjælp',
    text: 'Dokumentcenter',
    new_currency: 'Nyt Valutaabonnement'
  },
  uhead: {
    title1: 'Råvarer',
    title2: 'International Forex',
    title3: 'Internationalt indeks',
    currency: 'betalingsmiddel',
    lever: 'Gearet transaktion',
    coins: 'Valutatransaktion',
    contract: 'Kontrakttransaktion',
    metal: 'Metal',
    energy: 'Energi',
    contract_info: 'Kontraktoplysninger',
    helper: 'Nybegynderhjælp',
    text: 'Dokumentcenter',
    assets: 'Aktiver',
    u_wallet: 'Min pung',
    u_contract: 'Kontraktkonto',
    u_lever: 'Udnyttelseskonto',
    u_account: 'Kontooplysninger',
    u_logout: 'Log ud'
  },
  news: {
    label: 'Nyheder',
    update: 'Opdateret den'
  },
  guide: {
    label: 'Begynderguide',
    list: [
      {
        ptitle: 'Sådan tilmelder du dig starthestar',
        pkey: 'k1'
      },
      {
        ptitle: 'Identitetsgodkendelse',
        pkey: 'k2'
      },
      {
        ptitle: 'Indstillinger for login-adgangskode og transaktionsadgangskode',
        pkey: 'k3'
      },
      {
        ptitle: 'Indskudsmønter',
        pkey: 'k4'
      },
      {
        ptitle: 'Mønttransaktionsproces',
        pkey: 'k5'
      },
      {
        ptitle: 'Tilbagetrækning',
        pkey: 'k6'
      },
      {
        ptitle: ' mobil APP download',
        pkey: 'k7'
      }
    ],
    content: {
      k1: require('./currency/en/guide/k1.js').default,
      k2: require('./currency/en/guide/k2.js').default,
      k3: require('./currency/en/guide/k3.js').default,
      k4: require('./currency/en/guide/k4.js').default,
      k5: require('./currency/en/guide/k5.js').default,
      k6: require('./currency/en/guide/k6.js').default,
      k7: require('./currency/en/guide/k7.js').default
    }
  },
  kline: {
    h24_high: '24H høj',
    h24_low: '24H lav',
    h24_vol: '24H volumen',
    commission: 'Nuværende Kommission',
    record: 'Transaktionspost',
    commission_list: 'Kommissionsliste',
    deal_list: 'Seneste aftale',
    price: 'Pris',
    xprice: 'Overskudsspredning',
    bzj: 'Margin',
    number: 'Nummer',
    total: 'Total',
    time: 'Tid',
    currency: 'Stillinger',
    type: 'Type',
    direction: 'Retning',
    multiple: 'Udnyttelse',
    fee: 'Provision',
    amount: 'Total',
    status: 'Status',
    operate: 'Operation',
    buy: 'Købe',
    sell: 'Sælge',
    pending: 'Afventende ordre',
    trade_success: 'Handel vellykket',
    retract: 'Afbestille',
    profit: 'Tag overskud',
    loss: 'Stop tab',
    all: 'Alle',
    all_currency: 'Alle valutaer',
    new_price: 'Seneste pris',
    change: 'Stige og falde',
    protocol: 'protokol',
    _status1: 'Anmeldelse',
    _status2: 'Vellykket',
    _status3: 'Afvist'
  },
  coins: {
    title: 'Valutatransaktion',
    market: 'markedspris',
    limit: 'Grænsepris',
    profit: 'Tag overskud',
    loss: 'Stop tab',
    price: 'Pris',
    current: 'Nuværende',
    price_desc: 'Indtast venligst prisen',
    number: 'Nummer',
    number_desc: 'Indtast venligst nummeret',
    available: 'Ledig',
    half: 'halvt lager',
    all: 'Fuldt lager',
    fee: 'Provision',
    amount: 'overførselsbeløb',
    buy: 'Købe',
    sell: 'Sælge',
    current_price: 'Nuværende',
    open_price: 'Åben i dag',
    high: 'Høj',
    low: 'Lav',
    yesterday: 'Luk i går',
    today_zf: 'Prisændring',
    now_cc: 'Position',
    y_cc: 'Y-position',
    jsj: 'Afregningspris',
    zrjsj: 'Y Afregningspris'
  },
  lever: {
    title: 'Gearet transaktion',
    multiple: 'Udnyttelse',
    multiple_desc: 'Vælg venligst et multiplum',
    double: 'dobbelt',
    buy: 'Køb (op)',
    sell: 'Sælg (efterår)'
  },
  contract: {
    title: 'Kontrakttransaktion',
    time: 'Tid',
    time_desc: 'Vælg venligst et tidspunkt',
    minute: 'minut',
    second: 'Anden',
    buy: 'Køb (op)',
    sell: 'Sælg (efterår)',
    rate: 'Royalty-forhold'
  },
  wallet: {
    title: 'Mine aktiver',
    valuation: 'Samlet værdiansættelse af aktiver',
    my_wallet: 'Min pung',
    my_trade: 'Handelskonto',
    my_transfer: 'Udbetalingsrekord',
    my_deposit: 'Møntindbetalingspost',
    cbdz: 'indbetalingsadresse',
    cbsl: 'Mængde',
    deposit: 'Indskud mønter',
    withdrawal: 'Tilbagetrækning',
    search: 'Søg',
    currency: 'Stillinger',
    protocol: 'Protokol',
    liquidation: 'Luk position',
    liquidation_title: 'Hurtig likvidation',
    trade_pwd: 'Handelskodeord',
    enter_trade_pwd: 'Indtast handelsadgangskode',
    submit_liquidation: 'Luk stillingen med det samme',
    amount: 'Total',
    available: 'Ledig',
    freeze: 'Fryse',
    market: 'vurdering',
    ljsy: 'Kumuleret indkomst',
    zrsy: 'Gårsdagens indtjening',
    operate: 'Operation',
    countdown: 'Nedtælling',
    lever_title: 'Udnyttelseskonto',
    contract_title: 'Kontraktkonto',
    direction: 'Retning',
    bond: 'Margin',
    buy_rate: 'mange',
    profit: 'Fortjeneste og tab',
    profit1: 'Fortjeneste og tab',
    buy: 'Købe',
    sell: 'Sælge',
    many: 'Købe',
    empty: 'Sælge',
    contract_time: 'Kontrakt Tid',
    premium: 'Privilegium',
    minute: 'minut',
    address: 'Adresse',
    address_desc: 'Indtast venligst adressen',
    number: 'Nummer',
    number_desc: 'Indtast venligst antallet af udbetalinger',
    copy: 'Kopi',
    confirm: 'Okay',
    all: 'Alle',
    fee: 'Provision',
    actual: 'Faktisk ankomst',
    premark: 'Opmærksomhed',
    text_1: 'Denne adresse accepterer kun {name}-aktiver, lad venligst være med at genoplade andre valutaer, ellers vil aktiverne ikke blive hentet',
    text_2: 'Minearbejderens gebyr for {name} er: aktuel markedsværdi {rate}%/transaktion, minimumsstandard: {min}{name}/transaktion',
    text_3: 'Bekræft venligst, at modtageradresseoplysningerne er korrekte, før du overfører pengene. Når aktiverne er overført, kan de ikke trækkes tilbage.',
    text_4: '{name} Minegebyr er: {val} {name}/pen'
  },
  record: {
    title: 'Fondsrekord',
    type: 'Type',
    all_currency: 'Alle valutaer',
    all_type: 'Alle typer',
    select: 'Forespørgsel',
    clear: 'Ryd filter',
    receive: 'Depositum',
    transfer: 'Tilbagetrækning',
    buy: 'Købe',
    sell: 'Sælge',
    normal: 'Almindelig',
    pending: 'Stop-grænse',
    wallet: 'Positionsdetaljer',
    zzzqy: 'Balance',
    zqy: 'Fri margin',
    fdyk: 'Flydende P/L',
    fxl: 'Risikorate'
  },
  user: {
    account: 'Kontogodkendelse',
    account_desc: 'Kreditindbetaling, transaktion og andre funktioner skal først bestå kontobekræftelse',
    password: 'Login kodeord',
    password_desc: 'Bruges til at beskytte kontosikkerhed',
    modify: 'Modificere',
    set: 'Sæt',
    find_paypwd: 'Hent adgangskode',
    find_pay_pwd: 'Hent betalingsadgangskode',
    email: 'E-mail',
    enter_email: 'Indtast venligst din e-mail',
    email_code: 'Verifikationskode',
    enter_email_code: 'Indtast venligst e-mailbekræftelseskoden',
    send: 'Sende',
    pay_password: 'Betalingsadgangskode',
    pay_password_desc: 'Bruges til at beskytte aktivsikkerhed ved handel',
    not_certified: 'Ikke certificeret',
    certification: 'Certificering i gang',
    verified: 'Verificeret',
    rejected: 'afvist',
    update_pwd: 'Ændre login-adgangskode',
    update_pay_pwd: 'Ændre betalingsadgangskode',
    set_pay_pwd: 'Indstil betalingsadgangskode',
    old_pwd: 'Oprindelig adgangskode',
    enter_old_pwd: 'Indtast venligst den originale adgangskode',
    new_pwd: 'nyt kodeord',
    enter_new_pwd: 'Indtast venligst en ny adgangskode',
    res_pwd: 'Bekræft kodeord',
    enter_res_pwd: 'Indtast venligst bekræftelsesadgangskoden',
    submit: 'Indsend'
  },
  certified: {
    title: 'Kontogodkendelse',
    front: 'ID-kort foran',
    back: 'Bagsiden af ID-kortet',
    hand: 'Håndholdt ID-kort',
    name: 'Navn',
    name_desc: 'Indtast venligst et navn',
    card: 'ID-nummer',
    card_desc: 'Indtast venligst ID-nummer',
    submit: 'Indsend'
  },
  trade: {
    title: 'transaktionsadgangskode',
    enter_password: 'Indtast venligst transaktionsadgangskode',
    cancel: 'afbestille',
    submit: 'Indsend'
  },
  revoke: {
    title: 'Spørg besked',
    message: 'Er du sikker på, at du vil trække ordren tilbage?',
    cancel: 'afbestille',
    submit: 'bekræfte'
  },
  coins_assets: {
    title: 'Valutaaktiver',
    total_equity: 'Samlede egenkapital',
    equity: 'Kontoens egenkapital',
    number: 'Antal bedrifter',
    market_value: 'Nuværende vurdering'
  },
  lever_assets: {
    title: 'Gearede aktiver',
    total_equity: 'Samlede egenkapital',
    equity: 'Kontoens egenkapital',
    profit: 'Urealiseret fortjeneste og tab',
    risk: 'Risiko'
  },
  contract_assets: {
    title: 'Kontraktaktiver',
    total_equity: 'Samlede egenkapital',
    equity: 'Kontoens egenkapital',
    profit: 'Urealiseret fortjeneste og tab'
  },
  contract_menu: {
    contract: 'Kontraktdetaljer',
    lever: 'Udnyttelsesdetaljer',
    coins: 'Møntindeks',
    history: 'Fondskurs historie',
    coins_rules: [
      {
        value: '1D',
        name: 'På dagen'
      },
      {
        value: '1W',
        name: 'Nuværende uge'
      },
      {
        value: '1M',
        name: 'Indeværende måned'
      },
      {
        value: '1J',
        name: 'Nuværende sæson'
      }
    ],
    lever_info: 'Udnyt information',
    contract_info: 'Kontraktoplysninger'
  },
  contract_info: {
    bzj: 'Depositum',
    pt1: 'Afregningsvaluta',
    pt2: 'Kontrakt pålydende værdi',
    pt3: 'Minimum ændringspris',
    pt4: 'Kontraktperiode',
    pt5: 'Fund Rate Time',
    pt6: 'Markeret pris',
    pt7: 'Volumen af position',
    pt8: '24-timers handelsvolumen',
    pt9: 'Privilegium',
    pt10: 'Moden leveringsindtægt',
    pt11: 'Åbn positionsspredning',
    pt12: 'Leverings- og afregningsmekanisme',
    pt13: 'Transaktionsservicegebyr',
    pt14: 'Låseposition',
    pv5: 'Hver dag 08:00 16:00 24:00',
    pv10_1: 'Lang position: (gennemsnitlig lukkekurs-gennemsnitlig åbningspris) x antal',
    pv10_2: 'Kort position: (gennemsnitlig åbningskurs-gennemsnitlig lukkekurs) x antal',
    pv11: 'ingen',
    pv12: 'Automatisk levering ved udløb',
    pv13: 'ingen',
    pv14: 'ikke støtte'
  },
  metal_info: {
    pt1: 'Handel med sorter',
    pt2: 'Afregningsvaluta',
    pt3: 'Tilbudsenhed',
    pt4: 'Kontraktstørrelse',
    pt5: 'Minimumsændringsenhed',
    pt51: 'Minimum handelsenhed',
    pt52: 'Maksimal handelsenhed',
    pt6: 'Kontrakt tid',
    pt7: 'Handelstid',
    pt8: 'Provision',
    pt9: 'Børsnoteret børs',
    pt10: 'målspredning',
    pv4: 'bæredygtige',
    pv5: '24-timers handel fra mandag til fredag',
    pv51: '24 timers handel',
    pv6: 'Kontraktværdi 0,08 %',
    pv7: 'London Stock Exchange (LME)',
    pv71: 'New York Stock Exchange (NYMEX)',
    pv10: '{name} målspredning er {num} pips'
  },
  lever_info: {
    title: 'Udnyttelsesdetaljer',
    bei: 'gange',
    pt1: 'Afregningsvaluta',
    pt2: 'Kontrakt pålydende værdi',
    pt3: 'Minimum ændringspris',
    pt4: 'Kontraktperiode',
    pt5: 'Fund Rate Time',
    pt6: 'Markeret pris',
    pt7: 'Volumen af position',
    pt8: '24-timers handelsvolumen',
    pt9: 'Risikoreserve',
    pt10: 'Likvidationsgevinst',
    pt11: 'Åbn positionsspredning',
    pt12: 'Automatisk likvidationsmekanisme',
    pt13: 'Transaktionsservicegebyr',
    pt14: 'Låseposition',
    pv4: 'Bæredygtigt',
    pv5: 'Hver dag 08:00 16:00 24:00',
    pv10_1: 'Lang position: (gennemsnitlig lukkekurs-gennemsnitlig åbningspris) x antal',
    pv10_2: 'Kort position: (gennemsnitlig åbningskurs-gennemsnitlig lukkekurs) x antal',
    pv12: 'Reserverisikoforholdet når 80 %',
    pv13: 'Kontraktværdi',
    pv14: 'Ikke understøttet'
  },
  //币币信息
  coins_desc: require('./currency/en/coins.js').default,

  fun_history: {
    t1: 'Dato',
    t2: 'Estimeret finansieringssats',
    t3: 'Faktisk finansieringssats'
  },
  docs: {
    list: [
      {
        name: 'OTC handelsregler',
        key: 'otc'
      },
      {
        name: 'KYC og anti-hvidvaskaftaler',
        key: 'kyc'
      },
      {
        name: 'Retshåndhævende anmodningspolitik',
        key: 'lov'
      },
      {
        name: 'beskyttelse af privatlivets fred',
        key: 'privatliv'
      },
      {
        name: 'Brugeraftale',
        key: 'protokol'
      },
      {
        name: 'Risikomeddelelse',
        key: 'risiko'
      }
    ],
    otc: require('./currency/en/otc').default,
    kyc: require('./currency/en/kyc.js').default,
    law: require('./currency/en/law.js').default,
    privacy: require('./currency/en/privacy.js').default,
    protocol: require('./currency/en/protocol').default,
    risk: require('./currency/en/risk').default
  },
  asset: {
    title: 'Introduktion til digitale aktiver',
    list: 'Liste over digitale aktiver'
  },
  num_asset: require('./currency/en/asset').default,

  new_currency: {
    _title: 'Nyt Valutaabonnement',
    _all: 'Alle',
    _progress: 'I gang',
    _end: 'Over',
    _success: 'Udveksling lykkedes',
    _progress_str: 'Fremskridt',
    _total_str: 'Samlet udgivet',
    _date_time: 'Start- og slutdato',
    _submit: 'Indløs nu',
    _base_title: 'Grundlæggende oplysninger',
    _status: 'Status',
    _currency: 'Accepteret valuta',
    _symbol: 'Valuta symbol',
    _amount: 'Totalt ophævet forbud',
    _price: 'Startpris',
    _limit: 'Personlig købsgrænse',
    _limit_amount: 'Personlig købsgrænse',
    _limit_one: 'Personlig enkelt grænse nedre grænse',
    _no_limit: 'Ingen grænse',
    _position: 'Position',
    _begin_time: 'Starttidspunkt',
    _end_time: 'Sluttid',
    _redeemed: 'Beløb indløst',
    _surplus: 'overskud',
    _wallet: 'Pung',
    _have: 'Afholdt i øjeblikket',
    _input: 'input mængde',
    _choose: 'Vælg venligst',
    _new_wallet: 'Ny valutakonto',
    _new_price: 'Seneste pris',
    _lock: 'Låseperiode',
    _login: 'Vær venlig at logge ind',
    _no_data: 'Ingen data',

    _avg_price: 'gennemsnitspris'
  }
}
