import request from "@/api/request"

const api = {
  getChatList: 'chat/getChatList',
  getMessageList: 'chat/getMessageList',
  ajaxMsg: 'chat/ajaxMsg',
  sendMsg: 'chat/sendMsg',
  revoke: 'chat/revoke',
  deleteMsg: 'chat/deleteMsg',
  setTop: 'chat/setTop',
  searchText: 'chat/searchText',
}

export default {
  searchText(parameter) {
    return request({
      url: api.searchText,
      method: 'post',
      data: parameter
    })
  },
  setTop(parameter) {
    return request({
      url: api.setTop,
      method: 'post',
      data: parameter
    })
  },
  deleteMsg(parameter) {
    return request({
      url: api.deleteMsg,
      method: 'post',
      data: parameter
    })
  },
  revoke(parameter) {
    return request({
      url: api.revoke,
      method: 'post',
      data: parameter
    })
  },
  getChatList(parameter) {
    return request({
      url: api.getChatList,
      method: 'post',
      data: parameter
    })
  },
  getMessageList(parameter) {
    return request({
      url: api.getMessageList,
      method: 'post',
      data: parameter
    })
  },
  ajaxMsg(parameter) {
    return request({
      url: api.ajaxMsg,
      method: 'post',
      data: parameter
    })
  },
  sendMsg(parameter) {
    return request({
      url: api.sendMsg,
      method: 'post',
      data: parameter
    })
  }
}
