<template>
    <div class="route-box">
        <div class="theme--light" :style="'height:' + (screenHeight - 100) + 'px;'">
            <!-- <div>
                <div class="head-top">
                    <div class="head-box">
                        <div class="head-avatar">
                            <img src="../../static/imgs/t2.png" alt="" style="">
                        </div>
                        <div class="head-div">
                            <div>
                                <div class="head-name">
                                    <span>Hi {{ email }}</span>
                                    <div class="head-uid">UID:{{ uid }}</div>
                                </div>
                                <div class="head-desc">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
            <div>
                <div class="box-list">
                    <div class="box-div">
                        <!-- <div class="box-icon">
                                    <img src="../../static/imgs/btc.png" alt="">
                                </div> -->
                        <chat></chat>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Chat from '@/components/Chat.vue'
export default {
    name: "UserChat",
    components: { Chat },
    data() {
        return {

        }
    },
    created() {
    }
}
</script>

<style src="../../static/css/user.css" scoped type="text/css"></style>
