module.exports = {
  base: {
    title: 'STARTHESTAR',
    desc1: 'Re del contratto',
    desc2: 'Leader globale nel trading a contratto',
    d1: 'Fiducia',
    d2: 'Onestà',
    d3: 'Forza',
    more: 'Di più',
    top: "torna all'inizio",
    download: 'Scarica APP',
    zszh: 'Conto Reale',
    mnzh: 'Conto dimostrativo',
    content: 'Fai trading con un leader di mercato globale nel trading a contratto',
    lxkf: 'Contatta il servizio clienti',
    chxx: 'Ritira',
    zx: 'in linea'
  },
  demo: {
    title: 'registrazione riuscita!',
    title2: 'Conto analogico',
    submit: 'Confermare'
  },
  lang: {
    title: 'Italiano'
  },
  message: {
    invalid_email: 'E-mail non valido',
    invalid_password: 'Password non valida',
    invalid_new_password: 'Nuova password non valida',
    invalid_res_password: 'Conferma password non valida',
    invalid_email_code: 'Codice di verifica email non valido',
    password_inconsistent: 'Password incoerenti',
    invalid_invitation_code: 'Codice invito non valido',
    should_agree: 'Si prega di leggere e accettare i termini di registrazione',
    not_null: 'Questo campo non può essere vuoto',
    send_email: 'Riprova tra {:s}s',
    invalid_number: 'valore non valido',
    invalid_balance: 'Saldo non valido',
    invalid_rate: 'Si prega di selezionare più',
    invalid_rule: 'Scegli una regola',
    invalid_system_rule: 'Nessuna regola nel sistema',
    not_enough: 'Non abbastanza disponibile',
    price_high: 'Il prezzo deve essere superiore a {price}',
    price_low: 'Il prezzo deve essere inferiore a {price}',
    number_high: 'Il numero massimo di prelievi è {num}',
    number_low: "L'importo minimo di prelievo è {num}",
    copy_address: 'Copia indirizzo con successo {testo}',
    invalid_upload_front: "Si prega di caricare la foto anteriore del documento d'identità",
    invalid_upload_back: "Carica una foto del retro del tuo documento d'identità",
    invalid_upload_hand: "Si prega di caricare una foto documento d'identità",
    invalid_upload_name: 'Inserisci il nome del certificato',
    invalid_upload_card: 'Si prega di inserire il numero ID',
    upload_pic_type: 'Le immagini caricate possono essere solo in formato JPG, PNG',
    upload_pic_size: "La dimensione dell'immagine caricata non può superare i 10 MB"
  },
  index: {
    title2: 'Trading gratuito sul mercato globale 24 ore non-stop',
    desc2: 'Implementa la tua strategia di trading in un mercato estremamente liquido, con un volume di scambi nelle 24 ore di',
    desc2_1: "sull'intera piattaforma",
    list: {
      name: 'mercato',
      h24_price: 'Ultimo prezzo 24 ore su 24',
      h24_change: 'Cambio 24H',
      h24_high: 'Prezzo massimo 24 ore su 24',
      h24_low: '24H prezzo più basso',
      h24_vol: 'Volume 24H'
    },
    footer: {
      desc:
        "starthestar Blockchain Technology Ltd, con sede negli Stati Uniti, è una società di tecnologia blockchain leader a livello mondiale. La società ha ottenuto la licenza finanziaria internazionale US MSB. È una società di servizi di tecnologia blockchain conforme che può operare in conformità su servizi aziendali Blockchain su scala globale. Allo stesso tempo, i talenti del team tecnico di starthestar Blockchain Technology Ltd provengono dai migliori istituti di ricerca scientifica internazionali e dai team di sviluppo della tecnologia blockchain per proteggere completamente i fondi degli utenti e la sicurezza dell'account. Oltre al team tecnico, l'azienda dispone anche di un team blockchain professionale: il team di investimento quantitativo di risorse digitali AI, il team di valutazione del valore del progetto blockchain, il team di investimento di venture capital di risorse digitali, ecc., Hanno un layout globale per servire gli utenti blockchain globali. L'obiettivo e la visione dell'azienda è fornire servizi di transazione sicuri, stabili, premurosi e affidabili per gli appassionati di risorse digitali globali e creare un'azienda di tecnologia blockchain di fama mondiale professionale, digitale e internazionale.",
      item1: {
        tt: 'Esposizione del prodotto',
        pt1: 'Regole di trading OTC',
        pt2: 'Guida per principianti',
        pt3: 'Tariffa standard',
        pt4: 'Descrizione del contratto'
      },
      item2: {
        tt: 'Supporto',
        pt1: 'KYC e accordo antiriciclaggio',
        pt2: 'Politica di richiesta di applicazione',
        pt3: 'Introduzione alle risorse digitali'
      },
      item3: {
        tt: 'Termini',
        pt1: 'Protezione della privacy',
        pt2: 'Accordo per gli utenti',
        pt3: 'Notifica di rischio'
      },
      item4: {
        tt: 'Contattaci',
        pt1: 'Cooperazione commerciale',
        pt2: 'Cooperazione istituzionale',
        pt3: 'Applicazione OTC',
        pt4: 'E-mail del servizio clienti',
        pt5: 'Reclami e Suggerimenti',
        pt6: 'cooperazione mediatica'
      }
    },
    bk3: {
      title: 'Orgogliosi della forza innovativa di ricerca e sviluppo',
      desc: 'Esperienza di trading a contratto inimmaginabile, progettata per i trader finanziari',
      pt1: 'Ordine aperto stop profit e stop loss',
      pd1: "Gli ordini in sospeso non stanno alzati fino a tardi, preoccupati di non guardare l'ordine",
      pt2: 'Ordine di apertura inverso',
      pd2: 'Il mercato cambia improvvisamente, tieni il passo in un secondo',
      pt3: 'Supporto magazzino completo per magazzino',
      pd3: 'Fai quello che vuoi, fai quello che vuoi',
      pt4: "Liquidazione graduale dell'intera posizione",
      pd4: 'La liquidazione straniera prima equivale alla metà e cerca di aiutare gli investitori a ridurre le perdite'
    },
    bk5: {
      title: 'Otto vantaggi della piattaforma di trading a contratto',
      desc: "Ascoltiamo tutto il tempo, ottimizziamo e miglioriamo continuamente e creiamo un'esperienza di trading di prima classe per gli utenti di trading globali",
      pt1: 'Profondità del mercato',
      pd1: "La profondità del mercato delle prime tre bancarelle sulla piattaforma ha raggiunto l'ordine di milioni di dollari, superando di gran lunga la profondità media del mercato del settore di 10.000 e 100.000. Lo spread è piccolo e la transazione è veloce.",
      pt2: 'Transazione regolare',
      pd2: "Utilizzando un efficiente motore di transazione di corrispondenza per garantire un'esperienza di transazione fluida, può elaborare milioni di ordini al secondo e può supportare un funzionamento regolare e stabile con oltre 20 milioni di utenti online simultanei.",
      pt3: 'Prezzo adeguato',
      pd3:
        "'Prezzo contrassegnato' seleziona il prezzo di transazione in tempo reale delle tre principali borse mondiali come prezzo dell'indice per il calcolo della regressione, che previene efficacemente le perdite degli utenti causate da una manipolazione anomala dell'ultimo prezzo di transazione in un breve periodo di tempo.",
      pt4: 'Tecnologia auto-sviluppata',
      pd4: 'Il motore di matchmaking radicato nella finanza tradizionale, sviluppato da un team di esperti senior nel settore finanziario, e realizzato meticolosamente in ogni dettaglio per garantire il funzionamento sicuro, stabile ed efficiente della piattaforma di trading.',
      pt5: 'Massimo controllo del rischio',
      pd5:
        'Giudizio di secondo livello, crittografia multistrato, elevata sicurezza del sistema. La combinazione di portafogli caldi e freddi, misure di crittografia di livello militare, sicurezza delle transazioni delle risorse è garantita e il 100% garantisce la sicurezza delle risorse digitali degli utenti.',
      pt6: 'Sicurezza dei fondi',
      pd6:
        'Sistema di sicurezza a tre livelli, controllo di sicurezza completo. Sistema di portafoglio freddo deterministico gerarchico leader del settore, autocostruito, tutti gli utenti assegnano indirizzi di ricarica sono indirizzi di portafoglio freddo, la raccolta di fondi e il prelievo vengono completati tramite firme offline.',
      pt7: 'Potere Hardcore',
      pd7: "Decine di migliaia di riserve di BTC, geni dell'innovazione finanziaria e il top management team del mondo. starthestar promette che se gli errori dei dati dell'account e delle transazioni non sono causati da motivi di sistema, la piattaforma pagherà per intero in caso di errori!",
      pt8: 'Servizio di qualità',
      pd8: "Servizio clienti professionale 7 * 24 ore su 24, risposta rapida. Partendo dal pensiero del servizio, fornire un'esperienza di trading di prima classe per i clienti globali. L'ultima esperienza deriva dal concetto di servire i clienti."
    },
    bk6: {
      title: 'Operazioni globali di sicurezza e conformità',
      desc: 'Abbraccia la supervisione e aderisci sempre alle operazioni di conformità globale'
    },
    bk7: {
      title: 'Partner strategico ufficiale'
    },
    bk8: {
      title: 'Ha servito più di 100.000 utenti di trading globali',
      desc: 'Attualmente, gli utenti sono in tutti gli Stati Uniti, Giappone, Corea del Sud, Seychelles, Vietnam e altri paesi'
    },
    bk9: {
      title: 'Transazione fluida sempre e ovunque',
      pt1: 'Transazioni, depositi e prelievi di matchmaking in tempo reale arrivano in pochi secondi',
      pt2: 'Molteplici modalità di trading, leva fino a 125 volte',
      dt1: 'Download della versione iOS',
      dt2: 'Scarica la versione Android'
    },
    about: {
      title: 'Chi siamo',
      pt1: 'Concetto di piattaforma',
      pd1:
        "Lascia che le risorse globali circolino liberamente e crea una nuova esperienza contrattuale. X-xenogenetic, rappresenta i geni innovativi e fornisce un flusso costante di energia per l'innovazione del settore. M-motor, rappresenta l'obiettivo e continua ad andare avanti con l'obiettivo di guidare l'esperienza di trading a contratto. EX-exchange, per conto della piattaforma di trading, si impegna a creare un ecosistema di trading equo, trasparente ed efficiente con la filosofia operativa di servizio, ascolto e pensiero.",
      pt2: 'Valori fondamentali',
      pd2:
        'Ascoltiamo sempre, prestiamo attenzione e pensiamo al futuro e ci impegniamo a creare un ecosistema commerciale equo, trasparente ed efficiente. Fornire ai trader globali una migliore esperienza di trading, soddisfare le reali esigenze dei trader e promuovere lo sviluppo del mercato del trading di asset digitali.',
      pt3: 'Sfondo della squadra',
      pd3:
        'Il team fondatore è composto dai migliori investitori blockchain, dirigenti finanziari tradizionali, massimi esperti di risorse digitali e team tradizionali di tecnologia per la lavorazione dei metalli. I membri principali del team di ricerca e sviluppo provengono da Morgan Stanley, CICC, Hang Seng, Tencent, Noah Wealth.'
    }
  },
  start: {
    p_title: 'Transazione, inizia qui',
    p_desc: 'Inserisci il tuo indirizzo email nella seguente area per registrarti immediatamente',
    p_email: 'e-mail',
    p_btn: 'Creare'
  },
  download: {
    p_title: 'Scarica il client APP',
    p_desc: 'Transazione terminale multipiattaforma sempre e ovunque',
    p_btn: 'Scaricamento',
    p_scan_android: 'Applicazione Android',
    p_scan_ios: 'Applicazione iOS'
  },
  downpage: {
    t1: {
      p_title: 'Prestazioni di transazione fulminee',
      p_desc: 'Fornisci una varietà di tipi di transazione tra cui scegliere, la corrispondenza degli ordini è estremamente veloce, lo scambio flash con un clic e il trading è più conveniente'
    },
    t2: {
      p_title: 'Facile trasferimento di beni',
      p_desc: "Facile trasferimento delle risorse nell'account, panoramica in tempo reale delle risorse digitali, aumento del grafico a torta del rapporto valutario"
    },
    t3: {
      p_title: 'Pannello di trading personalizzato',
      p_desc: "Definisci liberamente il layout di trading in base alle diverse abitudini di trading e aumenta l'ingresso della linea K e della mappa di profondità"
    },
    t4: {
      p_title: "Gestione dell'integrazione dell'account",
      p_desc: 'Molteplici impostazioni di verifica della sicurezza, un account gestisce i conti C2C e i conti in valuta'
    }
  },
  login: {
    login: 'Accedi',
    title: 'Login utente',
    desc1: 'Accedi al sito ufficiale di',
    desc2: '',
    email: 'Indirizzo email/numero di telefono',
    email2: 'Indirizzo email',
    email3: 'numero di telefono',
    email_desc: 'Inserisci la tua email',
    phone_desc: 'Inserisci il numero di telefono',
    password: "parola d'ordine",
    password_desc: 'Inserisci la password',
    forgot_password: 'Ha dimenticato la password',
    no_account: 'Nessun account ancora'
  },
  signup: {
    signup: 'Registrati',
    title: 'Registrazione Utente',
    desc1: 'Benvenuto per accedere',
    desc2: 'Sito ufficiale',
    invitation_code: 'codice invito',
    invitation_desc: 'Inserisci il codice di invito',
    accept: 'Accettare',
    service: 'Accordo per gli utenti',
    and: 'E',
    policy: 'Informativa sulla protezione della privacy',
    have_account: 'Hai già un account'
  },
  retrieve: {
    ptitle: 'recupera password',
    pemail_code: 'Codice di verifica e-mail',
    pset: 'Impostare la password',
    set_title: 'Impostare la password',
    set_desc: 'Stai reimpostando la tua password, completa le seguenti impostazioni',
    new_password: 'nuova password',
    password_desc: 'Inserisci una nuova password',
    res_passowrd: 'Conferma password',
    res_passowrd_desc: 'Inserisci la password di conferma'
  },
  valid_email: {
    title: 'Verifica della registrazione',
    desc: 'Stai registrando un account, completa la seguente verifica',
    email_code: 'Codice di verifica e-mail',
    email_desc: 'Inserisci il codice di verifica',
    get_code: 'Ottieni il codice di verifica'
  },
  ihead: {
    begin_trade: 'Inizia a fare trading',
    helper: 'Aiuto per principianti',
    text: 'Centro documenti',
    new_currency: 'Abbonamento nuova valuta'
  },
  uhead: {
    title1: 'Merce',
    title2: 'Forex internazionale',
    title3: 'Indice internazionale',
    currency: 'Valuta',
    lever: 'Transazione con leva finanziaria',
    coins: 'Transazione valutaria',
    contract: 'Transazione contrattuale',
    metal: 'Metallo',
    energy: 'Energia',
    contract_info: 'Informazioni sul contratto',
    helper: 'Aiuto per principianti',
    text: 'Centro documenti',
    assets: 'Risorse',
    u_wallet: 'Il mio portafoglio',
    u_contract: 'Conto contratto',
    u_lever: 'Conto di leva',
    u_account: 'Informazioni account',
    u_logout: 'Disconnettersi'
  },
  news: {
    label: 'Notizia',
    update: 'Aggiornato il'
  },
  guide: {
    label: 'Guida per principianti',
    list: [
      {
        ptitle: 'Come registrarsi a starthestar',
        pkey: 'k1'
      },
      {
        ptitle: "Autenticazione dell'identità",
        pkey: 'k2'
      },
      {
        ptitle: 'Password di accesso e impostazioni della password di transazione',
        pkey: 'k3'
      },
      {
        ptitle: 'Deposita monete',
        pkey: 'k4'
      },
      {
        ptitle: 'Processo di transazione delle monete',
        pkey: 'k5'
      },
      {
        ptitle: 'Ritiro',
        pkey: 'k6'
      },
      {
        ptitle: "Download dell'APP mobile ",
        pkey: 'k7'
      }
    ],
    content: {
      k1: require('./currency/en/guide/k1.js').default,
      k2: require('./currency/en/guide/k2.js').default,
      k3: require('./currency/en/guide/k3.js').default,
      k4: require('./currency/en/guide/k4.js').default,
      k5: require('./currency/en/guide/k5.js').default,
      k6: require('./currency/en/guide/k6.js').default,
      k7: require('./currency/en/guide/k7.js').default
    }
  },
  kline: {
    h24_high: '24H di altezza',
    h24_low: '24H basso',
    h24_vol: 'Volume 24 ore',
    commission: 'Commissione attuale',
    record: 'Registro delle transazioni',
    commission_list: 'Elenco commissioni',
    deal_list: 'Ultimo affare',
    price: 'Prezzo',
    xprice: 'Profitto diffuso',
    bzj: 'Margine',
    number: 'quantità',
    total: 'Totale',
    time: 'Tempo',
    currency: 'Posizioni',
    type: 'Tipo',
    direction: 'Direzione',
    multiple: 'Leva',
    fee: 'Commissione',
    amount: 'Totale',
    status: 'Stato',
    operate: 'Operazione',
    buy: 'Compra',
    sell: 'Vendi',
    pending: 'Ordine in attesa',
    trade_success: 'Negoziazione di successo',
    retract: 'Annulla',
    profit: 'Avere un profitto',
    loss: 'Arrestare la perdita',
    all: 'Tutto',
    all_currency: 'Tutte le valute',
    new_price: 'Ultimo prezzo',
    change: 'Alzati e scendi',
    protocol: 'protocollo',
    _status1: 'Revisione',
    _status2: 'Riuscito',
    _status3: 'Respinto'
  },
  coins: {
    title: 'Transazione valutaria',
    market: 'prezzo di mercato',
    limit: 'Prezzo limite',
    profit: 'Avere un profitto',
    loss: 'Arrestare la perdita',
    price: 'Prezzo',
    current: 'Attuale',
    price_desc: 'Inserisci il prezzo',
    number: 'quantità',
    number_desc: 'Si prega di inserire la quantità',
    available: 'Disponibile',
    half: 'mezzo magazzino',
    all: 'Magazzino pieno',
    fee: 'Commissione',
    amount: 'Importo della transazione',
    buy: 'Compra',
    sell: 'Vendi',
    current_price: 'Attuale',
    open_price: 'Aperto oggi',
    high: 'Alto',
    low: 'Basso',
    yesterday: 'Chiudi ieri',
    today_zf: 'Cambio di prezzo',
    now_cc: 'Posizione',
    y_cc: 'Posizione Y',
    jsj: 'Prezzo di liquidazione',
    zrjsj: 'Y Prezzo di liquidazione'
  },
  lever: {
    title: 'Transazione con leva finanziaria',
    multiple: 'Leva',
    multiple_desc: 'Seleziona un multiplo',
    double: 'Doppio',
    buy: 'Compra (su)',
    sell: 'Vendi (giù)'
  },
  contract: {
    title: 'Transazione contrattuale',
    time: 'Tempo',
    time_desc: 'Si prega di selezionare un orario',
    minute: 'minuto',
    second: 'Secondo',
    buy: 'Compra (su)',
    sell: 'Vendi (giù)',
    rate: 'Rapporto di royalty'
  },
  wallet: {
    title: 'I miei beni',
    valuation: 'Valutazione patrimoniale totale',
    my_wallet: 'Il mio portafoglio',
    my_trade: 'Conto commerciale',
    my_transfer: 'Registro dei prelievi',
    my_deposit: 'Record di deposito di monete',
    cbdz: 'indirizzo di deposito',
    cbsl: 'Quantità',
    deposit: 'Deposita monete',
    withdrawal: 'Ritiro',
    search: 'Ricerca',
    currency: 'Posizioni',
    protocol: 'Protocollo',
    liquidation: 'Chiudi posizione',
    liquidation_title: 'Liquidazione rapida',
    trade_pwd: "Parola d'ordine commerciale",
    enter_trade_pwd: 'Inserisci la password di trading',
    submit_liquidation: 'Chiudi immediatamente la posizione',
    amount: 'Totale',
    available: 'Disponibile',
    freeze: 'Congelare',
    market: 'valutazione',
    ljsy: 'Reddito cumulativo',
    zrsy: 'I guadagni di ieri',
    operate: 'Operazione',
    countdown: 'Conto alla rovescia',
    lever_title: 'Conto di leva',
    contract_title: 'Conto contratto',
    direction: 'Direzione',
    bond: 'Margine',
    buy_rate: 'multiplo',
    profit: 'Profitti e perdite',
    profit1: 'Profitti e perdite',
    buy: 'Compra',
    sell: 'Vendi',
    many: 'Compra',
    empty: 'Vendi',
    contract_time: 'Tempo di contratto',
    premium: 'Privilegio',
    minute: 'minuto',
    address: 'Indirizzo',
    address_desc: "Inserisci l'indirizzo",
    number: 'quantità',
    number_desc: 'Inserisci il numero di prelievi',
    copy: 'copia',
    confirm: 'OK',
    all: 'Tutto',
    fee: 'Commissione',
    actual: 'Arrivo effettivo',
    premark: 'Attenzione',
    text_1: 'Questo indirizzo accetta solo risorse {name}, per favore non ricaricare altre valute, altrimenti le risorse non verranno recuperate',
    text_2: 'La commissione del minatore di {name} è: valore di mercato corrente {rate}%/transazione, standard minimo: {min}{name}/transazione',
    text_3: "Si prega di confermare che le informazioni sull'indirizzo di ricezione sono corrette prima di trasferire i fondi. Una volta trasferiti, i beni non possono essere ritirati.",
    text_4: 'La tariffa mineraria di {name} è: {val} {name}/pen'
  },
  record: {
    title: 'Registro fondi',
    type: 'Tipo',
    all_currency: 'Tutte le valute',
    all_type: 'Tutti i tipi',
    select: 'Domanda',
    clear: 'Filtro pulito',
    receive: 'Depositare',
    transfer: 'Ritiro',
    buy: 'Compra',
    sell: 'Vendi',
    normal: 'Ordinario',
    pending: 'Limite di arresto',
    wallet: 'Dettagli sulla posizione',
    zzzqy: 'Bilancio',
    zqy: 'Margine libero',
    fdyk: 'P/L fluttuante',
    fxl: 'Tasso di rischio'
  },
  user: {
    account: "Autenticazione dell'account",
    account_desc: "Deposito di credito, transazione e altre funzioni devono prima superare la verifica dell'account",
    password: 'Password per il login',
    password_desc: "Utilizzato per proteggere la sicurezza dell'account",
    modify: 'Modificare',
    set: 'Impostato',
    find_paypwd: 'recupera password',
    find_pay_pwd: 'Recupera la password di pagamento',
    email: 'E-mail',
    enter_email: 'Inserisci la tua email',
    email_code: 'Codice di verifica',
    enter_email_code: 'Inserisci il codice di verifica e-mail',
    send: 'Inviare',
    pay_password: 'Password di pagamento',
    pay_password_desc: 'Utilizzato per proteggere la sicurezza degli asset durante il trading',
    not_certified: 'Non certificato',
    certification: 'Certificazione in corso',
    verified: 'Verificato',
    rejected: 'respinto',
    update_pwd: 'Modifica password di accesso',
    update_pay_pwd: 'Modifica la password di pagamento',
    set_pay_pwd: 'Imposta password di pagamento',
    old_pwd: "Parola d'ordine originale",
    enter_old_pwd: 'Inserisci la password originale',
    new_pwd: 'nuova password',
    enter_new_pwd: 'Inserisci una nuova password',
    res_pwd: 'Conferma password',
    enter_res_pwd: 'Inserisci la password di conferma',
    submit: 'Invia'
  },
  certified: {
    title: "Autenticazione dell'account",
    front: "Carta d'identità anteriore",
    back: "Il retro della carta d'identità",
    hand: "Carta d'identità da tenere in mano",
    name: 'Nome',
    name_desc: 'Inserisci un nome',
    card: 'numero di identificazione',
    card_desc: 'Si prega di inserire il numero ID',
    submit: 'Invia'
  },
  trade: {
    title: "parola d'ordine della transazione",
    enter_password: 'Inserisci la password della transazione',
    cancel: 'Annulla',
    submit: 'invia'
  },
  revoke: {
    title: 'Messaggio tempestivo',
    message: "Sei sicuro di voler ritirare l'ordine?",
    cancel: 'Annulla',
    submit: 'Confermare'
  },
  coins_assets: {
    title: 'Attività valutarie',
    total_equity: 'Equità totale',
    equity: 'Equità del conto',
    number: 'quantità di partecipazioni',
    market_value: 'Valutazione attuale'
  },
  lever_assets: {
    title: 'Asset con leva finanziaria',
    total_equity: 'Equità totale',
    equity: 'Equità del conto',
    profit: 'Profitti e perdite non realizzati',
    risk: 'Rischio'
  },
  contract_assets: {
    title: 'Beni contrattuali',
    total_equity: 'Equità totale',
    equity: 'Equità del conto',
    profit: 'Profitti e perdite non realizzati'
  },
  contract_menu: {
    contract: 'Dettagli del contratto',
    lever: 'Dettagli sulla leva',
    coins: 'Indice delle monete',
    history: 'Cronologia dei tassi di fondo',
    coins_rules: [
      {
        value: '1D',
        name: 'Nel giorno'
      },
      {
        value: '1W',
        name: 'Settimana corrente'
      },
      {
        value: '1M',
        name: 'Corrente mese'
      },
      {
        value: '1J',
        name: 'Stagione attuale'
      }
    ],
    lever_info: 'Sfrutta le informazioni',
    contract_info: 'Informazioni sul contratto'
  },
  contract_info: {
    bzj: 'Deposito di sicurezza',
    pt1: 'Valuta di regolamento',
    pt2: 'Valore nominale del contratto',
    pt3: 'Prezzo di cambio minimo',
    pt4: 'Durata del contratto',
    pt5: 'Tempo del tasso del fondo',
    pt6: 'Prezzo Contrassegnato',
    pt7: 'Volume di posizione',
    pt8: 'Volume di scambi nelle 24 ore',
    pt9: 'Privilegio',
    pt10: 'Scadenza Consegna Reddito',
    pt11: 'Spread posizione aperta',
    pt12: 'Meccanismo di consegna e regolamento',
    pt13: 'Commissione per il servizio di transazione',
    pt14: 'Posizione di blocco',
    pv5: 'Tutti i giorni 08:00 16:00 24:00',
    pv10_1: 'Posizione lunga: (prezzo medio di chiusura-prezzo medio di apertura) x quantità',
    pv10_2: 'Posizione corta: (prezzo medio di apertura-prezzo medio di chiusura) x quantità',
    pv11: 'NO',
    pv12: 'Consegna automatica alla scadenza',
    pv13: 'NO',
    pv14: 'non supportare'
  },
  metal_info: {
    pt1: 'Varietà commerciali',
    pt2: 'Valuta di regolamento',
    pt3: 'Unità di quotazione',
    pt4: 'Dimensioni del contratto',
    pt5: 'Unità minima di cambio',
    pt51: 'Unità minima di negoziazione',
    pt52: 'Unità di negoziazione massima',
    pt6: 'Tempo di contratto',
    pt7: 'Tempo di negoziazione',
    pt8: 'Commissione',
    pt9: 'Scambio quotato',
    pt10: 'diffusione bersaglio',
    pv4: 'sostenibile',
    pv5: 'Trading 24 ore su 24 dal lunedì al venerdì',
    pv51: 'Negoziazione 24 ore su 24',
    pv6: 'Valore del contratto0,08%',
    pv7: 'Borsa di Londra (LME)',
    pv71: 'Borsa di New York (NYMEX)',
    pv10: '{name} lo spread target è di {num} pip'
  },
  lever_info: {
    title: 'Dettagli sulla leva',
    bei: 'volte',
    pt1: 'Valuta di regolamento',
    pt2: 'Valore nominale del contratto',
    pt3: 'Prezzo di cambio minimo',
    pt4: 'Durata del contratto',
    pt5: 'Tempo del tasso del fondo',
    pt6: 'Prezzo Contrassegnato',
    pt7: 'Volume di posizione',
    pt8: 'Volume di scambi nelle 24 ore',
    pt9: 'Riserva di rischio',
    pt10: 'Profitto di liquidazione',
    pt11: 'Spread posizione aperta',
    pt12: 'Meccanismo di liquidazione automatica',
    pt13: 'Commissione per il servizio di transazione',
    pt14: 'Posizione di blocco',
    pv4: 'Sostenibile',
    pv5: 'Tutti i giorni 08:00 16:00 24:00',
    pv10_1: 'Posizione lunga: (prezzo medio di chiusura-prezzo medio di apertura) x quantità',
    pv10_2: 'Posizione corta: (prezzo medio di apertura-prezzo medio di chiusura) x quantità',
    pv12: "Il coefficiente di rischio di riservazione raggiunge l'80%",
    pv13: 'Valore del contratto',
    pv14: 'Non supportato'
  },
  //币币信息
  coins_desc: require('./currency/en/coins.js').default,

  fun_history: {
    t1: 'Data',
    t2: 'Tasso di finanziamento stimato',
    t3: 'Tasso di finanziamento effettivo'
  },
  docs: {
    list: [
      {
        name: 'Regole di trading OTC',
        key: 'ecc'
      },
      {
        name: 'Accordi KYC e antiriciclaggio',
        key: 'kyc'
      },
      {
        name: "Politica sulle richieste delle forze dell'ordine",
        key: 'legge'
      },
      {
        name: 'Protezione della privacy',
        key: 'intimità'
      },
      {
        name: 'Accordo per gli utenti',
        key: 'protocollo'
      },
      {
        name: 'Notifica di rischio',
        key: 'rischio'
      }
    ],
    otc: require('./currency/en/otc').default,
    kyc: require('./currency/en/kyc.js').default,
    law: require('./currency/en/law.js').default,
    privacy: require('./currency/en/privacy.js').default,
    protocol: require('./currency/en/protocol').default,
    risk: require('./currency/en/risk').default
  },
  asset: {
    title: 'Introduzione alle risorse digitali',
    list: 'Elenco delle risorse digitali'
  },
  num_asset: require('./currency/en/asset').default,

  new_currency: {
    _title: 'Abbonamento nuova valuta',
    _all: 'Tutto',
    _progress: 'In corso',
    _end: 'Sopra',
    _success: 'Scambio riuscito',
    _progress_str: 'Progresso',
    _total_str: 'Rilasciato totale',
    _date_time: 'Data di inizio e fine',
    _submit: 'Riscattare ora',
    _base_title: 'Informazioni di base',
    _status: 'Stato',
    _currency: 'Valuta accettata',
    _symbol: 'Simbolo di valuta',
    _amount: 'Divieto totale revocato',
    _price: 'Prezzo iniziale',
    _limit: 'Limite di acquisto personale',
    _limit_amount: 'Limite di acquisto personale',
    _limit_one: 'Limite minimo personale limite inferiore',
    _no_limit: 'Senza limiti',
    _position: 'Posizione',
    _begin_time: 'Ora di inizio',
    _end_time: 'Tempo scaduto',
    _redeemed: 'Importo riscattato',
    _surplus: 'eccedenza',
    _wallet: 'Portafoglio',
    _have: 'Attualmente detenuto',
    _input: 'quantità in ingresso',
    _choose: 'Si prega di scegliere',
    _new_wallet: 'Nuovo conto in valuta',
    _new_price: 'Ultimo prezzo',
    _lock: 'Periodo di blocco',
    _login: 'Accedere prego',
    _no_data: 'Nessun dato',

    _avg_price: 'prezzo medio'
  }
}
