<template>
  <!-- 头部 -->
  <header class="x-2-cl common-header h-2-bg h-3-bd top-common-header" id="common-header">
    <!-- 图标 -->
    <box-svg></box-svg>

    <ul class="common-header-linkList" id="common-header-linkList">
      <!-- LOGO -->
      <li class="linkList-logo-li">
        <a class="linkList-logo" href="#/">
          <img src="../../static/imgs/logo52.png" />
        </a>
      </li>
      <!-- 菜单 -->
      <li class="header-nav-ifl">
        <svg aria-hidden="true" class="icon icon-18">
          <use xlink:href="#icon-b_1"></use>
        </svg>
        <svg aria-hidden="true" class="icon icon-18 hover">
          <use xlink:href="#icon-b_1_1"></use>
        </svg>
        <div class="h-1-bg header-sub-nav">
          <ul class="x-2-cl header-user-text" id="header-cus-nav">
            <!-- <li class="x-2-cl g-3-cl-h nav-li">
              <router-link :to="{ name: 'Trading', query: { i: 1 } }" class="h-2-cl g-3-cl-h nav-a">{{
                $t('uhead.title1') }}</router-link>
            </li> -->
            <!-- <li class="x-2-cl g-3-cl-h nav-li">
              <router-link :to="{ name: 'Trading', query: { i: 2 } }" class="h-2-cl g-3-cl-h nav-a">{{
                $t('uhead.title2') }}</router-link>
            </li> -->
            <!-- <li class="x-2-cl g-3-cl-h nav-li">
              <router-link :to="{ name: 'Trading', query: { i: 3 } }" class="h-2-cl g-3-cl-h nav-a">{{
                $t('uhead.title3') }}</router-link>
            </li> -->
            <li class="header-navEven">
              <router-link :to="{ name: 'NewCurrency' }" class="h-2-cl g-3-cl-h nav-a">{{ $t('ihead.new_currency')
                }}</router-link>
            </li>
            <li class="x-2-cl g-3-cl-h nav-li">
              <router-link :to="{ name: 'Coins' }" class="h-2-cl g-3-cl-h nav-a">{{ $t('uhead.coins') }}</router-link>
            </li>
            <li class="x-2-cl g-3-cl-h nav-li">
              <router-link :to="{ name: 'Lever' }" class="h-2-cl g-3-cl-h nav-a" href="#/lever">{{ $t('uhead.contract')
                }}</router-link>
            </li>
            <!-- <li class="x-2-cl g-3-cl-h nav-li">
							<router-link :to="{ name: 'Contract' }" class="h-2-cl g-3-cl-h nav-a" href="#/contract">{{ $t('uhead.lever') }}</router-link>
						</li> -->
          </ul>
        </div>
      </li>
      <li class="header-navEven">
        <router-link :to="{ name: 'Coins' }" class="h-2-cl g-3-cl-h nav-a">{{ $t('ihead.begin_trade') }}</router-link>
      </li>
      <li class="header-navEven">
        <router-link :to="{ name: 'Guide', query: { key: 'k1' } }" class="h-2-cl g-3-cl-h nav-a">{{ $t('ihead.helper')
          }}</router-link>
      </li>
      <!-- 菜单 -->
      <!-- <li class="header-nav-ifl" style="width:auto">
            <a href="javascript:" class="h-2-cl g-3-cl-h nav-a">About Us</a>
            <div class="h-1-bg header-sub-nav">
                <ul class="x-2-cl header-user-text" id="header-cus-nav">
                    <li class="x-2-cl g-3-cl-h nav-li">
                        <router-link :to="{name:'Docs',query:{docs:'about1'}}" class="h-2-cl g-3-cl-h nav-a">Financial Strength & Security</router-link>
                    </li>
                    <li class="x-2-cl g-3-cl-h nav-li">
                        <router-link :to="{name:'Docs',query:{docs:'about2'}}" class="h-2-cl g-3-cl-h nav-a">Regulation and Funds Safety</router-link>
                    </li>

                </ul>
            </div>
        </li> -->
    </ul>

    <div class="common-header-optionList">
      <!-- 登录注册按钮 -->
      <div class="h-2-bd header-guide unlogin-set" v-if="token == ''">
        <button @click="() => {
          this.$router.push('/login')
        }
          " class="common-button h-2-cl common-button-text-kind header-login-button"
          style="height:30px;padding-left:22px;padding-right:22px;margin-top:0">
          <div class="common-button-slot">{{ $t('login.login') }}</div>
        </button>
        <button @click="() => {
          this.$router.push('/signup')
        }
          " class="common-button h-2-cl common-button-hollow-big header-reg-button u-8-bd"
          style="height:30px;padding-left:20px;padding-right:20px;margin-top:0">
          <div class="common-button-slot">{{ $t('signup.signup') }}</div>
        </button>
      </div>

      <!-- 菜单 -->
      <!-- <div class="login-set header-isLogin" style="display:none">
                <div class="header-isLogin-navEven">
                    <div class="header-isLogin-navEven-title">资产</div>
                    <ul class="h-1-bg lang-list">
                        <li class="b-2-cl" data-link="/assets/exchangeAccount">币币账户</li>
                        <li class="b-2-cl" data-link="https://futures.bbkx.plus/zh_CN//assets/coAccount">合约账户</li>
                    </ul>
                </div>
                <div class="header-isLogin-navEven">
                    <div class="header-isLogin-navEven-title">订单</div>
                    <ul class="h-1-bg lang-list">
                        <li class="b-2-cl" data-link="/order/exchangeOrder">币币订单</li>
                        <li class="b-2-cl" data-link="https://futures.bbkx.plus/zh_CN//order/coOrder">合约订单</li>
                        <li class="b-2-cl" data-link="/order/toLoanOrder">借贷订单</li>
                    </ul>
                </div>
            </div> -->
      <!-- <Custom></Custom> -->
      <!-- 下载app -->
      <div class="h-2-bd header-user appdownLoad-coin">
        <div class="header-user-apphove">
          <div class="header-user-icon icon-info">
            <svg aria-hidden="true" class="icon icon-18">
              <use xlink:href="#icon-b_33"></use>
            </svg>
          </div>
          <div class="header-user-icon icon-active">
            <svg aria-hidden="true" class="icon icon-18">
              <use xlink:href="#icon-b_33_1"></use>
            </svg>
          </div>
        </div>
        <div class="h-1-bg header-user-appdown">
          <div class="code-box" style="margin-right: 10px;">
            <div class="download-options-code">
              <img style="width: 100%;height: 100%;top: 0;left: 0;" src="../../static/imgs/download_app1.png" alt="" />
            </div>
            <p class="download-options-codeText" style="white-space: nowrap;">
              {{ $t('download.p_scan_android') }}
            </p>
          </div>
          <div class="code-box">
            <div class="download-options-code">
              <img style="width: 100%;height: 100%;top: 0;left: 0;" src="../../static/imgs/download_app2.png" alt="" />
            </div>
            <p class="download-options-codeText" style="white-space: nowrap;">
              {{ $t('download.p_scan_ios') }}
            </p>
          </div>
          <!-- <div class="download-options-buttons">
                    <button class="common-button common-button-solid download-button u-16-cl u-8-bg" style="height:40px;line-height:40px;padding-left:20px;padding-right:20px;margin-top:0">
                        <div class="common-button-slot"><svg aria-hidden="true" class="icon icon-18">
                                <use xlink:href="#icon-b_13"></use>
                            </svg> &nbsp; iPhone</div>
                        <div class="solidBc bghover u-14-bg"></div>
                        <div class="solidBc bgactive u-15-bg"></div>
                    </button>
                    <button class="common-button common-button-solid download-button u-16-cl u-8-bg" style="height:40px;line-height:40px;padding-left:20px;padding-right:20px;margin-top:0">
                        <div class="common-button-slot"><svg aria-hidden="true" class="icon icon-18">
                                <use xlink:href="#icon-b_14"></use>
                            </svg> &nbsp; Android</div>
                        <div class="solidBc bghover u-14-bg"></div>
                        <div class="solidBc bgactive u-15-bg"></div>
                    </button>
                </div> -->
        </div>
      </div>

      <!-- 主题 -->
      <div class="h-2-bd header-user" id="color-set" style="display:none">
        <div class="header-user-icon icon-info">
          <svg aria-hidden="true" class="icon icon-18">
            <use xlink:href="#icon-b_2"></use>
          </svg>
        </div>
        <div class="header-user-icon icon-active">
          <svg aria-hidden="true" class="icon icon-18">
            <use xlink:href="#icon-b_2_1"></use>
          </svg>
        </div>
      </div>

      <!-- 消息 -->
      <div class="h-2-bd header-user login-set" style="display:none">
        <div class="messageCount" style="display:none" id="messageMore"></div>
        <div class="header-user-icon icon-info goMessage">
          <svg aria-hidden="true" class="icon icon-18">
            <use xlink:href="#icon-b_3"></use>
          </svg>
        </div>
        <div class="header-user-icon icon-active goMessage">
          <svg aria-hidden="true" class="icon icon-18">
            <use xlink:href="#icon-b_3_1"></use>
          </svg>
        </div>
        <div class="h-1-bg goMessage header-user-message">
          <ul class="header-user-text"></ul>
          <div class="x-3-cl h-4-bg header-user-out">查看更多</div>
        </div>
      </div>

      <!-- 用户 -->
      <div class="h-2-bd header-user login-set" v-if="token != ''">
        <el-badge :value="1" :hidden="msg.msg == 0" :is-dot="true" class="item">
          <div style="width: 40px;height: 40px;">
            <div class="header-user-icon icon-info goPersonal">
              <svg aria-hidden="true" class="icon icon-18">
                <use xlink:href="#icon-b_4"></use>
              </svg>
            </div>
            <div class="header-user-icon icon-active goPersonal">
              <svg aria-hidden="true" class="icon icon-18">
                <use xlink:href="#icon-b_4_1"></use>
              </svg>
            </div>
          </div>
        </el-badge>
        <div class="h-1-bg header-user-market">
          <ul class="header-user-text">
            <li class="x-3-cl goPersonal userText">{{ email }}</li>
            <li class="x-2-cl userStatus">UID: {{ uid }}</li>
          </ul>
          <div class="header-user-out logout" @click="() => {
            this.$router.push({ name: 'Wallet', query: { type: 1 } })
          }
            ">
            {{ $t('uhead.u_wallet') }}
          </div>
          <div class="header-user-out logout" @click="() => {
            this.$router.push({ name: 'Wallet', query: { type: 2 } })
          }
            ">
            {{ $t('uhead.u_lever') }}
          </div>
          <div class="header-user-out logout" @click="() => {
            this.$router.push({ name: 'Wallet', query: { type: 3 } })
          }
            ">
            {{ $t('uhead.u_contract') }}
          </div>
          <div class="header-user-out logout" @click="() => {
            this.$router.push({ name: 'Wallet', query: { type: 4 } })
          }
            ">
            {{ $t('new_currency._new_wallet') }}
          </div>
          <div class="header-user-out logout">
            <el-badge :value="msg.msg" :hidden="msg.msg == 0" :is-dot="false" style="width: 100%;">
              <div class="" @click="() => {
                this.$router.push({ name: 'UserChat' })
              }
                ">
                {{ $t('base.lxkf') }}
              </div>
            </el-badge>
          </div>
          <div class="header-user-out logout" @click="() => {
            this.$router.push({ name: 'User' })
          }
            ">
            {{ $t('uhead.u_account') }}
          </div>
          <div class="header-user-out logout" @click="Logout">
            {{ $t('uhead.u_logout') }}
          </div>
        </div>
      </div>


      <!-- 切换语言 -->
      <div class="x-2-cl h-2-bd header-lang">
        <div class="show-lang">
          Language
          <svg aria-hidden="true" class="icon icon-14">
            <use xlink:href="#icon-a_13"></use>
          </svg>
        </div>
        <ul class="h-1-bg lang-list b-2-cl">
          <li :class="lang == 'en-us' ? 'x-3-cl h-4-bg' : ''" @click="language('en-us')">
            English
          </li>
          <li :class="lang == 'no-no' ? 'x-3-cl h-4-bg' : ''" @click="language('no-no')">
            Norsk
          </li>
          <li :class="lang == 'it-it' ? 'x-3-cl h-4-bg' : ''" @click="language('it-it')">
            Italiano
          </li>
          <li :class="lang == 'fr-fr' ? 'x-3-cl h-4-bg' : ''" @click="language('fr-fr')">
            Français
          </li>
          <li :class="lang == 'es-es' ? 'x-3-cl h-4-bg' : ''" @click="language('es-es')">
            Español
          </li>
          <li :class="lang == 'de-de' ? 'x-3-cl h-4-bg' : ''" @click="language('de-de')">
            Deutsch
          </li>
          <li :class="lang == 'nl-nl' ? 'x-3-cl h-4-bg' : ''" @click="language('nl-nl')">
            Nederlands
          </li>
          <li :class="lang == 'fi-fi' ? 'x-3-cl h-4-bg' : ''" @click="language('fi-fi')">
            Suomalainen
          </li>
          <li :class="lang == 'da-dk' ? 'x-3-cl h-4-bg' : ''" @click="language('da-dk')">
            dansk
          </li>
          <li :class="lang == 'sv-se' ? 'x-3-cl h-4-bg' : ''" @click="language('sv-se')">
            svenska
          </li>
          <li :class="lang == 'zh-cn' ? 'x-3-cl h-4-bg' : ''" @click="language('zh-cn')">简体中文</li>
          <!-- <li :class="(lang == 'ja'?'x-3-cl h-4-bg':'')" @click="language('ja')">日本語</li>
                <li :class="(lang == 'ko'?'x-3-cl h-4-bg':'')" @click="language('ko')">한국어</li> -->
        </ul>
      </div>
    </div>
    <!-- 主题 -->
    <!-- <ul id="colorList" style="display:none">
            <li data-id="1" data-checked="checked" class="checked">
                <section class="common-redio"><svg aria-hidden="true" class="icon icon-16 uncheck">
                        <use xlink:href="#icon-c_14"></use>
                    </svg> <svg aria-hidden="true" class="icon icon-16 check">
                        <use xlink:href="#icon-c_14_1"></use>
                    </svg></section><span class="b-1-cl">深色版</span>
            </li>
            <li data-id="2" data-checked="">
                <section class="common-redio"><svg aria-hidden="true" class="icon icon-16 uncheck">
                        <use xlink:href="#icon-c_14"></use>
                    </svg> <svg aria-hidden="true" class="icon icon-16 check">
                        <use xlink:href="#icon-c_14_1"></use>
                    </svg></section><span class="b-1-cl">浅色版</span>
            </li>
        </ul> -->
  </header>
</template>

<script>
import { setup, getLanguage } from '@/utils/vue-i18n'
// import Custom from "@/components/head/Custom.vue"
import BoxSvg from './BoxSvg.vue'
import socket from '../utils/socket';
export default {
  components: {
    BoxSvg,
    // Custom
  },
  data() {
    return {
      lang: '',
      //登录标识
      token: '',
      email: '',
      uid: '',

      msg: { msg: 0, agent_id: 0 }
    }
  },
  created() {
    //检查登录
    this.checkToken()
    this.setSocket()

    //默认语言
    this.lang = localStorage.getItem('lang')
  },
  methods: {
    setSocket() {
      console.log('发送订阅')
      let _this = this
      let isSend = false
      if (socket.getReadyState() == 1) {
        let uid = localStorage.getItem('uid')
        socket.send({
          type: "sub.user.msg",
          data: {
            uid: uid
          }
        }) //订阅所有信息

        isSend = true
      }

      setTimeout(function () {
        if (!isSend) {
          _this.setSocket()
        }
      }, 1000)

      socket.on("user.msg", function (data) {
        // console.log(data, "接受消息")
        _this.msg = data
      })
    },
    checkToken() {
      //获取缓存的token
      let token = localStorage.getItem('token')
      if (token) {
        this.token = token
        this.email = localStorage.getItem('email')
        this.email = this.email.substr(0, 2) + '***' + this.email.substr(-7)
        this.uid = localStorage.getItem('uid')
      } else {
        this.token = ''
        this.email = ''
        this.uid = ''
      }
    },
    Logout() {
      localStorage.removeItem('token')
      localStorage.removeItem('email')
      localStorage.removeItem('uid')
      this.checkToken()
    },
    language(lang) {
      // 设置语言
      setup(lang)
      let local = getLanguage()
      this.lang = local.key
      //通知
      this.$emit('listenLang', lang)
    }
  }
}
</script>

<style src="../../static/css/index.css" scoped type="text/css"></style>
