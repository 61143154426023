module.exports = {
  base: {
    title: 'STARTHESTAR',
    desc1: 'Konge av kontrakt',
    desc2: 'Global leder innen kontraktshandel',
    d1: 'Tillit',
    d2: 'Ærlighet',
    d3: 'Styrke',
    more: 'Mer',
    top: 'tilbake til toppen',
    download: 'Last ned APP',
    zszh: 'Ekte konto',
    mnzh: 'Demokonto',
    content: 'Handel med en global markedsleder innen kontraktshandel',
    lxkf: 'Contactați serviciul pentru clienți',
    chxx: 'Retragere',
    zx: 'online'
  },
  demo: {
    title: 'vellykket registrering!',
    title2: 'Analog konto',
    submit: 'Bekrefte'
  },
  lang: {
    title: 'Norge'
  },
  message: {
    invalid_email: 'Ugyldig epost',
    invalid_password: 'Ugyldig passord',
    invalid_new_password: 'Ugyldig nytt passord',
    invalid_res_password: 'Ugyldig bekreftelsespassord',
    invalid_email_code: 'Ugyldig e-postbekreftelseskode',
    password_inconsistent: 'Inkonsekvente passord',
    invalid_invitation_code: 'Ugyldig invitasjonskode',
    should_agree: 'Vennligst les og godta registreringsvilkårene',
    not_null: 'Dette feltet kan ikke være tomt',
    send_email: 'Vennligst prøv igjen etter {:s}s',
    invalid_number: 'ugyldig verdi',
    invalid_balance: 'Ugyldig saldo',
    invalid_rate: 'Velg flere',
    invalid_rule: 'Vennligst velg en regel',
    invalid_system_rule: 'Ingen regler i systemet',
    not_enough: 'Ikke nok tilgjengelig',
    price_high: 'Prisen må være høyere enn {price}',
    price_low: 'Prisen må være mindre enn {price}',
    number_high: 'Maksimalt antall uttak er {num}',
    number_low: 'Minste uttaksbeløp er {num}',
    copy_address: 'Kopier adressen {tekst}',
    invalid_upload_front: 'Last opp frontbildet av ID-en',
    invalid_upload_back: 'Last opp et bilde av baksiden av ID-en din',
    invalid_upload_hand: 'Last opp et håndholdt ID-bilde',
    invalid_upload_name: 'Vennligst skriv inn navnet på sertifikatet',
    invalid_upload_card: 'Vennligst skriv inn ID-nummeret',
    upload_pic_type: 'Last opp bilder kan bare være i JPG-, PNG-format',
    upload_pic_size: 'Last opp bildestørrelsen kan ikke overstige 10 MB'
  },
  index: {
    title2: 'Globalt marked gratis handel 24 timer non-stop',
    desc2: 'Implementer din handelsstrategi i et ekstremt likvid marked, med et 24-timers handelsvolum på',
    desc2_1: 'på hele plattformen',
    list: {
      name: 'marked',
      h24_price: '24H siste pris',
      h24_change: '24 timers endring',
      h24_high: '24H høyeste pris',
      h24_low: '24H laveste pris',
      h24_vol: '24H volum'
    },
    footer: {
      desc:
        'starthestar Blockchain Technology Ltd, med hovedkontor i USA, er et verdensledende blockchain-teknologiselskap. Selskapet har fått den amerikanske MSB internasjonale finanslisensen. Det er et kompatibelt blockchain-teknologitjenesteselskap som kan operere i samsvar på global skala Blockchain-forretningstjenester. Samtidig kommer starthestar Blockchain Technology Ltd tekniske teamtalenter fra topp internasjonale vitenskapelige forskningsinstitusjoner og blockchain-teknologiutviklingsteam for å fullt ut beskytte brukermidler og kontosikkerhet. I tillegg til det tekniske teamet har selskapet også et profesjonelt blockchain-team: digitale eiendeler AI kvantitativ investeringsteam, blokkjede-prosjektverdievalueringsteam, digital asset venture-investeringsteam, etc., har en global layout for å betjene globale blokkjedebrukere. Selskapets Målet og visjonen er å tilby trygge, stabile, hensynsfulle og pålitelige transaksjonstjenester for globale digitale aktiva-entusiaster, og å bygge et profesjonelt, digitalt og internasjonalt verdenskjent blockchain-teknologiselskap.',
      item1: {
        tt: 'Produktvisning',
        pt1: 'OTC-handelsregler',
        pt2: 'Nybegynnerguide',
        pt3: 'Prisstandard',
        pt4: 'Kontraktsbeskrivelse'
      },
      item2: {
        tt: 'Brukerstøtte',
        pt1: 'KYC og anti-hvitvaskingsavtale',
        pt2: 'Retningslinjer for håndhevingsforespørsel',
        pt3: 'Introduksjon til digitale eiendeler'
      },
      item3: {
        tt: 'Vilkår',
        pt1: 'Personvern',
        pt2: 'Brukeravtale',
        pt3: 'Risikovarsling'
      },
      item4: {
        tt: 'kontakt oss',
        pt1: 'Bedriftssamarbeid',
        pt2: 'Institusjonelt samarbeid',
        pt3: 'OTC-applikasjon',
        pt4: 'E-post til kundeservice',
        pt5: 'Klager og forslag',
        pt6: 'mediesamarbeid'
      }
    },
    bk3: {
      title: 'Stolt av innovativ FoU-styrke',
      desc: 'Ufattelig kontrakthandelsopplevelse, designet for finanshandlere',
      pt1: 'Åpen ordre stopp profitt og stopp tap',
      pd1: 'Ventende bestillinger er ikke oppe for sent, bekymre deg for ikke å se bestillingen',
      pt2: 'Omvendt åpen rekkefølge',
      pd2: 'Markedet endrer seg plutselig, følg med på ett sekund',
      pt3: 'Støtt fullt lager for lager',
      pd3: 'Gjør hva du vil, gjør hva du vil',
      pt4: 'Full stilling trinnvis avvikling',
      pd4: 'Utenlandsk likvidasjon tilsvarer først halvparten og prøver å hjelpe investorer med å redusere tap'
    },
    bk5: {
      title: 'Åtte fordeler med kontraktshandelsplattform',
      desc: 'Vi lytter hele tiden, optimaliserer og forbedrer kontinuerlig, og skaper en førsteklasses handelsopplevelse for globale handelsbrukere',
      pt1: 'Markedsdybde',
      pd1: 'Markedsdybden til de tre første bodene på plattformen har nådd størrelsesorden millioner av dollar, langt over bransjens gjennomsnittlige markedsdybde på 10 000 og 100 000. Spredningen er liten og transaksjonen er rask.',
      pt2: 'Glatt transaksjon',
      pd2: 'Ved å bruke en effektiv matchende transaksjonsmotor for å sikre en jevn transaksjonsopplevelse, kan den behandle millioner av bestillinger per sekund, og kan støtte jevn og stabil drift med mer enn 20 millioner samtidige nettbrukere.',
      pt3: 'Rettferdig pris',
      pd3: 'Marked Price velger transaksjonsprisen i sanntid for verdens tre store børser som indeksprisen for regresjonsberegning, noe som effektivt forhindrer brukertap forårsaket av unormal manipulasjon av den siste transaksjonsprisen i løpet av kort tid.',
      pt4: 'Egenutviklet teknologi',
      pd4: 'Matchmaking-motoren forankret i tradisjonell finans, utviklet av et team av senioreksperter i finansbransjen, og omhyggelig utformet hver detalj for å sikre sikker, stabil og effektiv drift av handelsplattformen.',
      pt5: 'Topp risikokontroll',
      pd5: 'Andre nivå vurdering, flerlags kryptering, høy systemsikkerhet. Kombinasjonen av varme og kalde lommebøker, krypteringstiltak av militær kvalitet, sikkerhet for aktivatransaksjoner er garantert, og 100 % garanterer sikkerheten til brukernes digitale eiendeler.',
      pt6: 'Midler Sikkerhet',
      pd6: 'Tre-lags sikkerhetssystem, omfattende sikkerhetsrevisjon. Selvbygde bransjens ledende hierarkiske deterministiske kald lommebok-system, alle brukere tildeler ladeadresser er kald lommebok-adresser, innsamling og uttak av midler fullføres gjennom offline signaturer.',
      pt7: 'Hardcore Power',
      pd7: 'Titusenvis av BTC-reserver, gener for finansiell innovasjon og verdens toppledergruppe. starthestar lover at hvis konto- og transaksjonsdatafeil ikke er forårsaket på grunn av systemårsaker, vil plattformen betale fullt ut i tilfelle feil!',
      pt8: 'Kvalitetstjeneste',
      pd8: 'Profesjonell kundeservice 7*24 timer døgnet rundt, rask respons. Start fra tanken om tjeneste, gi førsteklasses handelsopplevelse for globale kunder. Den ultimate opplevelsen kommer fra konseptet med å betjene kunder.'
    },
    bk6: {
      title: 'Sikkerhet og samsvar global drift',
      desc: 'Omfavn tilsyn og overhold alltid globale samsvarsoperasjoner'
    },
    bk7: {
      title: 'Offisiell strategisk partner'
    },
    bk8: {
      title: 'Betjente mer enn 100 000 globale handelsbrukere',
      desc: 'For øyeblikket er brukere over hele USA, Japan, Sør-Korea, Seychellene, Vietnam og andre land'
    },
    bk9: {
      title: 'Jevn transaksjon når som helst, hvor som helst',
      pt1: 'Matchmaking-transaksjoner, innskudd og uttak i sanntid kommer på sekunder',
      pt2: 'Flere handelsmoduser, opptil 125 ganger innflytelse',
      dt1: 'Last ned iOS-versjon',
      dt2: 'Last ned Android-versjon'
    },
    about: {
      title: 'Om oss',
      pt1: 'Plattformkonsept',
      pd1:
        'La globale eiendeler sirkulere fritt og skap en ny kontraktsopplevelse. X-xenogenetic, representerer innovative gener, og gir en jevn strøm av kraft for industriinnovasjon. M-motor, representerer målet, og fortsetter å gå videre med målet om å lede kontraktshandelsopplevelsen. EX-exchange, på vegne av handelsplattformen, er forpliktet til å skape et rettferdig, transparent og effektivt handelsøkosystem med driftsfilosofien tjeneste, lytting og tenkning.',
      pt2: 'Kjerneverdier',
      pd2:
        'Vi lytter alltid, tar hensyn og tenker fremover, og er forpliktet til å skape et rettferdig, transparent og effektivt handelsøkosystem. Gi globale handelsmenn en bedre handelsopplevelse, møte de faktiske behovene til handelsmenn og fremme utviklingen av markedet for handel med digitale eiendeler.',
      pt3: 'Lagets bakgrunn',
      pd3: 'Grunnleggerteamet er sammensatt av topp blockchain-investorer, tradisjonelle finansledere, topp digitale aktivaeksperter og tradisjonelle metallbearbeidingsteknologiteam. Kjernemedlemmene i FoU-teamet er fra Morgan Stanley, CICC, Hang Seng, Tencent, Noah Wealth.'
    }
  },
  start: {
    p_title: 'Transaksjon, start her',
    p_desc: 'Vennligst skriv inn e-postadressen din i følgende område for å registrere deg umiddelbart',
    p_email: 'e-post',
    p_btn: 'Skape'
  },
  download: {
    p_title: 'Last ned APP-klient',
    p_desc: 'Når som helst, hvor som helst, multi-plattform terminal transaksjon',
    p_btn: 'nedlasting',
    p_scan_android: 'Android-app',
    p_scan_ios: 'IOS-appen'
  },
  downpage: {
    t1: {
      p_title: 'Lyntransaksjonsytelse',
      p_desc: 'Gi en rekke transaksjonstyper å velge mellom, ordrematching er ekstremt rask, ett-klikks flash-utveksling, og handel er mer praktisk'
    },
    t2: {
      p_title: 'Enkel overføring av eiendeler',
      p_desc: 'Enkel overføring av eiendeler på kontoen, sanntidsoversikt over digitale eiendeler, øke kakediagrammet for valutaforhold'
    },
    t3: {
      p_title: 'Tilpasset handelspanel',
      p_desc: 'Definer fritt handelsoppsettet i henhold til forskjellige handelsvaner, og øk oppføringen av K-linje og dybdekart'
    },
    t4: {
      p_title: 'Account Integration Management',
      p_desc: 'Flere sikkerhetsverifiseringsinnstillinger, én konto administrerer C2C-kontoer og valutakontoer'
    }
  },
  login: {
    login: 'Logg Inn',
    title: 'Brukerinnlogging',
    desc1: 'Logg inn takk',
    desc2: 'Offesiell nettside',
    email: 'E-post',
    email2: 'E-post',
    email3: 'Telefonnummer',
    email_desc: 'Vennligst skriv inn e-posten din',
    phone_desc: 'Vennligst skriv inn telefonnummer',
    password: 'passord',
    password_desc: 'Vennligst skriv inn passordet',
    forgot_password: 'Glemt passord',
    no_account: 'Ingen konto ennå'
  },
  signup: {
    signup: 'Melde deg på',
    title: 'bruker registrering',
    desc1: 'Velkommen til å logge inn',
    desc2: 'Offesiell nettside',
    invitation_code: 'invitasjonskode',
    invitation_desc: 'Vennligst skriv inn invitasjonskoden',
    accept: 'Aksepterer',
    service: 'Brukeravtale',
    and: 'og',
    policy: 'Retningslinjer for personvern',
    have_account: 'Har du allerede en konto'
  },
  retrieve: {
    ptitle: 'få tilbake passord',
    pemail_code: 'E-postbekreftelseskode',
    pset: 'Lag et passord',
    set_title: 'Lag et passord',
    set_desc: 'Du tilbakestiller passordet ditt, vennligst fullfør følgende innstillinger',
    new_password: 'Nytt passord',
    password_desc: 'Vennligst skriv inn et nytt passord',
    res_passowrd: 'bekreft passord',
    res_passowrd_desc: 'Vennligst skriv inn bekreftelsespassordet'
  },
  valid_email: {
    title: 'Registreringsbekreftelse',
    desc: 'Du registrerer en konto, vennligst fullfør følgende bekreftelse',
    email_code: 'E-postbekreftelseskode',
    email_desc: 'Vennligst skriv inn verifikasjonskoden',
    get_code: 'Få bekreftelseskode'
  },
  ihead: {
    begin_trade: 'Begynn handel',
    helper: 'Nybegynnerhjelp',
    text: 'Dokumentsenter',
    new_currency: 'Nytt valutaabonnement'
  },
  uhead: {
    title1: 'Råvarer',
    title2: 'Internasjonal Forex',
    title3: 'Internasjonal indeks',
    currency: 'Valuta',
    lever: 'Utnyttet transaksjon',
    coins: 'Valutatransaksjon',
    contract: 'Kontraktstransaksjon',
    metal: 'Metall',
    energy: 'Energi',
    contract_info: 'Kontraktinformasjon',
    helper: 'Nybegynnerhjelp',
    text: 'Dokumentsenter',
    assets: 'Eiendeler',
    u_wallet: 'Min lommebok',
    u_contract: 'Kontraktskonto',
    u_lever: 'Utnytt konto',
    u_account: 'Kontoinformasjon',
    u_logout: 'Logg ut'
  },
  news: {
    label: 'Nyheter',
    update: 'Oppdatert på'
  },
  guide: {
    label: 'Nybegynnerguide',
    list: [
      {
        ptitle: 'Slik registrerer du deg for starthestar',
        pkey: 'k1'
      },
      {
        ptitle: 'Identitetsautentisering',
        pkey: 'k2'
      },
      {
        ptitle: 'Innstillinger for påloggingspassord og transaksjonspassord',
        pkey: 'k3'
      },
      {
        ptitle: 'Innskuddsmynter',
        pkey: 'k4'
      },
      {
        ptitle: 'Mynttransaksjonsprosess',
        pkey: 'k5'
      },
      {
        ptitle: 'Uttak',
        pkey: 'k6'
      },
      {
        ptitle: 'Last ned  mobil APP',
        pkey: 'k7'
      }
    ],
    content: {
      k1: require('./currency/en/guide/k1.js').default,
      k2: require('./currency/en/guide/k2.js').default,
      k3: require('./currency/en/guide/k3.js').default,
      k4: require('./currency/en/guide/k4.js').default,
      k5: require('./currency/en/guide/k5.js').default,
      k6: require('./currency/en/guide/k6.js').default,
      k7: require('./currency/en/guide/k7.js').default
    }
  },
  kline: {
    h24_high: '24 timer høy',
    h24_low: '24H lav',
    h24_vol: '24 timers volum',
    commission: 'Nåværende kommisjon',
    record: 'Transaksjonspost',
    commission_list: 'Kommisjonsliste',
    deal_list: 'Siste avtale',
    price: 'Pris',
    xprice: 'Overskuddsspredning',
    bzj: 'Margin',
    number: 'Antall',
    total: 'Total',
    time: 'Tid',
    currency: 'Stillinger',
    type: 'Type',
    direction: 'Retning',
    multiple: 'Pressmiddel',
    fee: 'Kommisjon',
    amount: 'Total',
    status: 'Status',
    operate: 'Operasjon',
    buy: 'Kjøpe',
    sell: 'Selge',
    pending: 'Ventende bestilling',
    trade_success: 'Vellykket handel',
    retract: 'Avbryt',
    profit: 'Tjene på det',
    loss: 'Forhindre tap',
    all: 'Alle',
    all_currency: 'Alle valutaer',
    new_price: 'Siste pris',
    change: 'Stig og fall',
    protocol: 'protokoll',
    _status1: 'Anmeldelse',
    _status2: 'Vellykket',
    _status3: 'Avvist'
  },
  coins: {
    title: 'Valutatransaksjon',
    market: 'markedspris',
    limit: 'Begrense pris',
    profit: 'Tjene på det',
    loss: 'Forhindre tap',
    price: 'Pris',
    current: 'Nåværende',
    price_desc: 'Vennligst skriv inn prisen',
    number: 'Antall',
    number_desc: 'Vennligst skriv inn nummeret',
    available: 'Tilgjengelig',
    half: 'halvt lager',
    all: 'Fullt lager',
    fee: 'Kommisjon',
    amount: 'transaksjons beløp',
    buy: 'Kjøpe',
    sell: 'Selge',
    current_price: 'Nåværende',
    open_price: 'Åpent i dag',
    high: 'Høy',
    low: 'Lav',
    yesterday: 'Stengt i går',
    today_zf: 'Prisendring',
    now_cc: 'Posisjon',
    y_cc: 'Y-posisjon',
    jsj: 'Oppgjørspris',
    zrjsj: 'Y Oppgjørspris'
  },
  lever: {
    title: 'Utnyttet transaksjon',
    multiple: 'Pressmiddel',
    multiple_desc: 'Velg et multiplum',
    double: 'double',
    buy: 'Kjøpe opp)',
    sell: 'Selg (høst)'
  },
  contract: {
    title: 'Kontraktstransaksjon',
    time: 'Tid',
    time_desc: 'Vennligst velg et tidspunkt',
    minute: 'minutt',
    second: 'Sekund',
    buy: 'Kjøpe opp)',
    sell: 'Selg (høst)',
    rate: 'Royaltyforhold'
  },
  wallet: {
    title: 'Mine eiendeler',
    valuation: 'Samlet verdivurdering',
    my_wallet: 'Min lommebok',
    my_trade: 'Handelskonto',
    my_transfer: 'Uttaksrekord',
    my_deposit: 'Myntinnskuddspost',
    cbdz: 'innskuddsadresse',
    cbsl: 'Antall',
    deposit: 'Sett inn mynter',
    withdrawal: 'Uttak',
    search: 'Søk',
    currency: 'Stillinger',
    protocol: 'Protokoll',
    liquidation: 'Lukk posisjon',
    liquidation_title: 'Rask avvikling',
    trade_pwd: 'Handelspassord',
    enter_trade_pwd: 'Skriv inn handelspassord',
    submit_liquidation: 'Lukk posisjonen umiddelbart',
    amount: 'Total',
    available: 'Tilgjengelig',
    freeze: 'Fryse',
    market: 'verdivurdering',
    ljsy: 'Kumulert inntekt',
    zrsy: 'Gårsdagens inntekter',
    operate: 'Operasjon',
    countdown: 'Nedtelling',
    lever_title: 'Utnytt konto',
    contract_title: 'Kontraktskonto',
    direction: 'Retning',
    bond: 'Margin',
    buy_rate: 'flere',
    profit: 'Profitt og tap',
    profit1: 'Profitt og tap',
    buy: 'Kjøpe',
    sell: 'Selge',
    many: 'Kjøpe',
    empty: 'Selge',
    contract_time: 'Kontraktstid',
    premium: 'Privilegium',
    minute: 'minutt',
    address: 'Adresse',
    address_desc: 'Vennligst skriv inn adressen',
    number: 'Antall',
    number_desc: 'Vennligst skriv inn antall uttak',
    copy: 'Kopiere',
    confirm: 'OK',
    all: 'Alle',
    fee: 'Kommisjon',
    actual: 'Faktisk ankomst',
    premark: 'Merk følgende',
    text_1: 'Denne adressen aksepterer bare {name} eiendeler, vennligst ikke lad opp andre valutaer, ellers vil ikke eiendelene bli hentet',
    text_2: '{name} gruvearbeiders honorar er: gjeldende markedsverdi {rate}%/transaksjon, minimumsstandard: {min}{name}/transaksjon',
    text_3: 'Vennligst bekreft at mottaksadresseinformasjonen er korrekt før du overfører midlene. Når eiendelene er overført, kan de ikke tas ut.',
    text_4: '{name} Gruveavgift er: {val} {name}/ penn'
  },
  record: {
    title: 'Fondsrekord',
    type: 'Type',
    all_currency: 'Alle valutaer',
    all_type: 'Alle typer',
    select: 'Spørsmål',
    clear: 'Tøm filter',
    receive: 'Innskudd',
    transfer: 'Uttak',
    buy: 'Kjøpe',
    sell: 'Selge',
    normal: 'Vanlig',
    pending: 'Stopp-grense',
    wallet: 'Posisjonsdetaljer',
    zzzqy: 'Balansere',
    zqy: 'Fri margin',
    fdyk: 'Flytende P/L',
    fxl: 'Risikorate'
  },
  user: {
    account: 'Kontoautentisering',
    account_desc: 'Kredittinnskudd, transaksjoner og andre funksjoner må først bestå kontoverifisering',
    password: 'Innloggings passord',
    password_desc: 'Brukes for å beskytte kontosikkerhet',
    modify: 'Endre',
    set: 'Sett',
    find_paypwd: 'få tilbake passord',
    find_pay_pwd: 'Hent betalingspassord',
    email: 'E-post',
    enter_email: 'Vennligst skriv inn e-postadressen din',
    email_code: 'Bekreftelseskode',
    enter_email_code: 'Vennligst skriv inn e-postbekreftelseskoden',
    send: 'Sende',
    pay_password: 'Betalingspassord',
    pay_password_desc: 'Brukes for å beskytte aktivasikkerhet ved handel',
    not_certified: 'Ikke sertifisert',
    certification: 'Sertifisering pågår',
    verified: 'Verifisert',
    rejected: 'avvist',
    update_pwd: 'Endre påloggingspassord',
    update_pay_pwd: 'Endre betalingspassord',
    set_pay_pwd: 'Angi betalingspassord',
    old_pwd: 'Opprinnelig passord',
    enter_old_pwd: 'Vennligst skriv inn det opprinnelige passordet',
    new_pwd: 'Nytt passord',
    enter_new_pwd: 'Vennligst skriv inn et nytt passord',
    res_pwd: 'bekreft passord',
    enter_res_pwd: 'Vennligst skriv inn bekreftelsespassordet',
    submit: 'Sende inn'
  },
  certified: {
    title: 'Kontoautentisering',
    front: 'ID-kort foran',
    back: 'Baksiden av ID-kortet',
    hand: 'Håndholdt ID-kort',
    name: 'Navn',
    name_desc: 'Vennligst skriv inn et navn',
    card: 'ID-nummer',
    card_desc: 'Vennligst skriv inn ID-nummer',
    submit: 'Sende inn'
  },
  trade: {
    title: 'transaksjonspassord',
    enter_password: 'Vennligst skriv inn transaksjonspassord',
    cancel: 'Avbryt',
    submit: 'sende inn'
  },
  revoke: {
    title: 'Spørremelding',
    message: 'Er du sikker på at du vil trekke tilbake bestillingen?',
    cancel: 'Avbryt',
    submit: 'bekrefte'
  },
  coins_assets: {
    title: 'Valuta eiendeler',
    total_equity: 'Total egenkapital',
    equity: 'Konto egenkapital',
    number: 'Antall beholdninger',
    market_value: 'Gjeldende verdivurdering'
  },
  lever_assets: {
    title: 'Leveraged eiendeler',
    total_equity: 'Total egenkapital',
    equity: 'Konto egenkapital',
    profit: 'Urealisert gevinst og tap',
    risk: 'Fare'
  },
  contract_assets: {
    title: 'Kontrakt eiendeler',
    total_equity: 'Total egenkapital',
    equity: 'Konto egenkapital',
    profit: 'Urealisert gevinst og tap'
  },
  contract_menu: {
    contract: 'Kontraktdetaljer',
    lever: 'Utnytt detaljer',
    coins: 'Myntindeks',
    history: 'Fondskurshistorikk',
    coins_rules: [
      {
        value: '1D',
        name: 'På dagen'
      },
      {
        value: '1W',
        name: 'Nåværende uke'
      },
      {
        value: '1M',
        name: 'Denne måneden'
      },
      {
        value: '1J',
        name: 'Nåværende sesong'
      }
    ],
    lever_info: 'Utnytt informasjon',
    contract_info: 'Kontraktsinformasjon'
  },
  contract_info: {
    bzj: 'Depositum',
    pt1: 'Oppgjørsvaluta',
    pt2: 'Kontrakt pålydende',
    pt3: 'Minimum endringspris',
    pt4: 'Kontraktsperiode',
    pt5: 'Fondsrentetid',
    pt6: 'Merket pris',
    pt7: 'Volum av posisjon',
    pt8: '24-timers handelsvolum',
    pt9: 'Privilegium',
    pt10: 'Forfall Leveringsinntekt',
    pt11: 'Åpne posisjonsspredning',
    pt12: 'Leverings- og oppgjørsmekanisme',
    pt13: 'Transaksjonstjenestegebyr',
    pt14: 'Låseposisjon',
    pv5: 'Hver dag 08:00 16:00 24:00',
    pv10_1: 'Lang posisjon: (gjennomsnittlig sluttkurs-gjennomsnittlig åpningspris) x mengde',
    pv10_2: 'Kort posisjon: (gjennomsnittlig åpningskurs-gjennomsnittlig sluttkurs) x mengde',
    pv11: 'Nei',
    pv12: 'Automatisk levering ved forfall',
    pv13: 'Nei',
    pv14: 'ikke støtte'
  },
  metal_info: {
    pt1: 'Handel med varianter',
    pt2: 'Oppgjørsvaluta',
    pt3: 'Tilbudsenhet',
    pt4: 'Kontraktsstørrelse',
    pt5: 'Minimum endringsenhet',
    pt51: 'Minimum handelsenhet',
    pt52: 'Maksimal handelsenhet',
    pt6: 'Kontraktstid',
    pt7: 'Handelstid',
    pt8: 'Kommisjon',
    pt9: 'Børsnotert børs',
    pt10: 'målspredning',
    pv4: 'bærekraftig',
    pv5: '24-timers handel fra mandag til fredag',
    pv51: '24 timers handel',
    pv6: 'Kontraktsverdi 0,08 %',
    pv7: 'London Stock Exchange (LME)',
    pv71: 'New York Stock Exchange (NYMEX)',
    pv10: '{name} målspredning er {num} pips'
  },
  lever_info: {
    title: 'Utnytt detaljer',
    bei: 'ganger',
    pt1: 'Oppgjørsvaluta',
    pt2: 'Kontrakt pålydende',
    pt3: 'Minimum endringspris',
    pt4: 'Kontraktsperiode',
    pt5: 'Fondsrentetid',
    pt6: 'Merket pris',
    pt7: 'Volum av posisjon',
    pt8: '24-timers handelsvolum',
    pt9: 'Risikoreserve',
    pt10: 'Avviklingsgevinst',
    pt11: 'Åpne posisjonsspredning',
    pt12: 'Automatisk avviklingsmekanisme',
    pt13: 'Transaksjonstjenestegebyr',
    pt14: 'Låseposisjon',
    pv4: 'Bærekraftig',
    pv5: 'Hver dag 08:00 16:00 24:00',
    pv10_1: 'Lang posisjon: (gjennomsnittlig sluttkurs-gjennomsnittlig åpningspris) x mengde',
    pv10_2: 'Kort posisjon: (gjennomsnittlig åpningskurs-gjennomsnittlig sluttkurs) x mengde',
    pv12: 'Reserverisikoforholdet når 80 %',
    pv13: 'Kontraktsverdi',
    pv14: 'Ikke støttet'
  },
  //币币信息
  coins_desc: require('./currency/en/coins.js').default,

  fun_history: {
    t1: 'Dato',
    t2: 'Estimert finansieringsrate',
    t3: 'Faktisk finansieringsrate'
  },
  docs: {
    list: [
      {
        name: 'OTC-handelsregler',
        key: 'otc'
      },
      {
        name: 'KYC og anti-hvitvaskingsavtaler',
        key: 'kyc'
      },
      {
        name: 'Retningslinjer for rettshåndhevelse',
        key: 'lov'
      },
      {
        name: 'personvern',
        key: 'personvern'
      },
      {
        name: 'Brukeravtale',
        key: 'protokoll'
      },
      {
        name: 'Risikovarsling',
        key: 'Fare'
      }
    ],
    otc: require('./currency/en/otc').default,
    kyc: require('./currency/en/kyc.js').default,
    law: require('./currency/en/law.js').default,
    privacy: require('./currency/en/privacy.js').default,
    protocol: require('./currency/en/protocol').default,
    risk: require('./currency/en/risk').default
  },
  asset: {
    title: 'Digital eiendel introduksjon',
    list: 'Liste over digitale eiendeler'
  },
  num_asset: require('./currency/en/asset').default,

  new_currency: {
    _title: 'Nytt valutaabonnement',
    _all: 'Alle',
    _progress: 'I prosess',
    _end: 'Over',
    _success: 'Utveksling vellykket',
    _progress_str: 'Framgang',
    _total_str: 'Totalt utgitt',
    _date_time: 'Start- og sluttdato',
    _submit: 'Innløse nå',
    _base_title: 'Grunnleggende informasjon',
    _status: 'Status',
    _currency: 'Akseptert valuta',
    _symbol: 'Valutasymbol',
    _amount: 'Totalt opphevet forbud',
    _price: 'Startpris',
    _limit: 'Personlig kjøpsgrense',
    _limit_amount: 'Personlig kjøpsgrense',
    _limit_one: 'Personlig enkeltgrense nedre grense',
    _no_limit: 'Ingen grense',
    _position: 'Posisjon',
    _begin_time: 'Starttid',
    _end_time: 'Sluttid',
    _redeemed: 'Innløst beløp',
    _surplus: 'overskudd',
    _wallet: 'Lommebok',
    _have: 'Holdes for øyeblikket',
    _input: 'inngående mengde',
    _choose: 'Vennligst velg',
    _new_wallet: 'Ny valutakonto',
    _new_price: 'Siste pris',
    _lock: 'Låseperiode',
    _login: 'Vennligst Logg inn',
    _no_data: 'Ingen data',

    _avg_price: 'gjennomsnittlig pris'
  }
}
